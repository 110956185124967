import registerView from 'lib/scope'
import Textarea from "components/textarea"
import TextInput from "components/text_input"
import analytics from "lib/analytics"
import autosize from "autosize"

registerView('.js-forum-posts-new', ($view, $$) => {
  analytics.track('[Frontier] Viewed post submission page')

  new Textarea($$("textarea"))
  new TextInput($$("textarea"), { removeEmojis: true })

  let $urlInput = $$(".js-url-input")
  let $titleInput = $$(".js-title-input")
  new TextInput($titleInput, { removeEmojis: true })

  $urlInput.on("change", (e) => {
    let url = $urlInput.val()

    if (url.length > 3) {
      $$(".js-title-loading").removeClass("d-none")
      $.get({
        url: $titleInput.data("fetch-title-path"),
        data: { url: url }
      }).done(((data) => {
        let newTitle = data.title

        if ($titleInput.val().length == 0 && typeof(newTitle) == "string" && newTitle.length > 0) {
          $titleInput.val(newTitle)
        }
      })).always(() => {
        $$(".js-title-loading").addClass("d-none")
      })
    }
  })

  const onSharedByCreatorChange = () => {
    let $checkbox = $$(".js-shared-by-creator-input")
    $$(".js-shared-by-creator-instructions").toggleClass("d-none", !$checkbox.prop('checked'))
  }
  onSharedByCreatorChange()
  $$('.js-shared-by-creator-input').on('change', onSharedByCreatorChange)

  const refreshPublishingLaterFields = () => {
    let publishLaterChecked = $$(".js-publish-later-input").prop("checked")

    $$(".js-if-publishing-later").toggleClass("d-none", !publishLaterChecked)
    $$(".js-publish-at-input").prop("disabled", !publishLaterChecked)
  }
  refreshPublishingLaterFields()
  $$(".js-publish-later-input").on('change', refreshPublishingLaterFields)
})
