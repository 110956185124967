import registerView from 'lib/scope'

registerView('.js-home-courses', ($view, $$) => {

  // Only autoplay videos on small screens
  if ($$(".js-autoplay-videos").is(':visible')) {
    $$('.js-preview-video').each((i, el) => $(el)[0].play())
  } else {
    $$('.js-course').each((i, el) => {
      const $course = $(el)
      $course.on("mouseover", () => {
        const $video = $(el).find(".js-preview-video")
        $video[0].play()
      })
      $course.on("mouseout", () => {
        const $video = $(el).find(".js-preview-video")
        $video[0].pause()
      })
    })
  }
})

