import LineChart from "views/shared/ticker/line_chart"
import SimulatedGrowth from "views/applicants/ticker/simulated_growth"
import TickerEventNode from "views/applicants/ticker/ticker_event_node"

const animationDuration = 100
const lineOptions = {
  animation: {
    duration: animationDuration,
    easing: "linear",
    onComplete: (animation) => {
      if (animation.duration == animationDuration) {
        TickerChartWrapper.highlightLastPoint(animation.chart, 0)
      }
    },
  },
  legend: {
    display: true,
    position: "bottom",
    onClick: () => {},
    labels: {
      filter: (legendItem) => {
        if (!legendItem.text) {
          return false
        }
        return true
      }
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
        },
        scaleLabel: { fontColor: "#324555" },
        gridLines: {
          drawOnChartArea: false,
          display: true,
          color: "#324555",
          tickMarkLength: 0
        }
      }
    ],
    xAxes: [
      {
        type: "time",
        ticks: {
          display: true,
          fontColor: "#324555"
        },
        gridLines: {
          display: true,
          color: "#324555",
          tick: [1]
        },
        scaleLabel: { fontColor: "#324555" },
      }
    ]
  },
  tooltips: {
    enabled: true
  },
  spanGaps: true,
  layout: {
    padding: {
      left: 0,
      right: 10,
      top: 10,
      bottom: 0
    }
  }
}

export default class TickerChartWrapper extends TickerEventNode {
  constructor($view, context) {
    super($view)

    this.primaryDatasetIndex = 0

    const plugins = this.getPlugins()

    this.tickerChart = new TickerChart($view, context, plugins)
  }

  onTimeFilter(event, eventData) {
    const { lookbackDuration, start, end } = eventData

    if (lookbackDuration == 0) {
      this.tickerChart.removeTimeFilter()
    } else {
      this.tickerChart.applyTimeFilter(start, end)
    }
  }

  getPlugins() {
    const plugins = [{
      afterDatasetsDraw: (chart) => {
        if (chart.animating) return

        const ctx = chart.ctx

        const { yScale } = TickerChartWrapper.getScales(chart, this.primaryDatasetIndex)

        if (chart.tooltip._active && chart.tooltip._active.length) {
          // on hover on chart

          const activePoint = chart.tooltip._active[0]

          this.triggerMainChartHover({ activePoint })

          const xPixel = activePoint.tooltipPosition().x
          const yPixel = activePoint.tooltipPosition().y

          const topY = yScale.top
          const bottomY = yScale.bottom

          // draw vertical line
          ctx.save()
          ctx.beginPath()
          ctx.moveTo(xPixel, topY)
          ctx.lineTo(xPixel, bottomY)

          ctx.lineWidth = 2
          ctx.strokeStyle = "#324555"
          ctx.stroke()
          ctx.restore()

          const datasetIndex = activePoint._datasetIndex
          const datasets = activePoint._chart.tooltip._data.datasets
          const pointIndex = activePoint._index

          // highlight current leg
          TickerChartWrapper.drawHighlightLeg(chart, datasets, datasetIndex, pointIndex)

          //draw dot
          const color = chart.data.datasets[datasetIndex].pointBackgroundColor
          TickerChartWrapper.drawTrackingDot(chart, xPixel, yPixel, color)
        } else {
          // when exiting hover && not animating
          this.triggerMainChartHoverExit()

          TickerChartWrapper.highlightLastPoint(chart, this.primaryDatasetIndex)
        }
      }
    }]

    return plugins
  }

  static drawHighlightLeg(chart, datasets, datasetIndex, pointIndex) {
    const ctx = chart.ctx

    const { yScale, xScale } = TickerChartWrapper.getScales(chart, datasetIndex)

    const color = datasets[datasetIndex].pointBackgroundColor

    const activePointCoordinates = datasets[datasetIndex].data[pointIndex]
    const previousPointCoordinates = datasets[datasetIndex].data[pointIndex - 1]

    if (!previousPointCoordinates) return

    const yEndPixel = yScale.getPixelForValue(activePointCoordinates.y)
    const xEndPixel = xScale.getPixelForValue(activePointCoordinates.x)

    const yStartPixel = yScale.getPixelForValue(previousPointCoordinates.y)
    const xStartPixel = xScale.getPixelForValue(previousPointCoordinates.x)

    ctx.save()
    ctx.beginPath()

    ctx.shadowColor = color
    ctx.shadowBlur = 5
    ctx.moveTo(xEndPixel, yEndPixel)
    ctx.lineTo(xStartPixel, yStartPixel)

    ctx.lineWidth = 2
    ctx.strokeStyle = color
    ctx.stroke()
    ctx.restore()
  }

  static drawTrackingDot(chart, xPixel, yPixel, color) {
    const ctx = chart.ctx

    ctx.save()
    ctx.beginPath()
    ctx.arc(xPixel, yPixel, 7, 0, 2 * Math.PI)
    ctx.fillStyle = color
    ctx.lineWidth = 2
    ctx.strokeStyle = "#121E2A"
    ctx.fill()
    ctx.stroke()
    ctx.restore()
  }

  // Highlight last leg and draw tracking point on last point
  static highlightLastPoint(chart, datasetIndex) {
    const dataset = chart.data.datasets[datasetIndex]

    const lastPoint = dataset.data[dataset.data.length - 1]
    if (!lastPoint) return

    const { yScale, xScale } = TickerChartWrapper.getScales(chart, datasetIndex)

    const yPixel = yScale.getPixelForValue(lastPoint.y)
    const xPixel = xScale.getPixelForValue(lastPoint.x)

    //highlight the last leg
    const lastPointIndex = dataset.data.length - 1
    TickerChartWrapper.drawHighlightLeg(chart, chart.data.datasets, datasetIndex, lastPointIndex)

    //draw dot
    const color = chart.data.datasets[datasetIndex].pointBackgroundColor
    TickerChartWrapper.drawTrackingDot(chart, xPixel, yPixel, color)
  }

  static getScales(chart, datasetIndex) {
    const meta = chart.getDatasetMeta(datasetIndex)

    const yScale = chart.scales[meta.yAxisID]
    const xScale = chart.scales[meta.xAxisID]

    return {yScale, xScale}
  }
}

export class TickerChart extends LineChart {
  constructor($view, context, plugins) {
    super($view, context, lineOptions, plugins)

    this.filterOrder = ["time"]
    this.filters = {}
  }

  applyTimeFilter(start, end) {
    const filterFn = (datasets) => {
      return LineChart.applyTimeFilterToDatasets(datasets, start, end)
    }

    const on = () => {}

    const off = () => {
      this.filters.time = undefined
    }

    this.filters.time = { filterFn, on, off: off, name: "time" }
    this.applyFilters()
  }

  removeTimeFilter() {
    if (this.filters.time) {
      this.filters.time.off()
      this.applyFilters()
    }
  }

  applyFilters() {
    const datasets = this.filterOrder.reduce((acc, filterKey, idx) => {
      const filter = this.filters[filterKey]

      if (filter) {
        const ret = filter.filterFn(acc)
        filter.on()

        return ret
      }

      return acc
    }, this.datasets)

    this.updateDatasets(datasets)
  }

  changeToDurationTicks() {
    const xAxesConfig = this.chart.config.options.scales.xAxes[0]

    const unitStepSize = xAxesConfig.time.unitStepSize
    const unit = xAxesConfig.time.unit

    const callback = (value, idx, values) => {
      const unitNumber = unitStepSize * (idx + 1)

      return `${unit} ${unitNumber}`
    }

    xAxesConfig["ticks"]["callback"] = callback
    this.chart.config.options.scales.xAxes[0] = xAxesConfig
    this.chart.update()
  }

  changeToDefaultTicks() {
    const xAxesConfig = this.chart.config.options.scales.xAxes[0]

    if (xAxesConfig.ticks) {
      xAxesConfig.ticks.callback = undefined
    }

    this.chart.config.options.scales.xAxes[0] = xAxesConfig
    this.chart.update()
  }

  getPioneerVelocity() {
    const pioneerVelocityGrowthRate = 0.01
    return this.getVelocity(pioneerVelocityGrowthRate)
  }

  getVelocity(growthRate) {
    const primaryDataset = this.getCurrentPrimaryDataset()

    if (!primaryDataset || primaryDataset.data.length === 0) {
      return 0
    }

    const firstPoint = primaryDataset.data[0]
    const simulatedGrowthChartData = SimulatedGrowth.calculateSimulatedGrowthChartData({ startValue: firstPoint.y, startEpoch: firstPoint.x, growthRate, dataWiggle: 0 })
    const simulatedGrowthDataset = LineChart.getDatasetsFromChartData(simulatedGrowthChartData)[0]

    // velocityScore = area under the curve between primaryDataset and simulatedGrowth dataset
    let velocityScore = 0

    for (const point of primaryDataset.data) {
      const simulatedGrowthValueAtPoint = LineChart.getValueAt(point.x, simulatedGrowthDataset.data)
      const distance = point.y - simulatedGrowthValueAtPoint

      velocityScore += distance
    }

    return velocityScore
  }
}


