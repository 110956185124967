import moment from "moment/moment"
import registerView from 'lib/scope'
import AdvanceToNextLevelForm from 'views/course_progression/shared/advance_to_next_level_form'
import DatePicker from "views/course_progression/shared/date_picker"

class LaunchSoonerModal {
  constructor($view, originalLaunchDate, proposedLaunchDate, daysAway) {
    this.$view = $view
    this.$modal = $view.find(".js-launch-sooner-modal")
    this.proposedLaunchDate = proposedLaunchDate

    this.$modal.find(".js-original-launch-date").text(this._formattedDate(originalLaunchDate))
    this.$modal.find(".js-days-away").text(daysAway)
    this.$modal.find(".js-new-launch-date").text(this._formattedDate(proposedLaunchDate))

    this.$modal.find(".js-accept-proposed-date").on("click", this._acceptProposedDateAndSubmit.bind(this))
  }

  render() {
    this.$modal.modal()
  }

  _formattedDate(date) {
    return new moment(date).format("MMMM Do")
  }

  _acceptProposedDateAndSubmit() {
    [[".js-year-select", this.proposedLaunchDate.getFullYear()],
      [".js-month-select", this.proposedLaunchDate.getMonth() + 1],
      [".js-day-select", this.proposedLaunchDate.getDate()]
    ].forEach((klassAndDateValue) => {
      const klass = klassAndDateValue[0]
      const dateValue = klassAndDateValue[1]

      this.$view.find(klass).val(dateValue)
    })

    this.$view.find(".js-level-form").submit()
  }
}

registerView('.js-course_progression-launches-_level6', ($view, $$) => {
  const datePicker = new DatePicker($view)

  let renderedModal = false
  const renderLaunchSoonerModalIfNeeded = (e) => {
    if (renderedModal) {
      return
    }

    const launchDate = datePicker.getDate()
    if (launchDate) {
      const today = new Date()
      today.setHours(0, 0, 0, 0) // also clears min, sec, ms

      const daysAway = Math.round((launchDate - today) / (1000 * 60 * 60 * 24))
      if (daysAway > 28) {
        // Stop the form from submitting
        e.preventDefault()

        const daysAwayToPropose = Math.floor(daysAway / 2)
        const dateToPropose = new Date()
        dateToPropose.setDate(dateToPropose.getDate() + daysAwayToPropose)

        new LaunchSoonerModal($view, launchDate, dateToPropose, daysAway).render()
        renderedModal = true
      }
    }
  }
  $view.find(".js-next-level-button").on("click", renderLaunchSoonerModalIfNeeded)

  const shouldEnableAdvanceToLevel7 = ($form) => {
    if (!AdvanceToNextLevelForm.defaultShouldEnableSubmit($form)) {
      return false
    }
    // Ensure the date is not in the past
    return datePicker.isDateInValidRange()
  }

  new AdvanceToNextLevelForm($$('.js-level-form'), { shouldEnableSubmit: shouldEnableAdvanceToLevel7 })
})
