import registerView from 'lib/scope'

registerView('.js-home-landing_v3', ($view, $$) => {
  $('body').scrollspy({target: '.js-navigation-menu', offset: window.innerHeight * 1 / 4 })

  // If the page loads with no scroll, don't show any active menu elements.
  // By default, scrollspy has some weird behavior where it just highlights the first section, and then immediately
  // un-highlights it when scrolling begins. So we want to avoid that.
  if (window.scrollY < 10) {
    $$(".js-navigation-item").removeClass("active")
  }
})
