import registerView from "lib/scope"
import LineChart from "views/shared/ticker/line_chart"

const chartOptions = {
  scales: {
    yAxes: [
      {
        display: true,
        ticks: { display: false },
        gridLines: { display: true }
      }
    ],
    xAxes: [
      {
        display: true,
        ticks: { display: false },
        gridLines: { display: true },
      }
    ]
  },
  tooltips: {
    enabled: false
  },
}

registerView(".js-applicants-home-_priorities-preview", ($view, $$) => {
  $$(".js-line-chart-preview").each((idx, selector) => {
		const context = $$(selector)
		new LineChart($view, context, chartOptions)
	})
})
