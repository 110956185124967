import registerView from 'lib/scope'
import AutosaveForm from 'lib/autosave_form'
import AreYouSure from 'lib/are_you_sure'
import ButtonGroup from 'components/button_group'
import CharactersRemaining from 'lib/characters_remaining'
import Checklist from 'components/checklist'
import ClientSideValidationView from 'components/client_side_validation_view'
import CountryStateSelect from 'components/country_state_select'
import Select from 'components/select'
import Textarea from 'components/textarea'
import analytics from 'lib/analytics'
import { handleEnterOnInputFields } from 'lib/form_helpers'

const AUTOSAVING_ENABLED = false;

class TeammatesView {
  constructor($view) {
    this.$view = $view

    this._clearUnusedFormData()
    this._refreshControls()
    $view.find('.js-add-teammate').on('click', (e) => this.addTeammate())
    $view.find('.js-remove-teammate').on('click', (e) => this.removeTeammate($(e.currentTarget)))
  }

  addTeammate() {
    let $teammateForm = $(this.$view.find('.js-unused-teammate-form').get(0))
    $teammateForm.find('input,select,textarea').prop('disabled', false)
    $teammateForm.removeClass('d-none').removeClass('js-unused-teammate-form')
    this._refreshControls()
  }

  removeTeammate($target) {
    let $teammateForm = $target.parents('.js-teammate-form')
    $teammateForm.addClass('d-none').addClass('js-unused-teammate-form')
    this._clearUnusedFormData()
    this._refreshControls()
  }

  _clearUnusedFormData() {
    this.$view.find('.js-unused-teammate-form').find('input,select,textarea').prop('disabled', true).val(null)
  }

  _refreshControls() {
    if (this.$view.find('.js-unused-teammate-form').length) {
      this.$view.find('.js-add-teammate').removeClass('d-none')
    } else {
      this.$view.find('.js-add-teammate').addClass('d-none')
    }
    this._updateSidebar()
  }

  // TODO: fix breaking scope in two separate ways
  _updateSidebar() {
    $('.js-grant_applications-teammate').each((i, el) => {
      let $el = $(el)
      $(`.js-teammate-${$el.data('teammate')}-anchor`).toggleClass('d-block', $el.is(':visible')).toggleClass('d-none', !$el.is(':visible'))
    })
    $('.js-sidebar-column-content').trigger('sidebarUpdated')
  }
}

registerView('.js-grant_applications-teammate', ($view, $$) => {
  new CountryStateSelect($$('.js-country-state-select'))
  new ButtonGroup($$('.js-gender-button-group'))

  if ($$('.js-birthdate-input').prop('type') != 'date') {
    $$('.js-native-datepicker').remove()
    $$('.js-date-dropdowns').removeClass('d-none')
  } else {
    $$('.js-date-dropdowns').remove()
  }
})


registerView('.js-grant_applications-show', ($view, $$) => {
  $$('textarea').each((i, el) => {
    new Textarea($$(el))
  })

  $$('.js-text-input-with-character-limit').each((i, el) => {
    new CharactersRemaining($$(el))
  })

  new ClientSideValidationView($view)

  handleEnterOnInputFields($view)

  if (AUTOSAVING_ENABLED) {
    let $form = $$('form.js-autosave')
    if ($form.length) {
      new AutosaveForm($form)
    }
  }

  $$('.js-save-progress-button').on('click', (e) => {
    $$('.js-save-progress-hidden-field').prop('disabled', false)
    let $form = $$('form')
    $form.disableClientSideValidations()
    $form.submit()
  })

  new TeammatesView($$('form'))

  let $secondaryCategoriesContainer = $$('.js-secondary-categories')
  new Checklist($secondaryCategoriesContainer, { maxCheckboxes: 2 })

  let $primaryCategorySelect = $$('.js-primary-project-category-field')
  let onPrimaryCategoryChange = () => {
    $$('.js-secondary-category-checkbox-container').removeClass('d-none')
    let selectedCategoryId = $primaryCategorySelect.find(':selected').val()
    if (selectedCategoryId) {
      let $checkboxContainer = $$(`.js-secondary-category-checkbox-container[data-project-category-id=${selectedCategoryId}]`)
      $checkboxContainer.addClass('d-none')
      $checkboxContainer.find('input').prop('checked', false)
    }
  }
  $primaryCategorySelect.on('change', () => onPrimaryCategoryChange())
  onPrimaryCategoryChange()


  $('body').scrollspy({ target: '.js-scrollspy', offset: window.innerHeight * 1 / 4 })

  // TODO: probably an opportunity for some ConditionalFormStep module. Could get rid of OtherInput.
  let $outcomeContainer = $$('.js-long-term-outcome-container')
  new Select($outcomeContainer)

  let onLongTermOutcomeChange = () => {
    let outcome = $outcomeContainer.find('option:selected').val()
    let $competitorsContainer = $$('.js-competitors-container')
    if ($competitorsContainer.length) {
      let label = $competitorsContainer.data('labels')[outcome]
      $competitorsContainer.find('label').text(label)
    }

    let isCompany = outcome === 'company'
    $$('.js-company-fields').toggleClass('d-none', !isCompany)
    let $inputs = $$('.js-company-fields').find('input,select,textarea')
    if (!isCompany) {
      $inputs.val(null).change()
    }
    $inputs.prop('disabled', !isCompany)
  }
  $outcomeContainer.on('change', () => onLongTermOutcomeChange())
  onLongTermOutcomeChange()

  new ButtonGroup($$('.js-incorporated-button-group'))
  let $incorporatedInput = $$('.js-incorporated-button-group .js-button-group-input')
  let onIncorporatedChange = () => {
    let isIncorporated = $incorporatedInput.val() === 'true'
    $$('.js-incorporated-company-fields').toggleClass('d-none', !isIncorporated)

    let $inputs = $$('.js-incorporated-company-fields').find('input,select,textarea')
    if (!isIncorporated) {
      $inputs.val(null).change()
    }
    $inputs.prop('disabled', !isIncorporated)
  }
  $incorporatedInput.on('change', () => onIncorporatedChange())
  onIncorporatedChange()

  let $errors = $$('.help-block.text-danger')
  if ($errors.length) {
    $errors.get(0).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }

  new AreYouSure($$)

  let updateTeammateTitles = (i) => {
    let first = $$(`.js-first-name-input[data-i=${i}]`).val()
    let last = $$(`.js-last-name-input[data-i=${i}]`).val()

    let newTitle;
    if (first && last) {
      newTitle = first + ' ' + last
    } else {
      newTitle = $($$(`.js-teammate-title[data-i=${i}]`)[0]).data('fallback')
    }
    $$(`.js-teammate-title[data-i=${i}]`).text(newTitle)
  }

  $$('.js-first-name-input,.js-last-name-input').on('change', (e) => {
    updateTeammateTitles($(e.currentTarget).data('i'))
  })

  analytics.track('Viewed application')
})
