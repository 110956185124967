import registerView from 'lib/scope'
import ButtonGroup from 'components/button_group'
import Textarea from 'components/textarea'
import Tabs from 'components/tabs'

registerView('.js-feedback-new', ($view, $$) => {
  $$('textarea').each((i, el) => {
    new Textarea($$(el))
  })

  new Tabs($view)
})
