import registerView from 'lib/scope'
import Textarea from 'components/textarea'
import CharactersRemaining from 'lib/characters_remaining'
import Countdown from 'lib/countdown'

registerView('.js-home-challenge', ($view, $$) => {
  $$('textarea').each((i, el) => {
    new Textarea($$(el))
  })

  $$('.js-text-input-with-character-limit').each((i, el) => {
    new CharactersRemaining($$(el))
  })

  new Countdown($$('.js-registration-countdown'))
})

