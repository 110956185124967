import registerView from 'lib/scope'
import AreYouSure from 'lib/are_you_sure'
import Countdown from 'lib/countdown'
import Tabs from 'components/tabs'

registerView('.js-course_progression-launches-_level7', ($view, $$) => {
  function syncCheckboxAndNextButton() {
    const checked = $$(".js-course_progression-launches-level7-accountability-checkbox").is(":checked")
    const $el = $$('.js-course_progression-launches-level7-next')
    $el.prop("disabled", !checked)
  }

  function renderLaunchModal() {
    const $launchModal = $$('.js-level7-launch-modal')
    if ($launchModal.length) {
      $launchModal.modal()
    }
  }

  function manageCountdown(tabname) {
    $(".js-launch-countdown-wrapper").toggleClass("d-none", tabname == "countdown")
  }

  function sendForm($form, callback) {
    const url = $form.attr('action');
    const data = $form.serialize();

    AreYouSure.reinitialize($form)

    $.ajax({
      url: url,
      type: 'post',
      data: data,
      success: callback
    });
  }

  $$(".js-course_progression-launches-level7-accountability-checkbox").on('click', syncCheckboxAndNextButton)

  const submitFinalLaunchForm = () => {
    const $form = $$('.js-course_progression-launches-level7-actual-launch').find('.js-actual-launch-form')
    sendForm($form, renderLaunchModal)
  }
  $$(".js-launch-url-input").on("keypress", (e) => {
    if (e.keyCode == 13) {
      e.preventDefault()

      // Only automatically submit the form if the input has a value
      if ($(e.currentTarget).val().length) {
        submitFinalLaunchForm()
      }
    }
  })
  $$(".js-course_progression-launches-level7-actual-launch-next").on('click', submitFinalLaunchForm)

  const $countdown = $$(".js-launch-l7-countdown")
  new Countdown($countdown, { showMilliseconds: true })

  const initialTabname = $view.data("tab")
  new Tabs($$('.js-course_progression-launches-level7-tabs'), {
    initialTabname: initialTabname,
    afterSetActiveTab: (data) => {
      syncCheckboxAndNextButton()
      manageCountdown(data.tabname)
    }
  })
})
