import autosize from 'autosize'

export default class Textarea {
  constructor($input) {
    this.$input = $input
    autosize(this.$input)

    this._setMinHeight()
  }

  _setMinHeight() {
    if (this._maxLength()) {
      if (this._maxLength() > 600)
        this.$input.addClass('min-height--lg')
      else if (this._maxLength() > 200) {
        this.$input.addClass('min-height--md')
      }
    }
  }

  _maxLength() {
    return this.$input.prop('maxlength')
  }
}
