import registerView from 'lib/scope'
import Textarea from 'components/textarea'
import TextInput from 'components/text_input'

registerView('.js-forum-posts-_comment_form', ($view, $$) => {
  $$(".js-comment-input").each((i, el) => {
    const $el = $(el)
    new Textarea($el)
    new TextInput($el, { removeEmojis: true })
  })
})
