// Options:
// - showCharactersUsed: show `X/100` characters used instead of `Y characters remaining` (defaults to false)
export default class CharactersRemaining {
  constructor($view, options = {}) {
    this.$input = $view.find('.js-characters-remaining-input')
    this.$charactersRemaining = $view.find('.js-characters-remaining')

    let defaults = {
      showCharactersUsed: false,
    }
    this.options = Object.assign({}, defaults, options);

    if (!this._maxLength()) {
      $view.removeClass('character-limit')
    } else {
      this.refreshCharactersRemaining()
      this.$input.on('change keyup', () => this.refreshCharactersRemaining())
    }
  }

  refreshCharactersRemaining() {
    if (!this._maxLength()) { return }

    let charactersUsed = (this.$input.val() || '').length

    let newText

    if (this.options.showCharactersUsed) {
      newText = `${charactersUsed}/${this._maxLength()}`
    } else {
      let charactersRemaining = this._maxLength() - charactersUsed
      newText = `${charactersRemaining} character${charactersRemaining == 1 ? '' : 's'} left`
    }

    this.$charactersRemaining.text(newText)
  }

  _maxLength() {
    return this.$input.prop('maxlength')
  }
}
