import LineChart from "views/shared/ticker/line_chart"
import TickerEventNode from "views/applicants/ticker/ticker_event_node"

export default class TickerTable extends TickerEventNode {
  constructor($view) {
    super($view)
    this.$view = $view

    this.openSlots = []
    this.allSlots = []
    this.initializeSlots()

    this.defaultCompanyName = "Company"
  }

  onSelect(event, eventData) {
    this.pushData(eventData.tableData)
  }

  onDeselect(event, eventData) {
    const symbol = eventData.symbol

    this.removeDataBySymbol(symbol)
  }

  initializeSlots() {
    this.$view.find("th.js-table-slot").each((idx, selector) => {
      const slotNumber = this.$view.find(selector).data("slot")

      this.openSlots.push(slotNumber)
      this.allSlots.push(slotNumber)
    })

    // sort slots in DESC order
    this.allSlots.sort((a, b) => b - a)
    this.sortOpenSlots()
  }

  // Sort DESC order
  sortOpenSlots() {
    this.openSlots.sort((a, b) => b - a)
  }

  popOpenSlot() {
    if (this.openSlots.length > 0) {
      const slotNumber = this.openSlots.pop()

      return slotNumber
    } else {
      return null
    }
  }

  peekOpenSlot() {
    if (this.openSlots.length > 0) {
      const slotNumber = this.openSlots[this.openSlots.length - 1]

      return slotNumber
    } else {
      return null
    }
  }

  getOpenSlots() {
    return this.openSlots
  }

  // Removes data where the header matches the symbol
  // Does nothing if it cannot find the symbol
  removeDataBySymbol(symbol) {
    let slotNumber = null
    this.$view.find("th.js-table-slot").each((idx, selector) => {
      const $th = this.$view.find(selector)
      if ($th.html() === symbol) {
        slotNumber = $th.data("slot")
      }
    })

    this.removeData(slotNumber)
  }

  // Removes data from the specific slot
  // left aligns data in slots after removing data
  removeData(slot) {
    this.freeTableSlot(slot)

    this.openSlots.push(slot)
    this.sortOpenSlots()
  }

  // Returns the assigned table slot number
  pushData(tableData) {
    const assignedSlot = this.popOpenSlot()
    if (assignedSlot === null) return

    this.putData(tableData, assignedSlot)

    return assignedSlot
  }

  putData(tableData, slot) {
    const symbol = tableData["name"] || this.defaultCompanyName
    this.setSlotHeader(slot, symbol)

    this.$view.find(`tbody td[data-slot="${slot}"]`).each((idx, selector) => {
      const $td = this.$view.find(selector)
      const key = $td.data("key")

      const renderable = TickerTable.tableDatasetToHTML(tableData[key])
      $td.find(".js-data-value").html(renderable)
      if (tableData[key].tooltip) {
        $td.find(".js-data-value").data({ html: true, tooltip: true, title: tableData[key].tooltip.title })
        $td.find(".js-data-value").tooltip({ delay: { "show": 100, "hide": 0 }, trigger: "hover" })
      }

      $td.find(".js-data-value").html(renderable)

      $td.find(".js-data-value").toggleClass("d-none", false)
      $td.find(".js-default-value").toggleClass("d-none", true)
    })
  }

  freeTableSlot(slot) {
    const $header = this.$view.find(`thead th.js-table-slot[data-slot=${slot}]`)
    const isOccupied = $header.data("occupied")
    if (!isOccupied) return

    $header.html("")
    $header.data("occupied", false)

    this.$view.find(`tbody td[data-slot="${slot}"]`).each((idx, selector) => {
      const $td = this.$view.find(selector)

      $td.find(".js-data-value").html("")

      $td.find(".js-default-value").toggleClass("d-none", false)
      $td.find(".js-data-value").toggleClass("d-none", true)
    })
  }

  setSlotHeader(slot, value) {
    this.$view.find(`thead th.js-table-slot[data-slot=${slot}]`).each((idx, selector) => {
      const $header= this.$view.find(selector)

      $header.html(value)
      $header.data("occupied", true)
    })
  }

  getNextColor() {
    const slotNumber = this.peekOpenSlot()
    if (slotNumber !== null) return TickerTable.getSlotColor(slotNumber)

    return null
  }

  static getSlotColor(slotNumber) {
    // const yellow = "#FFE44B"
    const blue = "#5189F6"
    const green = "#56D882"
    const red = "#E76046"
    const colors = [green, blue, red]

    return colors[slotNumber]
  }

  /*
    Attributes: {text: string[], class: string}
    class gets uniformally applied
   */
  static tableDatasetToHTML(tableDataset) {
    const ret = tableDataset.text.map((text) => {
      return `<div class="${tableDataset.class}">${text}</div>`
    })

    return ret.join(" ")
  }

  static populateGrowthsInTableData(tableData, dataset) {
    Object.entries(tableData).forEach(([key, value]) => {
      if (value && value.type === "growth") {
        const lookbackDuration = value.value * 1000

        const end = Date.now()
        const start = end - lookbackDuration

        const subset = LineChart.applyTimeFilterToDatasets([dataset], start, end)[0]

        const firstDataPoint = subset.data[0]

        // Only render growth data if the given dataset has data that extends back that far
        if (firstDataPoint && firstDataPoint.x <= start) {
          const { percentage } = LineChart.getGrowthFromDataset(subset)

          tableData[key] = { ...value, text: [ `${Math.round(percentage)}%` ] }
        } else {
          tableData[key] = { ...value, text: ["-"]}
        }
      }
    })

    return tableData
  }
}
