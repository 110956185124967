import emojiRegex from 'emoji-regex'
const EMOJI_REGEX = emojiRegex()

export default class TextInput {
  constructor($input, options = {}) {
    this.$input = $input

    if (options.hasOwnProperty("removeEmojis")) {
      this.removeEmojis = options.removeEmojis
    } else {
      this.removeEmojis = true
    }

    if (this.removeEmojis) {
      this._removeEmojis()
      this.$input.on("keyup change", this._removeEmojis.bind(this))
    }
  }

  _removeEmojis() {
    let text = this.$input.val() || ""
    let newText = text.replace(EMOJI_REGEX, '')
    this.$input.val(newText)
  }
}
