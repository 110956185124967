import registerView from 'lib/scope'

registerView('.js-shared-voting-_follow_project', ($view, $$) => {
  let $tooltip = $$('.js-follow-tooltip')
  $tooltip.tooltip({ delay: { 'show': 500, 'hide': 0 }, trigger: 'hover' })
  $tooltip.on('click', (e) => {
    // Clicking the tooltip should not affect the label
    e.preventDefault()
  })
})
