class DatePicker {
  constructor($view, options =  {}) {
    this.$view = $view
    this.$view.find(".js-day-select, .js-month-select, .js-year-select").change(this.validateDate.bind(this))
    this.maxDate = options.maxDate
  }

  getDate() {
    const dateParts = [".js-year-select", ".js-month-select", ".js-day-select"].map((klass) => {
      return this.$view.find(klass).val()
    })

    // If all 3 fields are present
    if (dateParts.every(date_part => date_part)) {
      return new Date(
          dateParts[0],
          dateParts[1] - 1, // Months are offset by 1 in JS
          dateParts[2]
      )
    } else {
      return null
    }
  }

  isDateInValidRange() {
    const date = this.getDate()
    if (date === null) return null

    const today = new Date()
    today.setHours(0, 0, 0, 0) // also clears min, sec, ms
    const isDateInPast = date <= today
    let ret = !isDateInPast

    if (this.maxDate !== undefined) {
      const isDatePastMaxDate = date > this.maxDate
      ret = ret && !isDatePastMaxDate
    }

    // not in the past, not past maxDate (if exists)
    return ret
  }

  validateDate() {
    if (this.isDateInValidRange() !== null) {
      this.$view.find(".js-date-in-past-validation").toggleClass("d-none", this.isDateInValidRange())
    }
  }
}

export default DatePicker
