import Chart from "chart.js"
import { MS_IN_WEEK, MS_IN_DAY } from "lib/time_helper";

function getDefaultOptions() {
  const defaultOptions = {
    animation: false,
    legend: { display: false },
    spanGaps: true,
    scales: {
      yAxes: [{
        ticks: {
          maxTicksLimit: 4,
          min: 0
        },
      }],
      xAxes: [{
        type: "time",
        gridLines: {
          drawOnChartArea: false,
        },
      }]
    },
    layout: {
      padding: {
        left: 0,
        right: 10,
        top: 10,
        bottom: 0
      }
    },
    tooltips: {
      intersect: false,
      displayColors: false,
    }
  }

  return defaultOptions
}

function getDefaultDatasetOptions() {
  const defaultDatasetOptions = {
    borderWidth: 2,
    fill: false,
    lineTension: 0.05
  }

  return defaultDatasetOptions
}

// JS does not have a deep merge fn
// Need to merge nested options with defaults
function deepMerge(a, b) {
  return Object.entries(b).reduce((o, [k, v]) => {
    o[k] = v && typeof v === 'object'
        ? deepMerge(o[k] = o[k] || (Array.isArray(v) ? [] : {}), v)
        : v;
    return o;
  }, a);
}

/*
* Options:
* { prefix: string,
*   suffix: string,
*   borderColor: string,
*   options: ChartConfig
* }
* */

export default class LineChart {
  constructor($view, context, chartOptions = {}, plugins = []) {
    this.context = context
    this.$view = $view

    this.chartData = context.data("chart-data")
    this.borderColor = context.data("color")
    this.prefix = context.data("prefix") || ""
    this.suffix = context.data("suffix") || ""

    const datasets = LineChart.getDatasetsFromChartData(this.chartData, { color: this.borderColor, interpolateSinglePoint: true })

    const sortedDatasets = datasets.map((dataset) => LineChart.sortDataset(dataset))
    this.datasets = sortedDatasets

    const timeConfig = LineChart.generateTimeConfig(this.datasets, this.context[0].offsetWidth)

    const defaultOptions = getDefaultOptions()
    const options = deepMerge(defaultOptions, chartOptions)

    options.scales.xAxes[0]["time"] = timeConfig
    options.scales.yAxes[0].ticks["callback"] = (value) => {
      return `${this.prefix}${value.toLocaleString()}${this.suffix}`
    }
    options.tooltips["callbacks"] = {
      label: (tooltipItem, data) => {
        const datasetLabel = data.datasets[tooltipItem.datasetIndex].label

        return `${datasetLabel}: ${this.prefix}${tooltipItem.yLabel.toLocaleString()}${this.suffix}`
      }
    }

    const chartConfigs = {
      plugins: plugins,
      maintainAspectRatio: false,
      type: "line",
      data: { datasets: this.datasets },
      options: options
    }

    this.chart = new Chart(context, chartConfigs)
  }

  getPrimaryDataset() {
    return this.datasets[0]
  }

  getDatasets() {
    return this.datasets
  }

  getCurrentPrimaryDataset() {
    return this.chart.data.datasets[0]
  }

  getCurrentChartDatasets() {
    return this.chart.data.datasets
  }

  getPrimaryDatasetFrom(start, end) {
    const primaryDataset = this.getPrimaryDataset()
    const timeFilteredDataset = LineChart.applyTimeFilterToDataset([primaryDataset], start, end)[0]

    return timeFilteredDataset
  }

  // Sorts dataset in ASC order by x in {x, y}
  sortDatasets() {
    this.datasets = this.datasets.map((dataset) => {
      dataset = LineChart.sortDataset(dataset)

      return dataset
    })
  }


  update() {
    this.refreshTimeConfig()
    this.chart.update()
  }

  updateDatasets(datasets) {
    this.chart.data.datasets = datasets
    this.update()
  }

  refreshTimeConfig() {
    const newTimeConfig = LineChart.generateTimeConfig(this.chart.data.datasets, this.context[0].offsetWidth)
    const oldTimeConfig = this.chart.config.options.scales.xAxes[0].time

    this.chart.options.scales.xAxes[0].time = {...oldTimeConfig, ...newTimeConfig}
    this.chart.update()
  }

  reset() {
    this.chart.data.datasets = this.datasets
    this.update()
  }

  resetBorderColor() {
    this.chart.data.datasets.forEach((dataset) => {
      dataset["borderColor"] = this.borderColor
    })
    this.update()
  }

  // param: chartData[]
  // Order is the canvas draw order. Treat order as an increasing id.
  addDatasetFromChartData(chartData, color) {
    const datasets = LineChart.getDatasetsFromChartData(chartData, { color })

    const existingDatasetOrder = this.datasets.map((dataset) => dataset.order)
    const highestOrder = Math.max(existingDatasetOrder)

    const datasetOrderNumbers = datasets.map((dataset, idx) => {
      const order = highestOrder + idx + 1

      const sortedDataset = LineChart.sortDataset(dataset)
      this.datasets.push({...sortedDataset, order })

      return order
    })

    this.chart.data.datasets = this.datasets
    this.update()

    return datasetOrderNumbers
  }

  //Remove datasets by specifying their order number
  removeDatasetFromChartData(order) {
    const idx = this.datasets.findIndex((dataset) => dataset.order === order)
    this.datasets.splice(idx, 1)
    this.chart.data.datasets = this.datasets

    this.update()
  }

  // Sorts dataset in ASC order by x in {x, y}
  static sortDataset(dataset) {
    dataset.data.sort((a, b) => {
      return a.x - b.x
    })

    return dataset
  }

  static getGrowthFromDataset(dataset) {
    if (!dataset || dataset.data.length === 0) {
      return { percentage: 0, quantity: 0 }
    }

    // Treat falsey & 0 as 1 to avoid infinite growth
    // sort dataset by date
    dataset = LineChart.sortDataset(dataset)

    const firstValue = dataset.data[0].y
    const lastValue = dataset.data[dataset.data.length - 1].y

    const quantity = lastValue - firstValue

    // If firstValue = 0, pretend like its 1 to avoid infinite percentages
    const percentageFirstValue = firstValue || 1
    const percentage = (quantity/percentageFirstValue * 100) || 0

    return { percentage, quantity }
  }

  // Parts ripped from from chartkick, starting line 786
  // Generates step size and units for time series line-charts
  static generateTimeConfig(datasets, chartOffsetWidth) {
    let minTime = Number.MAX_VALUE
    let maxTime = 0

    for (const dataset of datasets) {
      dataset.data.forEach((point) => {
        const dateInEpoch = new Date(point.x).getTime()

        minTime = Math.min(dateInEpoch, minTime)
        maxTime = Math.max(dateInEpoch, maxTime)
      })
    }

    const timeDiff = (maxTime - minTime) / MS_IN_DAY

    let step;
    let unit;
    let displayFormats;
    let tooltipFormat;
    let unitStepSize;

    const tenYears = 365 * 10
    const tenMonths = 30 * 10
    const tenDays = 10
    const fiveDays = 5
    const halfDay = 0.5

    if (timeDiff > tenYears) {
      unit = "year"
      step = 365;
    } else if (timeDiff > tenMonths) {
      unit = "month";
      tooltipFormat = "ll"
      step = 30;
    } else if (timeDiff > tenDays) {
      unit = "day";
      tooltipFormat = "ll";
      step = 1;
    } else if (timeDiff > fiveDays) {
      displayFormats = { hour: "MMM D" };
      unit = "hour"
      tooltipFormat = "MMM D";
      step = 1 / 24.0;
      unitStepSize = 24;
    } else if (timeDiff > halfDay) {
      displayFormats = { hour: "MMM D, h a" };
      unit = "hour"
      tooltipFormat = "MMM D, h a";
      step = 1 / 24.0;
    } else  {
      displayFormats = { minute: "h:mm a" };
      unit = "minute"
      tooltipFormat = "h:mm a";
      step = 1 / 24.0 / 60.0;
    }

    if (!unitStepSize) {
      if (step && timeDiff > 0) {
        unitStepSize = Math.ceil(timeDiff / step / (chartOffsetWidth / 100.0));
      }
    }

    const ret = { tooltipFormat, unit, unitStepSize }

    if (displayFormats) {
      ret["displayFormats"] = displayFormats
    }

    return ret
  }

  // ChartData is presenter.data
  // interpolateSinglePoint will interpolate a second point a week earlier if there is only one data point
  static getDatasetsFromChartData(chartData, { color, interpolateSinglePoint } = {}) {
    const datasets = []

    for (const idx in chartData) {
      const data = chartData[idx]

      const formattedChartData = Object.entries(data.data).map(([key, value]) => {
        return { x: new Date(key).getTime(), y: value}
      })

      if (formattedChartData.length === 1 && interpolateSinglePoint) {
        const singlePoint = formattedChartData[0]

        const pairedPoint = { x: singlePoint.x - MS_IN_WEEK, y: singlePoint.y }

        formattedChartData.push(pairedPoint)
      }

      const defaultDatasetOptions = getDefaultDatasetOptions()

      const ret = {
        ...defaultDatasetOptions,
        ...data.dataset,
        label: data.name,
        data: formattedChartData,
      }

      if (color) {
        ret["borderColor"] = color
        ret["pointBackgroundColor"] = color
      }

      datasets.push(ret)
    }

    return datasets
  }

  // Generates dataset with 1 point at startEpoch and 1 point at endEpoch
  // Dataset is not visible
  static placeholderDataset(startEpoch, endEpoch) {
    const startPoint = { x: new Date(startEpoch), y: null }
    const endPoint = { x: new Date(endEpoch), y: null }

    return { fill: false, pointRadius: 0, borderWidth: 0, data: [ startPoint, endPoint ] }
  }

  static applyTimeFilterToDatasets(datasets, start, end) {
    const filteredDatasets = datasets.map((dataset) => {
      const filteredData = dataset.data.filter(({x}) => {
        const epoch = new Date(x).getTime()

        return (start <= epoch && epoch <= end )
      })

      const calculatedValue = LineChart.getValueAt(start, dataset.data)
      if (calculatedValue !== null) {
        const interpolatedPoint = { x: start, y: calculatedValue }

        filteredData.unshift(interpolatedPoint)
      }

      return {...dataset, data: filteredData}
    })

    filteredDatasets.push(LineChart.placeholderDataset(start, end))

    return filteredDatasets
  }

  // Shifts: Shift[] shifts corresponding idx of chart.data.datasets
  //
  // Shift:
  // { x: number
  //  y: number }
  static applyShiftsToDatasets(datasets, shifts) {
    const shiftedDatasets = datasets.map((dataset, idx) => {
      const shift = shifts[idx]
      const shiftedData = dataset.data.map((point) => {

        const pointX = new Date(point.x).getTime() + shift.x

        return { x: pointX, y: point.y }
      })

      return {...dataset, data: shiftedData}
    })

    return shiftedDatasets
  }

  // epoch: number
  // data: {x: number, y: number}[]
  // if exists x < epoch:
  //    find the closest x smaller than epoch and the closest x larger than epoch
  //    find the slope between the two and find what epoch's y value would be
  // else
  //    return null
  static getValueAt(epoch, data) {
    let closestLeft = { x: 0, y: null }
    let closestRight = { x: Number.MAX_VALUE, y: null }

    data.forEach(({x, y}) => {
      if (x == epoch) return y

      if (x < epoch && x > closestLeft.x) {
        closestLeft = {x, y}
      }

      if (x > epoch && x < closestRight.x) {
        closestRight = {x, y}
      }
    })

    if (closestLeft.y === null) return null

    const run = closestRight.x - closestLeft.x
    const rise = closestRight.y - closestRight.y

    const slope = rise/run

    return (slope * epoch) + closestLeft.y
  }
}
