import registerView from 'lib/scope'
import analytics from "lib/analytics"

registerView('.js-forum-posts-_preview', ($view, $$) => {
  $$(".js-link-to-post-url").on("click", (e) => {
    let $el = $(e.currentTarget)

    analytics.track("[Frontier] Opened post link", {
      postId: $view.data("post-hex-uid"),
      url: $el.attr("href"),
      challenge: $view.data("challenge"),
    })
  })
})
