import registerView from 'lib/scope'
import AreYouSure from 'lib/are_you_sure'
import LocalStorageForm from 'lib/local_storage_form'
import Textarea from 'components/textarea'
import CharactersRemaining from 'lib/characters_remaining'
import ClientSideValidationView from 'components/client_side_validation_view'
import NumericGoalInput from "views/shared/project_priorities/numeric_goal_input"
import { handleEnterOnInputFields } from 'lib/form_helpers'
import analytics from 'lib/analytics'

registerView('.js-progress_updates-edit', ($view, $$) => {
  const $form = $$('form')

  $$('textarea').each((i, el) => {
    new Textarea($(el))
  })

  $$('.js-text-input-with-character-limit').each((i, el) => {
    new CharactersRemaining($(el))
  })

  new ClientSideValidationView($view)
  handleEnterOnInputFields($view)

  $$(".js-numeric-priority-status-input").each((i, el) => {
    new NumericGoalInput($(el))
  })

  $$('.js-save-progress-button').on('click', (e) => {
    $$('.js-save-progress-hidden-field').val(1)
    $form.disableClientSideValidations()
    $form.submit()
  })

  new AreYouSure($$)

  const progress_update_state = $view.data("state")
  if (progress_update_state !== "submitted") {
    new LocalStorageForm($$)
  }

  const $codexInputFields = $$(".js-codex-input-field")
  const onCodexInputFieldsChange = (animate = true) => {
    let lengthOfCodexInputFields = 0
    $codexInputFields.each((i, el) => {
      lengthOfCodexInputFields += $(el).val().length
    })

    // Wait until we have enough data in the input fields to produce meaningful results from Codex
    if(lengthOfCodexInputFields >= 20) {
      if (animate) {
        $$(".js-codex-column").addClass("with-animation")
      }

      $$(".js-codex-column").addClass("codex-column--visible")
      $$(".js-form-column").addClass("form-column-with-codex-column")
      $$(".js-codex-column").addClass("resources-loading")

      $.ajax({
        url: $$(".js-codex-recommendations").data("path"),
        data: $form.serialize(),
      }).done((data, textStatus, jqXHR) => {
        $$(".js-codex-recommendations").html(data)
        $$(".js-codex-recommendations .js-see-codex-url").on("click", (e) => {
          analytics.track("Clicked codex recommendation", {
            codexId: $view.data("codex-id"),
            href: $(e.currentTarget).attr("href")
          })
        })
      }).always(() => {
        $$(".js-codex-column").removeClass("resources-loading")
      })
    }
  }
  $codexInputFields.on('change', onCodexInputFieldsChange)
  onCodexInputFieldsChange(false)


})
