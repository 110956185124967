import _ from "underscore"

// $timer expects one of two fields define on the element's data:
//
// 1) data-time-remaining: This is more accurate and preferred, as it isn't dependent on the client's time being
// correct.
//
// 2) data-deadline: If the client's time is incorrect, the countdown will be wrong too. But this may field may be
// preferred e.g. when caching, assuming correctness isn't critical.
//
// options:
// - onTimerDone: callback when the countdown hits 0
// - customHandler: function that handles the render
// - alwaysShowDays: show days in the countdown even if 0; defaults to true
// - alwaysShowHours: show hours in the countdown even if hours and days are both 0; defaults to true
// - showMilliseconds: if true, you'll need to write CSS for the countdown-ms class to stop stuttering
export default class Countdown {
  constructor($timer, options) {
    this.$timer = $timer
    this.ranDoneCallback = false

    let defaults = {
      onTimerDone: null,
      customHandler: null,
      alwaysShowHours: true,
      alwaysShowDays: true,
      showMilliseconds: false,
    }
    this.options = Object.assign({}, defaults, options);
    this.$timer.on("deadlineChanged", () => {
      this.refreshDeadline()
    })

    this.refreshDeadline()
  }

  refreshDeadline() {
    if (this.$timer.data('time-remaining')) {
      this.deadline = Date.now() + parseFloat(this.$timer.data('time-remaining'))
    } else if (this.$timer.data('deadline')) {
      this.deadline = new Date(parseInt(this.$timer.data('deadline')))
    } else {
      this.deadline = null
    }

    this.updateTimer()
  }

  updateTimer() {
    if (!this.deadline) {
      this.$timer.text("")
      return
    }

    let secondsRemaining = (this.deadline - Date.now()) / 1000.0
    if (secondsRemaining <= 0) {
      this._renderTimer(0)
      if (!this.ranDoneCallback) {
        if (this.options.onTimerDone) {
          this.options.onTimerDone()
        }
        this.ranDoneCallback = true;
        this.$timer.trigger('done');
      }
    } else {
      this._renderTimer(secondsRemaining)
      const timeUntilNextUpdate = this.options.showMilliseconds ? 13 : 1000
      setTimeout(() => {
        this.updateTimer()
      }, timeUntilNextUpdate)
    }
  }

  _renderTimer(secondsRemaining) {
    if (this.options.customHandler) {
      this.options.customHandler(secondsRemaining)
      return
    }

    let secondsLeftToRender = secondsRemaining

    let days = Math.floor(secondsLeftToRender / 60 / 60 / 24)
    secondsLeftToRender -= (days * 60 * 60 * 24)
    let hours = Math.floor(secondsLeftToRender / 60 / 60)
    secondsLeftToRender -= (hours * 60 * 60)
    let minutes = Math.floor(secondsLeftToRender / 60)
    secondsLeftToRender -= (minutes * 60)
    let seconds = Math.floor(secondsLeftToRender)
    secondsLeftToRender -= seconds
    let milliseconds = Math.floor(secondsLeftToRender * 100)

    let timesToRender = [minutes, seconds]

    if (this.options.alwaysShowHours || hours > 0 || days > 0) {
      timesToRender.unshift(hours)
    }

    if (this.options.alwaysShowDays || days > 0) {
      timesToRender.unshift(days)
    }

    if (this.options.showMilliseconds) {
      timesToRender.push(milliseconds)
    }

    let paddedTimes = _.map(timesToRender, (t) => {
      return `${t < 10 ? '0' : ''}${t}`
    })

    if (this.options.showMilliseconds) {
      paddedTimes[paddedTimes.length - 1] =
          `<div class="d-inline-block text-left countdown-ms">${paddedTimes[paddedTimes.length - 1]}</div>`
    }

    let timerText = `${paddedTimes.join(':')}`
    this.$timer.html(timerText)
  }
}
