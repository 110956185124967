import registerView from "lib/scope"
import PercentChart from "views/course_progression/shared/percent_chart";
import AdvanceToNextLevelForm from "views/course_progression/shared/advance_to_next_level_form"
import Chart from "chart.js"

class PieChart extends PercentChart {
  constructor($view, context, chartConfigs) {
    const colors = chartConfigs.map((c) => c.color)
    const labels = chartConfigs.map((c) => c.label)

    const dataSumIndex = chartConfigs.findIndex((config) => {
      return config.selector === null && config.label === null
    })

    const chart = new Chart(context, {
      type: "pie",
      data: {
        datasets: [{
          data: [0, 0, 0, 100],
          backgroundColor: colors,
          borderColor: "#172A3B",
        }],
        labels: labels,
      },
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              const label = data.labels[tooltipItem.index]

              return `${label}: ${value}%`
            }
          },
          filter: (tooltipItem) => {
            if (tooltipItem.index === dataSumIndex) {
              return false
            }
            return true
          }
        },
        legend: {
          position: "left",
          onClick: () => {},
          labels: {
            fontColor: "#172A3B",
            filter: (legendItem) => {
              if (legendItem.index === dataSumIndex) {
                return false
              }
              return true
            }
          }
        },
      }
    })

    const selectors = chartConfigs.map((config) => config.selector).filter((selector) => selector)
    super($view, chart, selectors)
  }

  update() {
    const data = this.getInputSelectorData(true)
    const dataSum = data.reduce((acc, a) => acc + a, 0)

    const remainingPercents = Math.max(100 - dataSum, 0)
    data.push(remainingPercents)

    this.chart.data.datasets[0].data = data
    this.chart.update();
  }
}

registerView(".js-course_progression-pmf-_level4", ($view, $$) => {
  const chartConfigs = [
    {
      selector: ".js-a-number-users-input",
      label: "Must have",
      color: "#11D03D"
    }
    ,
    {
      selector: ".js-b-number-users-input",
      label: "Nice to have",
      color: "#36A2EB"
    },
    {
      selector: ".js-c-number-users-input",
      label: "Don't care",
      color: "#DC3545"
    },
    {
      selector: null,
      label: null,
      color: "#FFE44B"
    },
  ]

  const context = $$(".js-pie-chart")
  const pieChart = new PieChart($view, context, chartConfigs)

  const $successModal = $$('.js-level4-success-modal')
  if ($successModal.length) {
    $successModal.modal()
  }

  new AdvanceToNextLevelForm($$(".js-level-form"), {
    shouldEnableSubmit: ($form) => {
      return AdvanceToNextLevelForm.defaultShouldEnableSubmit($form) && pieChart.valid()
    }
  })
})
