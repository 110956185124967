// To have this work in development, enable chrome://flags/#unsafely-treat-insecure-origin-as-secure
// and add http://lvh.me:3000 to the list.

// See: https://github.com/muaz-khan/RecordRTC/blob/master/simple-demos/audio-recording.html

import RecordRTC from 'recordrtc';
import { v4 as uuidv4 } from 'uuid';
import Countdown from 'lib/countdown';

// 90 seconds (plus a ~1 second buffer - not a full second so we show 1:30 instead of 1:31)
const MAX_RECORDING_TIME_MILLISECONDS = 90999;

// 10 second minimum recording time (very short recordings break AWS Transcribe)
const MIN_RECORDING_TIME_SECONDS = 10;

export default class AudioInput {
  constructor($container) {
    // DOM elements
    this.$container = $container;
    this.$button = $container.find('.js-audio-input-button');
    this.$button.on('click', (e) => this.onClick(e));
    this.$playerContainer = $container.find('.js-audio-player-container');
    this.$volumeIndicator = $container.find('.js-audio-player-volume-indicator');
    this._registerOnPlayerSrcUpdatedListener();
    this.$hiddenFileInput = $container.find('.js-audio-file-field'); // to attach the file to the form
    this.$countdownContainer = $container.find('.js-audio-recording-countdown-container');
    this.$countdown = $container.find('.js-audio-recording-countdown');
    
    // Register the countdown
    this.countdownController = new Countdown(this.$countdown, {
      onTimerDone: () => this._stopRecording(),
      alwaysShowHours: false,
      alwaysShowDays: false,
    });

    // Instance vars to handle audio recording
    this.recorder = null; // the RecordRTC object
    this.microphone = null; // the stream returned from getUserMedia
    this.soundMeter = null; // sound meter to indicate the recording volume
    this.meterRefresh = null; // interval to update the volume indicator
    this.isRecording = false; // whether we're currently recording
  }

  onClick(e) {
    e.preventDefault(); // prevent form submission
    if (this.isRecording) {
      this._stopRecording();
    } else {
      this._startRecording();
    }
  }

  // Ask the user for microphone permissions (if we don't have them yet),
  // and call a callback on the returned stream.
  _captureMicrophone(callback) {
    if (this.microphone) {
      callback(this.microphone);
      return;
    }

    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      window.audioContext = new AudioContext();
    } catch (e) {
      alert('Unable to record: Web Audio API not supported.');
    }

    if (typeof navigator.mediaDevices === 'undefined' || !navigator.mediaDevices.getUserMedia) {
      alert('Unable to record: this browser does not support the WebRTC getUserMedia API.');
    }

    navigator.mediaDevices.getUserMedia({
      audio: true
    }).then((mic) => {
      callback(mic);
    }).catch((error) => {
      // Likely because the user previously blocked microphone access.
      alert(`Unable to record: ${error}`);
    });
  }

  // Replace the embedded audio player. If src is null, creates a hidden player with no src.
  _replaceAudioPlayer(src) {
    this.$playerContainer.html(`<audio class="js-audio-player" ${src ? `controls src="${src}" preload="auto"` : ''}></audio>`);
  }

  // Start recording audio.
  _startRecording() {
    // Make sure we have microphone access.
    if (!this.microphone) {
      this._captureMicrophone((mic) => {
        this.microphone = mic;
        this._startRecording();
      });
      return;
    }

    // Confirm with the user if they're about to replace their previous recording.
    if (this.$hiddenFileInput.val() && !confirm('Are you sure you want to start a new recording? Your previous recording will be lost.')) {
      return;
    }

    this.isRecording = true;
    this.$hiddenFileInput.val(null);
    this.$button.html('Stop recording');
    this.$button.removeClass('btn-success');
    this.$button.addClass('btn-danger');
    this.$volumeIndicator.removeClass('d-none').addClass('d-flex');

    this._replaceAudioPlayer();

    if (this.recorder) {
      this.recorder.destroy();
      this.recorder = null;
    }

    this.recorder = RecordRTC(this.microphone, { type: 'audio', mimeType: 'audio/webm', sampleRate: 96000 });
    this.recorder.startRecording();

    this.$countdown.data('time-remaining', MAX_RECORDING_TIME_MILLISECONDS);
    this.$countdown.trigger('deadlineChanged');
    this.$countdownContainer.removeClass('d-none').addClass('d-flex');

    this._startSoundMeter();
  }

  // Stop recording, and allow the user to play back the recorded audio.
  _stopRecording() {
    this.$button.html('Start new recording');
    this.$button.removeClass('btn-danger');
    this.$button.addClass('btn-success');
    this.$volumeIndicator.removeClass('d-flex').addClass('d-none');

    // Calculate recording time, as we don't want to allow super short recordings
    const countdownDeadline = this.countdownController.deadline;
    const secondsRemaining = countdownDeadline && (countdownDeadline - Date.now()) / 1000.0;
    const secondsElapsed = secondsRemaining && (MAX_RECORDING_TIME_MILLISECONDS / 1000.0) - secondsRemaining;
    
    this.$countdown.removeData('time-remaining');
    this.$countdown.trigger('deadlineChanged');
    this.$countdownContainer.removeClass('d-flex').addClass('d-none');

    if (!this.recorder) {
      this.isRecording = false;
    } else {
      this.recorder.stopRecording(() => {

        // Don't allow super short recordings
        if (secondsElapsed && secondsElapsed < MIN_RECORDING_TIME_SECONDS) {
          alert(`Your recording was too short. Please record for at least ${MIN_RECORDING_TIME_SECONDS} seconds.`);
          this.$hiddenFileInput.trigger('change'); // hiddenFileInput was previously set to null in _startRecording()
          this._registerOnPlayerSrcUpdatedListener();
          this.isRecording = false;
          this.$button.html('Start recording'); // make it clear there's no old recording to be overwritten
          return;
        }

        const blob = this.recorder.getBlob();

        // add recorded audio to player
        this._replaceAudioPlayer(URL.createObjectURL(blob));

        // add recorded audio to hidden file input
        this._addBlobToHiddenFileInput(blob, uuidv4()).then(() => {

          // trigger a change event to sync the linked audio input
          this.$hiddenFileInput.trigger('change');

          // if src is later updated by a linked input, make sure we update the file field as well
          this._registerOnPlayerSrcUpdatedListener();

          this.isRecording = false;
        });
      });
    }
  }

  // Start measuring input volume, and display on the meter.
  _startSoundMeter() {
    this.soundMeter = new SoundMeter(window.audioContext);
    this.soundMeter.connectToSource(this.microphone, (e) => {
      if (e) {
        console.error(e);
        return;
      }
      this.meterRefresh = setInterval(() => {
        this.$volumeIndicator.find('meter').val(this.soundMeter.instant.toFixed(2));
      }, 200);
    });
  }

  // Stop measuring input volume.
  _stopSoundMeter() {
    if (this.soundMeter) {
      this.soundMeter.stop();
      clearInterval(this.meterRefresh);
      this.$volumeIndicator.find('meter').val(0);
    }
  }

  // Add the recorded audio blob to the hidden file input (for form submission purposes).
  async _addBlobToHiddenFileInput(blob, uuid) {
    // If we received an object URL, convert it to a blob.
    if (typeof blob === 'string') {
      blob = await fetch(blob).then(r => r.blob());
    }

    const mimeType = blob.type;
    const extension = mimeType.split(';')[0].split('/')[1];
    const fileName = `recording_${uuid}.${extension}`;

    const container = new DataTransfer();
    const file = new File([blob], fileName, { type: mimeType });
    container.items.add(file);
    this.$hiddenFileInput.get(0).files = container.files;
  }

  // Register a listener to update the hidden file input when the audio player src
  // is updated by another caller (i.e. the other linked audio input in voting).
  // We have to reregister this listener every time we stop recording audio as
  // a new audio player component is created.
  _registerOnPlayerSrcUpdatedListener() {
    this.$playerContainer.find('.js-audio-player').on('playerSrcUpdated', (event, src, uuid) => {
      if (src) {
        if (!uuid) {
          throw 'Missing UUID';
        }
        // Update button text to make it more obvious that you've already recorded
        this.$button.html('Start new recording');
        
        // Populate the hidden file input for the form
        this._addBlobToHiddenFileInput(src, uuid).then(() => {
          // custom event to trigger refreshSubmitEnabled()
          this.$hiddenFileInput.trigger('fileInputUpdated');
        });
      } else {
        // src is null -> the recording was removed (likely due to a new recording less than the minimum length)
        this.$button.html('Start recording');
        this.$hiddenFileInput.val(null);
        this.$hiddenFileInput.trigger('fileInputUpdated');
      }
    });
  }
}


// From: https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/volume/js/soundmeter.js
// Demo: https://webrtc.github.io/samples/src/content/getusermedia/volume/

// Meter class that generates a number correlated to audio volume.
// The meter class itself displays nothing, but it makes the
// instantaneous and time-decaying volumes available for inspection.
// It also reports on the fraction of samples that were at or near
// the top of the measurement range.
function SoundMeter(context) {
  this.context = context;
  this.instant = 0.0;
  this.slow = 0.0;
  this.clip = 0.0;
  this.script = context.createScriptProcessor(2048, 1, 1);
  const that = this;
  this.script.onaudioprocess = function (event) {
    const input = event.inputBuffer.getChannelData(0);
    let i;
    let sum = 0.0;
    let clipcount = 0;
    for (i = 0; i < input.length; ++i) {
      sum += input[i] * input[i];
      if (Math.abs(input[i]) > 0.99) {
        clipcount += 1;
      }
    }
    that.instant = Math.sqrt(sum / input.length);
    that.slow = 0.95 * that.slow + 0.05 * that.instant;
    that.clip = clipcount / input.length;
  };
}

SoundMeter.prototype.connectToSource = function (stream, callback) {
  console.log('SoundMeter connecting');
  try {
    this.mic = this.context.createMediaStreamSource(stream);
    this.mic.connect(this.script);
    // necessary to make sample run, but should not be.
    this.script.connect(this.context.destination);
    if (typeof callback !== 'undefined') {
      callback(null);
    }
  } catch (e) {
    console.error(e);
    if (typeof callback !== 'undefined') {
      callback(e);
    }
  }
};

SoundMeter.prototype.stop = function () {
  console.log('SoundMeter stopping');
  this.mic.disconnect();
  this.script.disconnect();
};
