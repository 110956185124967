import registerView from 'lib/scope'
import AdvanceToNextLevelForm from 'views/course_progression/shared/advance_to_next_level_form'
import Tabs from 'components/tabs'
import Countdown from 'lib/countdown'

registerView('.js-course_progression-launches-_level8', ($view, $$) => {
    const initialTabname = $view.data("tab")
    new Tabs($$('.js-course_progression-launches-level8-tabs'), {
        initialTabname: initialTabname,
    })

    $$('.js-date-goal-countdown').each((i, el) => {
        new Countdown($(el))
    })
})
