import * as React from "react"
import { Winner, RouteTemplates } from "../Index"

interface WinnerCardProps {
  showOneLiner: boolean
  winner: Winner
  routeTemplates: RouteTemplates
  onMouseEnter?: (winner: Winner) => void
  onMouseLeave?: (winner: Winner) => void
}

export default class WinnerCard extends React.Component<WinnerCardProps, {}> {
  onMouseEnter(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(this.props.winner)
    }
  }

  onMouseLeave(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(this.props.winner)
    }
  }

  render() {
    let oneLiner = this.props.winner.one_liner
    let punctuatedOneLiner
    if (oneLiner.substr(oneLiner.length -1) == ".") {
      punctuatedOneLiner = oneLiner
    } else {
      punctuatedOneLiner = oneLiner + "."
    }

    let renderedOneLiner = null
    let flexAlignItemsClass = null;
    if (this.props.showOneLiner) {
      renderedOneLiner = (
        <div className="serif text-small">
          {punctuatedOneLiner}
        </div>
      )

      flexAlignItemsClass = "align-items-start"
    } else {
      flexAlignItemsClass = "align-items-center"
    }

    let showPath = this.props.routeTemplates.winner_team_member_path.replace(":slug:", this.props.winner.slug)

    return (
      <div
        className="winner_team_members-_card"
        onMouseEnter={this.onMouseEnter.bind(this)}
        onMouseLeave={this.onMouseLeave.bind(this)}
      >
        <a href={showPath} className={`link--inherit-color d-flex ${flexAlignItemsClass}`}>
          <img src={this.props.winner.photo_url} />
          <div className="ml-3">
            <span className="text-transform--uppercase font-weight-bold link--dotted">
              {this.props.winner.full_name}
            </span>
            <div className="text-small">
              {this.props.winner.age} &middot; {this.props.winner.location_description}
            </div>
            <div className="serif text-small">
              {renderedOneLiner}
            </div>
          </div>
        </a>
      </div>
    )
  }
}
