const NUMERIC_GOAL_INVALID_CHARACTER_REGEX = /[^\d€$₹£₿₦,%]/giu

export default class NumericGoalInput {
  constructor($input) {
    this.$input = $input
    this._setupEvents()
  }

  _setupEvents() {
    this.$input.on("keyup change", this._removeInvalidCharacters.bind(this))
  }

  _removeInvalidCharacters() {
    let oldValue = this.$input.val()
    let newValue = oldValue.replace(NUMERIC_GOAL_INVALID_CHARACTER_REGEX, "")
    this.$input.val(newValue)
  }
}

