import registerView from 'lib/scope'

export const COMPLETED_EVENT = "icsTableCompleted"

class EmailsSentView {
  constructor($view) {
    this.$view = $view
  }

  refreshTable(tableBodyHTML) {
    this.$view.find(".js-ics-table-body").html(tableBodyHTML)
  }
}

registerView('.js-ics-table', ($view, $$) => {
  const emailsSentView = new EmailsSentView($view)

  function refreshProgress() {
    const url = $view.data("url")

    $.ajax({ url: url }).done(((data, textStatus, jqXHR) => {
      emailsSentView.refreshTable(data.ics_table_body_html)

      const finished = data.finished
      if (finished) {
        $view.trigger(COMPLETED_EVENT)
      }
    }))
  }

  refreshProgress()
  setInterval(refreshProgress, 10000)
})
