import LineChart from "views/shared/ticker/line_chart"
import { getPositiveNegativeAssets } from "views/applicants/ticker/growth_text"

export default class SidebarGrowthText {
  constructor($view, lineChart) {
    this.$view = $view
    this.lineChart = lineChart
  }

  updateSidebarChartGrowthTexts() {
    const dataset = this.lineChart.getCurrentPrimaryDataset()
    const { quantity } = LineChart.getGrowthFromDataset(dataset)

    const $growthText = this.$view.find(".js-sidebar-growth-text")

    const isGrowthPositive= quantity >= 0
    const { textColor, textModifier } = getPositiveNegativeAssets(isGrowthPositive)

    const renderableQuantity = Math.round(Math.abs(quantity) * 100)/100

    $growthText.css("color", textColor)
    $growthText.html(`${textModifier}${renderableQuantity}`)
  }

  update() {
    this.updateSidebarChartGrowthTexts()
  }
}
