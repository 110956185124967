import registerView from 'lib/scope'

registerView('.js-shared-instructions-_rp1', ($view, $$) => {
  let $rp1Input = $$('.js-rp1-input')
  if ($rp1Input.length) {
    let onRp1InputChange = () => {
      let valid = ($.trim($rp1Input.val().toLowerCase()) == $rp1Input.data('pw'))
      $$('.js-submit').toggleClass('disabled', !valid).prop('disabled', !valid)
    }
    $rp1Input.on('keyup', onRp1InputChange)
    onRp1InputChange();
  }
})
