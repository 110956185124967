import registerView from 'lib/scope'
import ButtonGroup from 'components/button_group'
import Tabs from 'components/tabs'
import CharactersRemaining from 'lib/characters_remaining'

registerView('.js-peer_reviews-show', ($view, $$) => {
  new ButtonGroup($$('.js-rating-button-group'))

  let $ratingInput = $$('.js-rating-button-group .js-button-group-input')
  let onRatingChange = () => {
    let $feedbackLabel = $$('.js-feedback-label')
    let rating = $ratingInput.val().replace('_', '-')
    let newLabel = $feedbackLabel.data(rating ? rating : 'default')
    $feedbackLabel.text(newLabel)
  }
  onRatingChange()
  $ratingInput.on('change', () => onRatingChange())

  $$('.js-text-input-with-character-limit').each((i, el) => {
    new CharactersRemaining($(el))
  })

  new Tabs($view)

  $$('.js-tooltip').tooltip({ delay:  { 'show': 500, 'hide': 0 }, trigger: 'hover' })
})
