export function getPositiveNegativeAssets(isPositive) {
  const upArrow = "&#9650;"
  const downArrow = "&#9660;"

  const textColor = isPositive ? "var(--ticker-green)" : "var(--ticker-red)"
  const arrow = isPositive ? upArrow : downArrow
  const textModifier = isPositive ? "+" : "-"

  return { textColor, arrow, textModifier }
}
