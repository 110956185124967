import registerView from 'lib/scope'
import analytics from 'lib/analytics'

registerView('.js-home-_subscribe_form', ($view, $$) => {
  $$('form').on('ajax:success', (e) => {
    $$('.js-subscription-form').hide()
    $$('.js-subscription-confirmation').removeClass('d-none')

    analytics.track('Subscribed to mailing list', { email: e.detail[0].email })
  }).on('ajax:error', (e) => {
  })

  $$('.js-subscribe-cell').on('click', (e) => {
    $$('.js-submit').click()
  })
})
