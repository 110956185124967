import OtherInput from 'components/other_input'

export default class Select {
  constructor($view) {
    this.$view = $view
    this.$select = this.$view.find('select')
    this.$select.on('change', () => this._refresh())

    let $otherInputContainer = this.$view.find('.js-other-input-container')
    if ($otherInputContainer.length) {
      this.otherInput = new OtherInput($otherInputContainer)
    }

    this._refresh()
  }

  _refresh() {
    if (!this.otherInput) {
      return
    }

    this.otherInput.toggle(this.$view.find('.js-other-option:selected').length)
  }
}
