import 'bootstrap'
import 'lib/scope'
import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import LocalTime from 'local-time'
import jstz from 'jstimezonedetect'
import Cookies from 'js-cookie'
import ReactRailsUJS from 'react_ujs'

require("chart.js")

Rails.start()

Turbolinks.start()
// https://github.com/reactjs/react-rails/pull/900/files
window.Turbolinks = Turbolinks;
// Remove previous event handlers and add new ones:
ReactRailsUJS.detectEvents();

// Import after Turbolinks has started
// https://github.com/DavyJonesLocker/client_side_validations#when-using-webpacker
import '@client-side-validations/client-side-validations'
import '@client-side-validations/simple-form'
import 'lib/rails.validations.custom'

// Manually setup Turbolinks event handling for ClientSideValidations until this is fixed:
// https://github.com/DavyJonesLocker/client_side_validations/issues/767
// (RN is monitoring the GH issue)
$(document).on("turbolinks:load", function () {
  return $(ClientSideValidations.selectors.forms).validate();
});

LocalTime.start()
Cookies.set('time_zone', jstz.determine().name(), { expires: 1 })

// Require all views
let requireAll = (r) => { r.keys().forEach(r) }
requireAll(require.context('./views/', true, /\.js$/))

// Support component names relative to this directory
let componentRequireContext = require.context('./', true)
ReactRailsUJS.useContext(componentRequireContext)
