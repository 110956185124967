import registerView from 'lib/scope'
import ButtonGroup from 'components/button_group'
import AdvanceToNextLevelForm from 'views/course_progression/shared/advance_to_next_level_form'

registerView('.js-course_progression-launches-_level9', ($view, $$) => {
  function syncCheckboxAndShortAnswer() {
    const checked = $$(".js-talked-to-users").is(":checked")
    $$('.js-l9-short-answer-container').toggleClass("l9-standby", !checked)

  }
  $$(".js-talked-to-users").on('click', syncCheckboxAndShortAnswer)
  syncCheckboxAndShortAnswer()
  // Default behavior does not work since CSV doesn't try to validate hidden fields
  const shouldEnableAdvanceToLevel10 = ($form) => {
    return !!$form.find(".js-user-likes").val() && !!$form.find(".js-upcoming-work").val()
  }
  new AdvanceToNextLevelForm($$('.js-level-form'), { shouldEnableSubmit: shouldEnableAdvanceToLevel10 })
})
