import registerView from "lib/scope"
import Clipboard from 'lib/clipboard'
import AdvanceToNextLevelForm from "views/course_progression/shared/advance_to_next_level_form"

registerView(".js-course_progression-pmf-_level2", ($view, $$) => {
  new AdvanceToNextLevelForm($$(".js-level-form"))

  $$(".js-copy-list-item").hover((event) => {
    if (!$$(event.currentTarget).attr("copied")) {
      $$(event.currentTarget).find(".js-copy-icon").toggleClass("d-none", false)
    }
  }, (event) => {
    $$(event.currentTarget).find(".js-copy-icon").toggleClass("d-none", true)
  })

  $$(".js-copy-list-item").click((event) => {
    $$(event.currentTarget).find(".js-copy-icon").toggleClass("d-none", true)

    // Show "COPIED"
    $$(event.currentTarget).find(".js-copied-text").toggleClass("visible", true)

    // Cancel previous timeout
    if ($(event.currentTarget).attr("copied")) {
      const processId = $(event.currentTarget).attr("copied")
      clearTimeout(processId)
    }

    // Hide "COPIED"
    const timeoutId = setTimeout(() => {
      $$(event.currentTarget).removeAttr("copied")
      $$(event.currentTarget).find(".js-copied-text").toggleClass("visible", false)
    }, 1000)

    $(event.currentTarget).attr("copied", timeoutId)
  })

  $view.find(".js-copy-list-item").each((idx, elem) => {
    new Clipboard(elem, { silent: true })
    const clipboardText = $$(elem).find(".js-question-text").text()
    $$(elem).attr("data-clipboard-text", clipboardText)
  })
})
