export default class CountryStateSelect {
  constructor($view) {
    this.$countryInput = $view.find('.js-country-field')
    this.$stateInput = $view.find('.js-state-field')
    this.$stateView = $view.find('.js-state')

    this.$countryInput.on('change', (e) => this.toggleStateInput())
    this.toggleStateInput()
  }

  toggleStateInput() {
    if (this.$countryInput.val() == 'US') {
      this.$stateView.removeClass('d-none')
    } else {
      this.$stateView.addClass('d-none')
      this.$stateInput.val(null)
    }
  }
}
