// Use this class to toggle whether "Advance to Level N+1" is disabled.
// Default behavior will use ClientSideValidations. Note that CSV's major flaw is that it doesn't validate
// hidden fields, so you may have to override the default behavior via the shouldEnableSubmit option.
// You may still find it helpful to call defaultShouldEnableSubmit() even if you do pass that option.
export default class AdvanceToNextLevelForm {
  constructor($form, options = {}) {
    this.$form = $form

    // CSV should work without this, but this.$form[0].ClientSideValidations (used below) doesn"t seem to work
    // unless we explicitly call this
    this.$form.enableClientSideValidations()

    const defaults = {
      shouldEnableSubmit: AdvanceToNextLevelForm.defaultShouldEnableSubmit,
    }
    const computedOptions = Object.assign({}, defaults, options);
    this.shouldEnableSubmit = computedOptions.shouldEnableSubmit

    this.refreshSubmitEnabled()
    this._setupEvents()
  }

  refreshSubmitEnabled() {
    let $submit = this._$submit()
    this.shouldEnableSubmit(this.$form) ? $submit.removeAttr("disabled") : $submit.attr("disabled","disabled")
  }

  static defaultShouldEnableSubmit($form) {
    const validators = $form[0].ClientSideValidations.settings.validators;

    // isValid's default behavior is to add error messages in the UI when all we really want is to get a boolean result.
    // Temporarily disable that default behavior.
    const oldCallback = window.ClientSideValidations.callbacks.element.fail
    window.ClientSideValidations.callbacks.element.fail = function(element, message, callback) { /* Do nothing */ }
    const formIsValid = $form.isValid(validators)
    window.ClientSideValidations.callbacks.element.fail = oldCallback

    return formIsValid
  }

  _setupEvents() {
    this.$form.find("input,textarea").on("keyup", (e) => {
      // Manually mark the value as changed on keypress so that ClientSideValidations doesn't wait until
      // the change event is triggered. Without this, refreshSubmitEnabled() below won't notice a text field's new
      // value before blur, meaning a user would have to click out for the submit button to be enabled.
      const $el = $(e.currentTarget)
      $el.trigger("change.ClientSideValidations")
    })

    this.$form.find("input,select,textarea").on("change keyup", this.refreshSubmitEnabled.bind(this))
  }

  _$submit() {
    return this.$form.find(".js-next-level-button")
  }
}
