import registerView from "lib/scope"
import NumericGoalInput from "views/shared/project_priorities/numeric_goal_input"
import Select from "components/select"

registerView(".js-shared-project_priorities-_edit", ($view, $$) => {
  $$(".js-goal-input-wrapper[data-goal-type=numeric] input").each((i, el) => {
    new NumericGoalInput($(el))
  })

  $$(".js-goal-input-wrapper[data-goal-type=date]").each((i, el) => {
    let $el = $(el)
    if ($el.find(".js-native-datepicker input").prop("type") != "date") {
      $el.find(".js-native-datepicker").remove()
    } else {
      $el.find(".js-date-dropdowns").remove()
    }
  })

  const parsedTitleDetails = $view.data("title-details")

  $$(".js-project-priority").each((i, el) => {
    const $el = $(el)
    new Select($el.find(".js-title-container"))

    const $select = $el.find(".js-title-select")

    const refreshTitleDetails = () => {
      const $selectedOption = $select.find(":selected")
      const value = $selectedOption.val()

      let detailsToRender;
      if (parsedTitleDetails.hasOwnProperty(value)) {
        detailsToRender = parsedTitleDetails[value]
      } else {
        detailsToRender = ""
      }

      $el.find(".js-selected-title-details").html(detailsToRender)
    }
    refreshTitleDetails()
    $select.on("change", refreshTitleDetails)
  })
})
