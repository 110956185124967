import 'is-in-viewport'
import 'components/tabs'

/*
Expects:
  .js-tabs-nav
    >> .js-tab-nav-item[data-tab=${i}][data-tabname=${tabname}]; optional: data-path, data-preload
  >> .js-tab-content[data-tab=${i}]
 */
export default class Tabs {
  constructor($view, options = {}) {
    this.$view = $view
    this.$nav = $view.find('.js-tabs-nav')

    let defaults = {
      disableUrlHash: false,
    }
    this.options = Object.assign({}, defaults, options);

    this._setupNavigation()
    this._setupDropdownFallback()

    this.setInitialActiveTab()
  }

  setActiveTabByIndex(i) {
    let $navItem = this.$nav.find(`.js-tab-nav-item[data-tab=${i}]`)
    this._setActiveTab($navItem)
  }

  setActiveTabByTabname(tabname) {
    let navItem = $(this._navItemWithTabname(tabname))
    if (navItem) {
      this._setActiveTab($(navItem))
    }
  }

  refreshControls() {
    let $activeNavItem = this._activeNavItem()

    let showNextTab = !$activeNavItem.data('hide-next') && this.canGoToRelativeTab(1)
    this.$view.find('.js-next-tab').toggle(!!showNextTab)

    let showPreviousTab = !$activeNavItem.data('hide-previous') && this.canGoToRelativeTab(-1)
    this.$view.find('.js-previous-tab').toggle(!!showPreviousTab)
  }

  goToRelativeTab(offset, options = {}) {
    if (!this.canGoToRelativeTab((offset))) {
      return null
    }

    this._setActiveTab(this._$navItemForRelativeTab(offset), options)

    let $scrollTarget = $('.js-tab-change-scroll-target:not(:in-viewport)')
    if ($scrollTarget.length) {
      $scrollTarget[0].scrollIntoView()
    }
  }

  canGoToRelativeTab(offset) {
    let $relativeNavItem = this._$navItemForRelativeTab(offset)
    return $relativeNavItem.length && !$relativeNavItem.hasClass('disabled')
  }

  setInitialActiveTab() {
    // To set the first active nav item, first try to use the url anchor
    let $activeNavItem;
    if (window.location.hash.length > 1) {
      let activeNavItem = this._navItemWithTabname(window.location.hash.substring(1))
      if (activeNavItem) {
        $activeNavItem = $(activeNavItem)
      }
    }

    // Then, use any fallback provided as an option
    if (!$activeNavItem) {
      if (this.options.initialTabname) {
        let activeNavItem = this._navItemWithTabname(this.options.initialTabname)
        if (activeNavItem) {
          $activeNavItem = $(activeNavItem)
        }
      }
    }

    // Next, see if any of the tabs have the data-initial flag set
    if (!$activeNavItem) {
      let activeNavItem = this.$nav.find('.js-tab-nav-item[data-initial=1]:not(.disabled)')[0]
      if (activeNavItem) {
        $activeNavItem = $(activeNavItem)
      }
    }

    // Fallback to the first tab
    if (!$activeNavItem) {
      $activeNavItem = $(this.$nav.find('.js-tab-nav-item:not(.disabled)')[0])
    }
    this._setActiveTab($activeNavItem)

    // Preload the rest of the tabs
    this.$view.find('.js-tab-content.d-none').filter('[data-path]:empty:not([data-preload=0])').each((i, el) => {
      let $el = $(el)
      $el.load($el.data('path'), () => {
        this.$view.trigger('tabLoaded', [$el.data('tab')]);
      })
    })
  }

  _setupNavigation() {
    this.$nav.find('.js-tab-nav-item').on('click', (e) => this._onClick(e))
    this.$view.find('.js-previous-tab').on('click', () => this.goToRelativeTab(-1))
    this.$view.find('.js-next-tab').on('click', () => this.goToRelativeTab(1))
  }

  _setupDropdownFallback() {
    let $dropdownFallback = this.$view.find('.js-tabs-nav-dropdown-fallback')
    if (!$dropdownFallback.length) {
      return
    }
    this.$dropdownFallback = $dropdownFallback
    this.$dropdownFallback.on('change', () => {
      let val = $dropdownFallback.val()
      this.setActiveTabByIndex(val)
    })
  }

  _onClick(e) {
    e.stopPropagation()
    this._setActiveTab($(e.currentTarget))
  }

  _setActiveTab($selectedNavItem, options = {}) {
    let $oldTab = this.$view.find('.js-tab-content:not(.d-none)')
    let tabScroll;
    if (this.options.tabScrollSyncing && $oldTab.length) {
      let elementToMaintain = $oldTab.find('[data-tab-scroll]:in-viewport').first()
      tabScroll = $(elementToMaintain).data('tab-scroll')
    }

    this.$nav.find('.js-tab-nav-item').removeClass('active')
    $selectedNavItem.addClass('active')
    if (this.$dropdownFallback) {
      this.$dropdownFallback.val(this._currentTabId())
    }

    // Transition to the new tab via carousel if provided, else simply toggle display classes
    let $newContent = this.$view.find(`.js-tab-content[data-tab=${this._currentTabId()}]`)
    if (this.options.$carousel) {
      let slickIndex = $newContent.closest('.slick-slide').data('slick-index')

      // Disable animations by default for now, since slickGoTo doesn't work if there's an ongoing animation
      let disableAnimations = !options.useCarouselAnimation;
      this.options.$carousel.slick('slickGoTo', slickIndex, disableAnimations)
    } else {
      this.$view.find('.js-tab-content').addClass('d-none')
      $newContent.removeClass('d-none')
    }

    let tabname = $selectedNavItem.data('tabname')
    if (tabname && !this.options.disableUrlHash) {
      // Use replaceState here so that navigating back via the browser goes to the previous page, not hash.
      // Without the first argument, Turbolinks won't forward correctly when navigating back then forward:
      // https://github.com/turbolinks/turbolinks/issues/219
      history.replaceState({ turbolinks: {} }, undefined, `#${tabname}`)
    }

    // TODO (rishi) weird .js-mobile:visible hack
    if (this.options.tabScrollSyncing && this.$view.find('.js-tabs-nav.js-mobile:visible').length) {
      let $scrollToElement;
      // If a target was provided, scroll to it
      if (tabScroll) {
        $scrollToElement = $newContent.find(`[data-tab-scroll=${tabScroll}]`)
      }
      // If not, and a data-tab-scroll attribute exists, scroll to it
      if (!$scrollToElement || !$scrollToElement.length) {
        $scrollToElement = $newContent.find(`[data-tab-scroll]`).first()
      }

      if ($scrollToElement.length) {
        let elementPosition = $scrollToElement.offset().top;
        if (this.options.tabScrollTopOffset) {
          elementPosition -= this.options.tabScrollTopOffset;
        }
        window.scrollTo(0, elementPosition)
      }
    }

    this.refreshControls()

    if ($newContent.is(':empty') && $newContent.data('path')) {
      $newContent.load($newContent.data('path'), () => {
        this.$view.trigger('tabLoaded', [$newContent.data('tab')]);
      })
    }

    if (this.options.afterSetActiveTab) {
      this.options.afterSetActiveTab({
        $tabContent: $newContent,
        tabTitle: $selectedNavItem.text(),
        tabname: tabname
      })
    }
  }

  _activeNavItem() {
    return this.$nav.find('.js-tab-nav-item.active')
  }

  _currentTabId() {
    return parseInt(this._activeNavItem().data('tab'))
  }

  _$navItemForRelativeTab(offset) {
    return $(this.$nav.find(`.js-tab-nav-item[data-tab=${this._currentTabId() + offset}]`))
  }

  _navItemWithTabname(tabname) {
    if (this._validTabnames().includes(tabname)) {
      return this.$nav.find(`.js-tab-nav-item[data-tabname=${tabname}]:not(.disabled)`)[0]
    } else {
      return null
    }
  }

  _validTabnames() {
    return $.makeArray(this.$nav.find('.js-tab-nav-item').map((i, el) => {
      let $el = $(el)
      return $el.data('tabname')
    }))
  }
}
