import registerView from 'lib/scope'
import ButtonGroup from 'components/button_group'
import AdvanceToNextLevelForm from 'views/course_progression/shared/advance_to_next_level_form'

registerView('.js-course_progression-launches-_level2', ($view, $$) => {
  new ButtonGroup($$('.js-launch-status-button-group'))

  const isPostLaunch = function() {
    return $$(".js-button-group-input").val() == "launched"
  }

  const refreshUpcomingFeatureContainerVisibility = function() {
    const postLaunch = isPostLaunch()
    $$(".js-upcoming-feature-container").toggleClass("d-none", !postLaunch)
    if (!postLaunch) {
      $$(".js-upcoming-feature-container").val("")
    }
  }
  refreshUpcomingFeatureContainerVisibility()

  $$(".js-button-group-option").on("click", (e) => {
    const $button = $(e.currentTarget)
    const postLaunch = isPostLaunch()

    if ($button.hasClass("selected") && postLaunch) {
      const $modal = $$(".js-post-launch-modal")
      $modal.modal()
      $modal.on('hidden.bs.modal', () => {
        $modal.remove()
      })
    }

    refreshUpcomingFeatureContainerVisibility()
  })

  const shouldEnableAdvanceToLevel3 = ($form) => {
    return $form.find(".js-button-group-input").val() && $form.find(".js-short-project-summary").val() &&
        (!isPostLaunch() || $form.find(".js-upcoming-feature").val())
  }
  new AdvanceToNextLevelForm($$('.js-level-form'), { shouldEnableSubmit: shouldEnableAdvanceToLevel3 })
})
