import analytics from "lib/analytics"
import { MS_IN_WEEK } from "lib/time_helper";

// Draws Simulated Growth line on mainChart
export default class SimulatedGrowth {
  constructor($view, mainChart) {
    this.$view = $view
    this.mainChart = mainChart

    this.mainChartIds = null

    this.color = "#9549E1" // #ticker_purple

    this.initializeEventListeners()
    this.update()
  }

  initializeEventListeners() {
    this.$view.find(".js-simulated-growth-button-group-input").on("change", () => {
      analytics.track("[Ticker] Used simulated growth", {})
      this.update()
    })
  }

  getGrowthRate() {
    const $simulatedCompanyInput = this.$view.find(".js-simulated-growth-button-group-input")
    const growthRate = parseFloat($simulatedCompanyInput.val())

    return growthRate
  }

  update() {
    this.removeSimulatedGrowth()

    this.addSimulatedGrowth({ predictive: true, predictiveLength: MS_IN_WEEK * 4})
  }

  removeSimulatedGrowth() {
    const oldMainChartIds = this.mainChartIds || []
    for (const mainChartId of oldMainChartIds) {
      this.mainChart.removeDatasetFromChartData(mainChartId)
    }

    this.mainChartIds = []
  }

  // predictiveLength in ms
  addSimulatedGrowth({ predictive, predictiveLength } = {}) {
    const growthRate = this.getGrowthRate()
    if (isNaN(growthRate)) return

    const primaryDataset = this.mainChart.getPrimaryDataset()

    const startValue = primaryDataset.data[0].y || 1
    const startEpoch = primaryDataset.data[0].x

    const displayGrowthRate = Math.round(growthRate * 100) || ""
    const name = `${displayGrowthRate}% Growth`

    const calculateSimulatedGrowthChartDataParams = { name, startValue, startEpoch, growthRate, dataWiggle: 0.0 }

    if (predictive) {
      const today = new Date().getTime()
      const endEpoch = new Date(today + predictiveLength).getTime()

      calculateSimulatedGrowthChartDataParams["endEpoch"] = endEpoch
    }

    const simulatedChartData = SimulatedGrowth.calculateSimulatedGrowthChartData(calculateSimulatedGrowthChartDataParams)

    const newMainChartIds = this.mainChart.addDatasetFromChartData(simulatedChartData, this.color)

    this.mainChartIds = newMainChartIds
  }

	static calculateSimulatedGrowthChartData({ startValue, startEpoch, endEpoch, growthRate, dataWiggle = 0.0, name }) {
		const compoundData = {}
		let runningValue = startValue

		let date = new Date(startEpoch)
    let endDate = new Date(endEpoch)

		while (date <= endDate) {
			const dateOnly = `${date.getFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`

			const wiggle = Math.random() * (runningValue * dataWiggle)

			compoundData[dateOnly] = Math.ceil(runningValue + wiggle)

			date = new Date(date.getTime() + MS_IN_WEEK)
			runningValue *= (1 + growthRate)
		}

		return [{ name: name, data: compoundData, dataset: { pointRadius: 0 }}]
	}
}

