import TickerEventNode from "views/applicants/ticker/ticker_event_node"

export default class SelectedCompanyToolbar extends TickerEventNode {
  constructor($view) {
    super($view)

    this.$view = $view
    this.openSlots = []

    this.initializeOpenSlots()
    this.initializeEventEmitters()
  }

  initializeOpenSlots() {
    this.$view.find(".js-selected-company-toolbar-slot").each((idx, selector) => {
      const slotNumber = this.$view.find(selector).data("slot")

      this.openSlots.push(slotNumber)
    })
  }

  getAssignedSlot() {
    if (this.openSlots.length > 0) {
      const slotNumber = this.openSlots.pop()

      return slotNumber
    } else {
      return null
    }
  }

  initializeEventEmitters() {
    this.$view.find(".js-selected-company-toolbar-slot").each((idx, selector) => {
      const $slot = this.$view.find(selector)

      $slot.find(".js-selected-company-toolbar-slot-x").on("click", (e) => {
        const symbol = $slot.find(".js-selected-company-toolbar-slot-text").html()

        const eventData = { symbol }
        this.triggerDeselect(eventData)
      })
    })
  }

  onSelect(event, eventData) {
    this.pushData(eventData.symbol, eventData.color)
  }

  onDeselect(event, eventData) {
    this.removeDataBySymbol(eventData.symbol)
  }

  pushData(symbol, backgroundColor) {
    const assignedSlot = this.getAssignedSlot()

    if (assignedSlot === null) return

    const $slot = this.$view.find(`.js-selected-company-toolbar-slot[data-slot=${assignedSlot}]`)
    $slot.find(".js-selected-company-toolbar-slot-text").html(symbol)
    $slot.toggleClass("d-none", false)
    $slot.css("background-color", backgroundColor)

    return assignedSlot
  }

  removeDataBySymbol(symbol) {
    let slotNumber = null
    this.$view.find(".js-selected-company-toolbar-slot").each((idx, selector) => {
      const $slot = this.$view.find(selector)
      const $slotText = $slot.find(".js-selected-company-toolbar-slot-text")

      if ($slotText.html() === symbol) {
        slotNumber = $slot.data("slot")
      }
    })

    this.removeData(slotNumber)
  }

  removeData(slot) {
    const $slot = this.$view.find(`.js-selected-company-toolbar-slot[data-slot=${slot}]`)
    $slot.find(".js-selected-company-toolbar-slot-text").html("")
    $slot.toggleClass("d-none", true)

    this.openSlots.push(slot)
  }
}
