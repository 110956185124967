import registerView from 'lib/scope'

registerView('.js-forum-posts-_upvote', ($view, $$) => {
  let $form = $view.find(".js-upvote-form")

  $form.on('ajax:success', (e) => {
    const response = e.detail[2].response
    const parsedResponse = JSON.parse(response)

    $view.toggleClass("upvoted", parsedResponse.score == 1)
    $$(".js-hidden-score-field").val(parsedResponse.score == 1 ? 0 : 1)
    const scoreWithoutUser = parseInt($view.find(".js-score-value").data("initial-score-without-user"))
    $$(".js-score-value").text(scoreWithoutUser + parsedResponse.score)
  })
})
