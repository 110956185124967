import { ALL_EVENTS, SELECT, DESELECT } from "./events"

// Listens for events from TickerEventNodes.
// Modifies event and handles event side effects
// Reverse proxies modified event to TickerEventNodes
//
// To add a new EventNode, add it to getEventNodes()
export default class TickerEventRouter {
  constructor({$view, maxSelectableCompanies, tickerTable }) {
    this.$view = $view

    this.maxSelectableCompanies = maxSelectableCompanies

    this.tickerTable = tickerTable
    this.eventNodes = []

    this.initializeEventListeners()

    this.debounceTracker = {}
    this.debounceDuration = 200 //in ms
  }

  addEventNode(node) {
    this.initializeEventListenersForEventNode(node)
    this.eventNodes.push(node)
  }

  getEventNodes() {
    return this.eventNodes
  }

  // Hears child event from child and routes an adult command event to all children
  initializeEventListenersForEventNode(eventNode) {
    ALL_EVENTS.forEach(({ childEvent, adultEvent }) => {
      this.initializeEventListener(
          eventNode,
          childEvent,
          (event, eventData) => this.triggerEvent(adultEvent, event, eventData))
    })
  }

  initializeEventListeners() {
    const eventNodes = this.getEventNodes()

    eventNodes.forEach((eventNode) => {
      this.initializeEventListenersForEventNode(eventNode)
    })
  }

  initializeEventListener(eventNode, eventType, lambda) {
    eventNode.$view.on(eventType, lambda)
  }

  triggerEvent(eventType, event, eventData) {
    if (!this.canEmitEvent(eventType)) return
    if (this.debounceTracker[eventType]) {
      const timeSinceLastEvent = Date.now() - this.debounceTracker[eventType]

      if (timeSinceLastEvent < this.debounceDuration) return
    }

    this.debounceTracker[eventType] = Date.now()

    const fullEventData = this.createFullEventData(eventType, eventData)

    this.triggerEventForSelectables(eventType, fullEventData)

    this.afterEvent(eventType)
  }

  canEmitEvent(eventType) {
    switch(eventType) {
      case SELECT.adultEvent:
         return this.getNumberSelectedCompanies() < this.maxSelectableCompanies
      default:
        return true
    }
  }

  createFullEventData(eventType, eventData) {
    switch (eventType) {
      case SELECT.adultEvent:
        return this.createSelectEventData(eventData)

      default:
        return eventData
    }
  }


  afterEvent(eventType) {
    switch (eventType) {
      case SELECT.adultEvent:
        this.afterSelect()
        break
      case DESELECT.adultEvent:
        this.afterDeselect()
        break
      default:
        break
    }
  }

  triggerEventForSelectables(eventType, eventData) {
    const eventNodes = this.getEventNodes()

    eventNodes.forEach((eventNode) => {
      eventNode.$view.trigger(eventType, [eventData])
    })
  }

  // return type:
  //  eventData: {
  //    symbol: string,
  //    color: string,
  //    tableData: TableData /* see general_ticker.rb */
  //  }
  createSelectEventData(eventData) {
    const color = this.tickerTable.getNextColor()

    return { ...eventData, color }
  }

  afterSelect() {
    this.toggleLineChartDisabled()
  }

  afterDeselect() {
    this.toggleLineChartDisabled()
  }

  toggleLineChartDisabled() {
    // If reached maxSelectabledCompanies, disable sidebar companies
    if (this.getNumberSelectedCompanies() >= this.maxSelectableCompanies) {
      $(".js-line-chart-wrapper").each((idx, selector) => {
        const $lineChartWrapper = $(selector)
        if (!$lineChartWrapper.hasClass("selected")) {
          $(selector).addClass("disabled")
        }
      })
    } else {
      $(".js-line-chart-wrapper").removeClass("disabled")
    }
  }

  getNumberSelectedCompanies() {
    // TODO is there a better source of truth?
    const numberSelectedCompanies = $(".js-line-chart-wrapper.selected").length

    return numberSelectedCompanies
  }
}
