import registerView from 'lib/scope'

class SharedCommentReplyContainer {
  constructor($view) {
    this.$view = $view
  }

  appendToCommentView(commentView) {
    let $replyContainer = this.$view.remove()
    $replyContainer.find(".js-reply-to-comment-form").attr("action", commentView.$view.data("reply-path"))

    $(commentView.$view.find(".js-reply-shared-input-placeholder")[0]).append($replyContainer)
    this.$view.removeClass("d-none")
  }
}

class CommentView {
  constructor($view, sharedCommentReplyContainer) {
    this.$view = $view
    this.sharedCommentReplyContainer = sharedCommentReplyContainer

    $(this.$view.find(".js-reply-to-comment")[0]).on("click", (e) => {
      if (this.$view.find(".js-forum-posts-_shared_reply_to_comment").length == 0) {
        this.sharedCommentReplyContainer.appendToCommentView(this)
      } else {
        this.sharedCommentReplyContainer.$view.detach()
      }
    })
  }
}

registerView('.js-forum-posts-_comment', ($view, $$) => {
  // Search outside $view for the shared reply container
  const $sharedCommentReplyContainer = $(".js-forum-posts-_shared_reply_to_comment")
  if (!$sharedCommentReplyContainer) {
    throw 'Missing shared reply container';
  }
  const sharedCommentReplyContainer = new SharedCommentReplyContainer($sharedCommentReplyContainer)

  new CommentView($($view), sharedCommentReplyContainer)

  let $tooltip =  $$(".js-reply-to-comment")
  $tooltip.tooltip({ delay: { "show": 200, "hide": 0 }, trigger: "hover" })
})
