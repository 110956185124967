import registerView from 'lib/scope'
import Tabs from 'components/tabs'

registerView('.js-reviewers-home-guidelines', ($view, $$) => {
  let $tabsContainer = $$('.js-tabs-container')
  let tabs = new Tabs($tabsContainer)

  // NDA
  let onNDAStateChange = () => {
    let signed = $$('.js-nda-signature').length
    $tabsContainer.find('.js-tab-nav-item').last().toggleClass('disabled', !signed)
    tabs.refreshControls()
  }
  onNDAStateChange()

  $view.find('.js-nda-form').on('ajax:success', (e) => {
    let html = e.detail[2].response
    $$('.js-nda-action-container').empty().html(html)
    onNDAStateChange()
  })
})
