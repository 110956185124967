import registerView from "lib/scope"
import AreYouSure from 'lib/are_you_sure'

registerView(".js-course_progression-monitoring-email", ($view, $$) => {
  const $monitoringEmailModal = $$(".js-edit-monitoring-email-modal")
  if ($monitoringEmailModal.data("render")) {
    // Disable fade in rendering on load
    $monitoringEmailModal.toggleClass("fade", false)
    $monitoringEmailModal.modal()
    $monitoringEmailModal.toggleClass("fade", true)
  }

  // Don't allow unsaved changes to alternative email trigger AreYouSure
  AreYouSure.disable($view)
})
