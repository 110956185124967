import React from "react"
import PropTypes from "prop-types"
import Rails from "rails-ujs"
import autosize from "autosize"

class ChatMessageForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitRequestActive: false,
      bodyValue: '',
    }

    this.handleBodyInputChange = this.handleBodyInputChange.bind(this)
    this.onInputKeyPress = this.onInputKeyPress.bind(this)
  }

  componentDidMount() {
    let form = document.getElementById('new_chat_message')
    form.addEventListener('ajax:beforeSend', this.beforeFormSubmit.bind(this))
    form.addEventListener('ajax:success', this.handleSubmitSuccess.bind(this))
    form.addEventListener('ajax:error', this.handleSubmitFailure.bind(this))
    autosize(form)
  }

  componentDidUpdate() {
    let form = document.getElementById('new_chat_message')
    autosize.update(form)
  }

  beforeFormSubmit() {
    this.setState({ submitRequestActive: true })
  }

  handleSubmitSuccess() {
    this.setState({ submitRequestActive: false })
    this.setState({ bodyValue: '' })
    this.messageInput.focus()
  }

  handleSubmitFailure() {
    this.setState({ submitRequestActive: false })
  }

  handleBodyInputChange(e) {
    this.setState({ bodyValue: e.target.value })
  }

  onInputKeyPress(e) { 
    if (e.key === 'Enter' && /\S/.test(e.currentTarget.value) && !e.shiftKey) {
      let form = document.getElementById('new_chat_message')
      Rails.fire(form, 'submit')
    }
  }

  render() {
    return (
        <div className="chat-composer">
          <form className="simple_form"
                id="new_chat_message"
                action={`/chat/${this.props.chatRoomId}/messages?livestream_viewer_hex_uid=${this.props.livestreamViewerHexUid}`}
                data-remote="true"
                method="post">
            <textarea
                ref={(input) => { this.messageInput = input; }}
                className="chat-composer-input form-control border-top border-bottom-0 border-left-0 border-right-0 py-2"
                autoComplete="off"
                placeholder="Write a message..."
                id="chat_message_body"
                value={this.state.bodyValue}
                disabled={this.state.submitRequestActive}
                onChange={this.handleBodyInputChange}
                onKeyPress={this.onInputKeyPress}
                type="text"
                name="chat_message[body]"/>
          </form>
        </div>
    );
  }
}

ChatMessageForm.propTypes = {
  postMessageUrl: PropTypes.string,
  sender: PropTypes.string,
  body: PropTypes.string
};

export default ChatMessageForm
