import registerView from "lib/scope"

registerView(".js-admins-course-progressions-show", ($view, $$) => {
  $$(".js-level-toggle").on("click", (e) => {
    const level = $$(e.currentTarget).data("level")

    const $levelAttributesList = $$(`.js-level-attributes[data-level=${level}]`)
    $levelAttributesList.toggleClass("d-none")

    if ($levelAttributesList.hasClass("d-none")) {
      $$(e.currentTarget).find(".js-toggle-icon").html("&#8681;")
    } else {
      $$(e.currentTarget).find(".js-toggle-icon").html("&#8679;")
    }
  })
})
