import registerView from 'lib/scope'

registerView(".js-forum-posts-schedule_later", ($view, $$) => {
   const refreshPublishingLaterFields = () => {
     const publishLaterChecked = $$(".js-publish-later-input").prop("checked")

     $$(".js-if-publishing-later").toggleClass("d-none", !publishLaterChecked)
     $$(".js-publish-at-input").prop("disabled", !publishLaterChecked)
   }

  refreshPublishingLaterFields()

  $$(".js-publish-later-input").on("change", refreshPublishingLaterFields)
})
