import registerView from "lib/scope"
import analytics from "lib/analytics"
import ButtonGroup from "components/button_group"
import TickerChartWrapper from "views/applicants/ticker/ticker_chart"
import TickerTable from "views/applicants/ticker/ticker_table"
import SimulatedGrowth from "views/applicants/ticker/simulated_growth"
import SidebarCompany from "views/applicants/ticker/sidebar_company"
import TickerEventRouter from "views/applicants/ticker/ticker_event_router"
import MainGrowthText from "views/applicants/ticker/main_growth_text"
import TimeFilter from "views/applicants/ticker/time_filter"

registerView(".js-ticker-index", ($view, $$) => {
  analytics.track("[Ticker] Viewed ticker", {})

  // Config
  const showMainChartGrowthText = $view.data("show-main-chart-growth-text")
  const showSimulatedGrowth = $view.data("show-simulated-growth")
  const showCurrentUserDataOnTable = $view.data("show-current-user-data-on-table")

  // Initialize table
  const tickerTable = new TickerTable($$(".js-ticker-table"))

  // Initialize mainChart
  const tickerChartWrapper = new TickerChartWrapper($view, $view.find(".js-main-chart"))
  const mainChart = tickerChartWrapper.tickerChart

  if (showCurrentUserDataOnTable) {
    // Initialize current user data permanently on table
    const permanentTableData = $$(".js-main-chart").data("table-data")
    const fullPermanentTableData = TickerTable.populateGrowthsInTableData(permanentTableData, mainChart.getPrimaryDataset())
    tickerTable.pushData(fullPermanentTableData)
  }

  const maxSelectableCompanies = tickerTable.getOpenSlots().length

  const eventRouter = new TickerEventRouter({$view, maxSelectableCompanies, tickerTable })
  eventRouter.addEventNode(tickerChartWrapper)
  eventRouter.addEventNode(tickerTable)


  // Initialize simulated growth line on main chart
  if (showSimulatedGrowth) new SimulatedGrowth($view, mainChart)


  // Initialize sidebar companies
  $$(".js-line-chart-wrapper").each((idx, wrapperSelector) => {
    const $wrapper = $$(wrapperSelector)

    const sidebarCompanyParams =  { $view: $wrapper, maxSelectableCompanies, mainChart }
    const sidebarCompany = new SidebarCompany(sidebarCompanyParams)

    eventRouter.addEventNode(sidebarCompany)
  })

  const timeFilter = new TimeFilter($$(".js-time-filter-input"))
  eventRouter.addEventNode(timeFilter)

  // Initialize main chart growth text
  if (showMainChartGrowthText) {
    const mainChartGrowthText = new MainGrowthText($$(".js-main-growth-text"), mainChart)
    eventRouter.addEventNode(mainChartGrowthText)
  }

  // Filter all sidebarCompany data after event listeners are hooked up
  timeFilter.filter()

  $$(".js-metric-selection-input").on("change", () => {
    const url = $$(".js-metric-selection-input").val()

    $$(".js-metric-selection-input").attr("disabled", true)

    window.location.href = url
  })

  new ButtonGroup($$(".js-simulated-growth-button-group"), { allowUnclickOnSelectedOption: true })

  // Edit Ticker Symbol Modal
  const $editTickerSymbolModal = $$(".js-edit-ticker-symbol-modal")
  if ($editTickerSymbolModal.data("render")) {
    // Disable fade in rendering on load
    $editTickerSymbolModal.toggleClass("fade", false)
    $editTickerSymbolModal.modal()
    $editTickerSymbolModal.toggleClass("fade", true)
  }

  // Edit Privacy Modal
  function togglePrivacyWarningLabel() {
    const selectedInput = $$(".js-privacy-input:checked")

    const displayPrivacyWarning = selectedInput.val() === "anonymous"
    $$(".js-privacy-warning-label").toggleClass("d-none", !displayPrivacyWarning)
  }

  $$(".js-privacy-input").on("change", togglePrivacyWarningLabel)
  togglePrivacyWarningLabel()

  const $editPrivacyModal = $$(".js-edit-privacy-modal")

  // render editPrivacyModal if anchor === "privacy"
  if (window.location.hash.length > 1) {
    const anchor = window.location.hash.substring(1)
    if (anchor === "privacy") {
      $editPrivacyModal.toggleClass("fade", false)
      $editPrivacyModal.modal()
      $editTickerSymbolModal.toggleClass("fade", true)
    }
  }

  // Metric tooltip
  let $tooltip =  $$(".js-metric-tooltip")
  $tooltip.tooltip({ delay: { "show": 200, "hide": 0 }, trigger: "hover" })
  $tooltip.on("click", (e) => {
    e.preventDefault()
  })
})
