import registerView from 'lib/scope'
import Countdown from 'lib/countdown'
import AdvanceToNextLevelForm from 'views/course_progression/shared/advance_to_next_level_form'

class EmailsSentView {
  constructor($view) {
    this.$view = $view
  }

  refreshTable(tableBodyHTML) {
    this.$view.find(".js-emails-table tbody").html(tableBodyHTML)
  }
}

registerView('.js-course_progression-launches-_level4', ($view, $$) => {
  const timeRemaining = $$('.js-emails-countdown').data("time-remaining")
  if (timeRemaining) {
    new Countdown($$('.js-emails-countdown'), {
      alwaysShowDays: false,
      alwaysShowHours: false,
      onTimerDone: function() {
        $view.find('.js-times-up').removeClass("d-none")
        $view.find('.js-emails-countdown').addClass("d-none")
      }
    })
  }

  // Use var instead of let so refreshProgress can modify its value
  var finishedEmailing = false

  const shouldEnableSubmit = ($form) => {
    const $acquisitionChannel = $form.find(".js-acquisition-channel")
    return ($acquisitionChannel.length == 0 || $acquisitionChannel.val()) && finishedEmailing
  }
  const advanceToNextLevelForm = new AdvanceToNextLevelForm($$('.js-level-form'), { shouldEnableSubmit: shouldEnableSubmit })

  const emailsSentView = new EmailsSentView($view)
  function refreshProgress() {
    const url = $view.data("url")
    $.ajax({ url: url }).done(((data, textStatus, jqXHR) => {
      emailsSentView.refreshTable(data.email_table_body_html)

      finishedEmailing = data.finished_emailing
      $$('.js-emailing-unfinished').toggleClass('d-none', finishedEmailing)
      $$('.js-emailing-finished').toggleClass('d-none', !finishedEmailing)
      $$('.js-acquisition-channel-container').toggleClass("d-none", !finishedEmailing)
      advanceToNextLevelForm.refreshSubmitEnabled()
    }))
  }
  refreshProgress()
  setInterval(refreshProgress, 10000)
})
