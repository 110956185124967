import analytics from "lib/analytics"
import { simplePluralize } from "lib/string_helper"
import LineChart from "views/shared/ticker/line_chart"
import TickerTable from "views/applicants/ticker/ticker_table"
import SidebarGrowthText from "views/applicants/ticker/sidebar_growth_text"
import TickerEventNode from "views/applicants/ticker/ticker_event_node"

const lineOptions = {
  scales: {
    yAxes: [
      {
        ticks: { display: false },
        scaleLabel: { fontColor: "#324555" },
        gridLines: {
          display: false,
          color: "#324555",
        }
      }
    ],
    xAxes: [
      {
        type: "time",
        ticks: { display: false, fontColor: "#324555" },
        gridLines: {
          display: false,
          color: "#324555",
          tick: [1]
        },
        scaleLabel: { fontColor: "#324555" },
      }
    ]
  },
  tooltips: {
    enabled: false
  },
  spanGaps: true,
  layout: {
    padding: {
      left: 0,
      right: 10,
      top: 10,
      bottom: 0
    }
  }
}

export default class SidebarCompany extends TickerEventNode {
  constructor({$view, wrapperSelector, mainChart }) {
    super($view)

    this.$view = $view
    this.wrapperSelector = wrapperSelector

    const context = this.$view.find(".js-line-chart")
    this.lineChart = new LineChart($view, context, lineOptions)

    this.growthText = new SidebarGrowthText(this.$view, this.lineChart)

    this.mainChartId = null
    this.tableSlot = null
    this.toolbarSlot = null

    this.mainChart = mainChart

    this.initializeChartData()
    this.initializeTableData()

    this.symbol = this.tableData.name

    this.initializeEventEmitters()
    this.updateMostRecentDataPoints()
    this.updateGrowthText()
  }

  onSelect(event, eventData) {
    const { symbol, color }= eventData
    if (symbol !== this.symbol) return

    analytics.track("[Ticker] Selected sidebar company", { company: this.symbol, sampleCompany: this.getChart().data("sample-company")})

    const $sidebarSelector = this.$view.find(".js-sidebar-company-selector")
    $sidebarSelector.html("<span style='position: relative; top: 1px;'>-</span>")
    $sidebarSelector.toggleClass("selected", true)
    this.$view.toggleClass("selected", true)
    this.$view.css("border-color", color)

    this.mainChartIds = this.mainChart.addDatasetFromChartData(this.chartData, color)
    this.mainChart.applyFilters()
  }

  onDeselect(event, eventData) {
    const symbol = eventData.symbol
    if (symbol !== this.symbol) return

    // Remove line from mainChart
    for (const mainChartId of this.mainChartIds) {
      this.mainChart.removeDatasetFromChartData(mainChartId)
    }
    this.mainChart.applyFilters()

    //Reset color in sidebar chart
    this.lineChart.resetBorderColor()

    // Remove it from selected company toolbar
    this.toolbarSlot = null

    const $sidebarSelector = this.$view.find(".js-sidebar-company-selector")
    $sidebarSelector.html("+")
    $sidebarSelector.toggleClass("selected", false)
    this.$view.toggleClass("selected", false)
    this.$view.css("border-color", "transparent")
  }

  onTimeFilter(event, eventData) {
    const { lookbackDuration, start, end } = eventData

    if (lookbackDuration == 0) {
      this.resetLineChart()
    } else {
      this.applyTimeFilterToLineChart(start, end)
    }

    this.updateGrowthText()
  }

  initializeEventEmitters() {
    this.$view.on("click", (e) => {
      const symbol = this.symbol
      const eventData = { symbol }

      const isSelected = this.$view.hasClass("selected")
      if (isSelected) {
        this.triggerDeselect(eventData)
      } else {
        eventData["tableData"] = this.tableData
        this.triggerSelect(eventData)
      }
    })
  }

  initializeChartData() {
    const chartData = this.getChart().data("chart-data")

    this.chartData = chartData
  }

  initializeTableData() {
    const tableData = this.getChart().data("table-data")
    const fullTableData = TickerTable.populateGrowthsInTableData(tableData, this.lineChart.getPrimaryDataset())

    this.tableData = fullTableData
  }

  getChart() {
    return this.$view.find(".js-line-chart")
  }


  updateGrowthText() {
    this.growthText.update()
  }

  updateMostRecentDataPoints() {
    const dataset = this.lineChart.getPrimaryDataset()
    let mostRecentPoint = dataset.data[dataset.data.length - 1 ]

    const $mostRecentDataPointText = this.$view.find(".js-sidebar-most-recent-data-point")

    const unit = this.$view.data("unit")
    const prefix = this.$view.data("prefix")
    const suffix = this.$view.data("suffix")

    const pluralizedUnit = unit ? simplePluralize(mostRecentPoint.y, unit) : ""

    $mostRecentDataPointText.html(`${prefix}${mostRecentPoint.y}${suffix} ${pluralizedUnit}`)
  }

  // start & end are epoch
  applyTimeFilterToLineChart(start, end) {
    const timeFilteredDatasets = LineChart.applyTimeFilterToDatasets(this.lineChart.getDatasets(), start, end)
    this.lineChart.updateDatasets(timeFilteredDatasets)
  }

  resetLineChart() {
    this.lineChart.reset()
  }
}
