import Chart from "chart.js"
import PercentChart from "views/course_progression/shared/percent_chart";
import registerView from "lib/scope"
import AdvanceToNextLevelForm from "views/course_progression/shared/advance_to_next_level_form"
import Tabs from "components/tabs"


class BarChart extends PercentChart {
  constructor($view, context, chartConfigs, initialValues) {
    const colors = chartConfigs.map((c) => c.color(0.5))
    const initialColors = chartConfigs.map((c) => c.color(0.2))
    const borderColors = chartConfigs.map((c) => c.color(1))
    const labels = chartConfigs.map((c) => c.label)

    const chart = new Chart(context, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [{
          label: "Final",
          data: [0, 0, 0],
          backgroundColor: colors,
          borderColor: borderColors,
          borderWidth: 1
        }, {
          label: "Initial",
          data: initialValues,
          backgroundColor: initialColors,
          borderColor: borderColors,
          borderWidth: 1,
        }]
      },
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              const label = data.labels[tooltipItem.index]

              return `${label}: ${value}%`
            }
          }
        },
        legend: {
          onClick: () => {}
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMax: 100
            }
          }]
        }
      }
    });

    const selectors = chartConfigs.map((config) => config.selector)
    super($view, chart, selectors)
  }

  update() {
    const data = this.getInputSelectorData()
    this.chart.data.datasets[0].data = data
    this.chart.update()
  }
}

registerView(".js-course_progression-pmf-_level10", ($view, $$) => {
  let $tooltip =  $$(".js-final-score-tooltip")
  $tooltip.tooltip()

  const $tabsContainer = $$(".js-course_progression-pmf-level10-tabs")
  if ($tabsContainer.length) {
    const initialTabname = $tabsContainer.data("initialTab")
    new Tabs($tabsContainer, {
      initialTabname: initialTabname,
      afterSetActiveTab: (data) => {
        // Hide the resources button when showing congratulations page
        $(".js-level-resources").toggleClass("d-none", data.tabname == "congratulations")
      }
    })
  }

  function finalNPSValid() {
    const finalNPS = parseInt($$(".js-a-number-users-input").val())
    if (finalNPS) {
      return finalNPS >= $$(".js-bar-chart-inputs").data("goal")
    }

    return false
  }

  $$(".js-a-number-users-input").on("blur", () => {
    $$(".js-low-final-nps-validation").toggleClass("d-none", finalNPSValid())
  })

  const chartConfigs = [
    {
      selector: ".js-a-number-users-input",
      label: "Must have",
      color: (opacity) => `rgba(17, 208, 61, ${opacity})`,
    },
    {
      selector: ".js-b-number-users-input",
      label: "Nice to have",
      color: (opacity) => `rgba(54, 162, 235, ${opacity})`,
    },
    {
      selector: ".js-c-number-users-input",
      label: "Don't care",
      color: opacity => `rgba(220, 53, 69, ${opacity})`,
    },
  ]

  const context = $$(".js-bar-chart")
  const initialValues = $$(".js-bar-chart").data("initialValues")
  const barChart = new BarChart($view, context, chartConfigs, initialValues)

  new AdvanceToNextLevelForm($$(".js-level-form"), {
    shouldEnableSubmit: ($form) => {
      return AdvanceToNextLevelForm.defaultShouldEnableSubmit($form) && barChart.valid() && finalNPSValid()
    }
  })
})
