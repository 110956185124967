import LineChart from "views/shared/ticker/line_chart"
import TickerEventNode from "views/applicants/ticker/ticker_event_node"
import { getPositiveNegativeAssets } from "views/applicants/ticker/growth_text"

export default class MainGrowthText extends TickerEventNode {
  constructor($view, mainChart) {
    super($view)

    this.mainChart = mainChart

    this.mostRecentDataPoint = $view.data("most-recent-value")

    this.prefix = $view.data("prefix") || ""
    this.suffix = $view.data("suffix") || ""

    this.isAnimating = true
    this.setMinWidth()
    setTimeout(() => { this.animateMostRecentDataPoint() },200)
  }

  onTimeFilter(event, eventData) {
    this.updateMainChartGrowthTexts(eventData)
  }

  onMainChartHover(event, eventData) {
    if (this.isAnimating) return

    const activePoint = eventData.activePoint

    const datasetIndex = activePoint._datasetIndex
    const pointIndex = activePoint._index

    const datasets = activePoint._chart.tooltip._data.datasets

    const activePointValue = datasets[datasetIndex].data[pointIndex].y
    const firstPointValue = datasets[datasetIndex].data[0].y

    this.setMostRecentDataPointText(activePointValue)

    const isGrowthPositive = activePointValue >= firstPointValue
    const { textColor } = getPositiveNegativeAssets(isGrowthPositive)

    this.$view.find(".js-most-recent-data-point").css("color", textColor)
  }

  onMainChartHoverExit(event, eventData) {
    if (this.isAnimating) return

    this.restoreMostRecentDataPointText()
  }

  setMostRecentDataPointText(value) {
    const mostRecentDataPointSelector = this.$view.find(".js-most-recent-data-point")

    mostRecentDataPointSelector.text(`${this.prefix}${value}${this.suffix}`)
  }

  restoreMostRecentDataPointText() {
    this.setMostRecentDataPointText(this.mostRecentDataPoint)

    if (this.textColor) {
      this.$view.find(".js-most-recent-data-point").css("color", this.textColor)
    }
  }

  updateMainChartGrowthTexts(eventData) {
    const timePeriod = eventData.displayName
    const unit = this.$view.data("unit")

    const dataset = this.mainChart.getCurrentPrimaryDataset()
    const { quantity, percentage } = LineChart.getGrowthFromDataset(dataset)

    const roundedGrowthPercentage = Math.round(percentage * 100) / 100

    const isGrowthPositive = quantity >= 0
    const { textColor, arrow } = getPositiveNegativeAssets(isGrowthPositive)


    const growthText = `${arrow} ${quantity} ${unit} (${roundedGrowthPercentage}%) ${timePeriod}`
    this.$view.find(".js-growth-text").css("color", textColor)
    this.$view.find(".js-growth-text").html(growthText)

    this.textColor = textColor
    this.$view.find(".js-most-recent-data-point").css("color", textColor)
  }

  animateMostRecentDataPoint() {
    this.nextAnimatedValue = 0

    let id
    let stepSize = 2

    id = setInterval(() => {
      const currentAnimatedValue = this.nextAnimatedValue

      this.nextAnimatedValue = currentAnimatedValue + stepSize
      stepSize = Math.pow(stepSize, 2)

      const continueAnimation = this.mostRecentDataPoint > currentAnimatedValue
      const renderableValue =  continueAnimation ? currentAnimatedValue : this.mostRecentDataPoint

      this.setMostRecentDataPointText(renderableValue)

      if (!continueAnimation) this.stopAnimation()
    }, 150)

    this.intervalId = id
  }

  stopAnimation() {
    clearInterval(this.intervalId)
    this.isAnimating = false
    this.restoreMostRecentDataPointText()
  }

  setMinWidth() {
    const prevText = this.$view.find(".js-most-recent-data-point").text()

    const numberDigits = this.mostRecentDataPoint.toString().length
    const allZeros = "0".repeat(numberDigits)

    this.$view.find(".js-most-recent-data-point").text(allZeros)
    const width = this.$view.find(".js-most-recent-data-point")[0].offsetWidth

    this.$view.find(".js-most-recent-data-point").text(prevText)

    this.$view.find(".js-most-recent-data-point").css("min-width", width)
  }
}
