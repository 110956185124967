import 'prismjs'
import registerView from "lib/scope"
import analytics from "lib/analytics"
import Clipboard from 'lib/clipboard'

registerView(".js-ticker_snippet_docs", ($view, $$) => {
  analytics.track("[Ticker] Viewed ticker snippet docs", {})

  new Clipboard(".js-copy-code-snippet-button")

  $$(".js-check-button").on("click", () => {
    const url = $$(".js-check-button").data("url")

    const renderTestResultModal = (data) => {
      $$(".js-integration-successful-modal").modal()

      if (Object.keys(data).length) {
        const analyticsFnStatuses = [
          { success: data.page, selector: ".js-page-status"},
          { success: data.identify, selector: ".js-identify-status"},
          { success: data.active, selector: ".js-active-status"}
        ]

        let totalSuccess = true
        let totalFailure = true

        analyticsFnStatuses.map(({success, selector}) => {
          const $el = $$(selector)
          $el.toggleClass("success", success)
          $el.toggleClass("fail", !success)
          $el.find(".js-success").toggleClass("d-none", !success)
          $el.find(".js-fail").toggleClass("d-none", success)

          if (success) {
            totalFailure = false
          } else {
            totalSuccess = false
          }
        })

        $$(".js-total-success").toggleClass("d-none", !totalSuccess)
        $$(".js-total-failure").toggleClass("d-none", !totalFailure)

        // If neither total success nor total failure, then show partial success
        const partialSuccess = !(totalSuccess || totalFailure)
        $$(".js-partial-success").toggleClass("d-none", !partialSuccess)
      } else {
        $$(".js-total-failure").toggleClass("d-none", true)
      }
    }

    $.ajax({ url: url }).done(((data, textStatus, jqXHR) => {
      renderTestResultModal(data)
    })).fail(((data, textStatus, jqXHR) => {
      renderTestResultModal({})
    }))
  })
})

