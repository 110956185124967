export default class ClientSideValidationView {
  constructor($view) {
    this.$view = $view

    this.scrollToFirstError()
    window.ClientSideValidations.callbacks.form.fail = () => this.scrollToFirstError()
  }

  scrollToFirstError() {
    if (!this.$view.find('.help-block.text-danger').length) {
      return
    }

    this.$view.find('.help-block.text-danger').get(0).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }
}
