import registerView from 'lib/scope'
import AdvanceToNextLevelForm from 'views/course_progression/shared/advance_to_next_level_form'
import Countdown from 'lib/countdown'
import Tabs from 'components/tabs'

registerView(".js-course_progression-pmf-_level9", ($view, $$) => {
  const $countdown = $$(".js-roadmap-countdown")
  new Countdown($countdown)

  new AdvanceToNextLevelForm($$(".js-level-form"))

  const $tabsContainer = $$(".js-course_progression-pmf-level9-tabs")
  if ($tabsContainer.length) {
    const initialTabname = $tabsContainer.data("initialTab")
    new Tabs($tabsContainer, { initialTabname: initialTabname })
  }
})
