import registerView from "lib/scope"
import AreYouSure from 'lib/are_you_sure'
import Clipboard from 'lib/clipboard'

registerView(".js-course_progression-pmf-_level8", ($view, $$) => {
  const $successModal = $$(".js-level8-success-modal")
  if ($successModal.length) {
    $successModal.modal()
  }

  new Clipboard('.js-copy-email-template')
  const clipboardText = $$(".js-pmf-level8-email").text()
  $$('.js-copy-email-template').attr('data-clipboard-text', clipboardText)
})
