import registerView from 'lib/scope'
import analytics from 'lib/analytics'

registerView('.js-home-landing-_hero', ($view, $$) => {
  // ClientSideValidations only automatically acts on _visible_ forms, so explicitly enable it for the email
  // subscription form when the modal is presented
  let $applicationsClosedModal = $$('.js-applications-closed-modal')
  $applicationsClosedModal.on('shown.bs.modal', (e) => {
    $applicationsClosedModal.find('form').enableClientSideValidations();

    analytics.track('Opened LP hero email subscription modal')
  })
})
