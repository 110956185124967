import registerView from 'lib/scope'
import Tabs from 'components/tabs'

registerView('.js-home-landing_v2-_experts', ($view, $$) => {
  let $imessageContainer = $$(".js-imessage-screen-container")

  // To reduce visual flickering when changing tabs, don't ever _decrease_ the height of the imessage-screen-container.
  let previousImessageContainerHeight = null
  const afterSetActiveTab = () => {
    if (!previousImessageContainerHeight) {
      previousImessageContainerHeight = $imessageContainer.height()
    } else if (previousImessageContainerHeight > $imessageContainer.height()) {
      // If after changing tabs, the previous container was taller, set the container to that height.
      $imessageContainer.height(previousImessageContainerHeight)
    } else {
      // If the current container is taller, track the new height for future tabs
      previousImessageContainerHeight = $imessageContainer.height()
    }
  }
  new Tabs($view, { afterSetActiveTab: afterSetActiveTab})
})
