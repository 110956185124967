import * as React from "react"
import { Winner, WinnerLocation } from "../Index"

interface HeroMapPinProps {
  onClick: ((pin: HeroMapPin, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
  location: WinnerLocation
  locationString: string
  winners: Winner[]
  selected: boolean
  winnerCardHighlighted: boolean
}

interface HeroMapPinState {
  visible: boolean
}

export class HeroMapPin extends React.Component<HeroMapPinProps, HeroMapPinState> {
  constructor(props: HeroMapPinProps) {
    super(props)

    this.state = {
      visible: false
    }

    this.onClick = this.onClick.bind(this)
  }

  onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    this.props.onClick(this, event)
  }

  componentDidUpdate(prevProps: Readonly<HeroMapPinProps>, prevState: Readonly<{}>, snapshot?: any): void {
    this._componentRendered()
  }

  componentDidMount(): void {
    this._componentRendered()
  }

  _componentRendered(): void {
    let $tooltip = ($(`.js-tooltip[data-location-string="${this.props.locationString}"]`) as any)
    $tooltip.tooltip({ container: ".js-flat-earth-hero", delay:  { 'show': 0, 'hide': 0 }, trigger: 'hover' })

    if (this.props.winnerCardHighlighted) {
      $tooltip.tooltip("show")
    } else {
      $tooltip.tooltip("hide")
    }

    setTimeout(() => {
      this.setState((state) => {
        return { visible: true }
      })
    }, this.props.location.x * 2000)
  }

  render() {
    const style = {
      top: `${this.props.location.y * 100}%`,
      left: `${this.props.location.x * 100}%`
    }

    let className = "flat-earth-pin js-tooltip"
    if (this.props.selected) {
      className += " selected"
    }

    if (this.state.visible) {
      className += " visible"
    }

    if (this.props.winnerCardHighlighted) {
      className += " winner-card-highlighted"
    }

    return (
      <div
        className={className}
        style={style}
        data-location-string={this.props.locationString}
        data-tooltip="true"
        data-html="true"
        title={`<div class=\"sans-serif py-1\">${this.props.winners[0].location_description}</div>`}
        onClick={this.onClick}
      >
      </div>
    )
  }
}
