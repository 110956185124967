import registerView from 'lib/scope'
import analytics from 'lib/analytics'

registerView('.js-home-tournament', ($view, $$) => {
  analytics.track('Viewed interstitial')

  $$('.js-cta').on('click', (e) => {
    e.preventDefault()
    $$('.js-default').addClass('d-none')
    $$('.js-after-click').removeClass('d-none')

    setTimeout(() => {
      Turbolinks.visit($$('.js-cta').attr('href'))
    }, 500)
  })
})
