import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import "moment-timezone"
import jstz from 'jstimezonedetect'

class ChatMessage extends React.Component {
  innerHTML() {
    return { __html: this.props.body_html }
  }

  render () {
    return (
        <div className="chat-message">
          <span className="font-weight-bold">{this.props.sender_name}</span>
          <span className="text-gray-A pl-2 text-small">
            {moment(this.props.created_at).tz(jstz.determine().name()).format("h:mm a")}
          </span>
          <div className="serif" dangerouslySetInnerHTML={this.innerHTML()} />
        </div>
    );
  }
}

ChatMessage.propTypes = {
  sender: PropTypes.string,
  body: PropTypes.string
};

export default ChatMessage
