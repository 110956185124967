import registerView from 'lib/scope'

const POLL_INTERVAL = 10000
const POLL_END_BUFFER = (30 * 60 * 1000)

registerView('.js-community-call-participant-view', ($view, $$) => {
    async function getParticipantsView() {
        const url = $view.data("url")
        $.ajax({ url: url }).done(((data, textStatus, jqXHR) => {
            $view.find(`.js-community-call-participants`).replaceWith(data)
        }))
    }

    getParticipantsView()
    const interval = setInterval(getParticipantsView, POLL_INTERVAL)

    const stopPollingTime = Date(parseInt($view.data('end-time'))) + POLL_END_BUFFER

    setInterval(() => {
        if (Date.now() < stopPollingTime ) clearInterval(interval)
    }, POLL_INTERVAL)


    let $tooltip =  $$('.js-contact-tooltip')
    $tooltip.tooltip({ delay: { 'show': 500, 'hide': 0 }, trigger: 'hover' })
    $tooltip.on('click', (e) => {
        e.preventDefault()
    })
})
