import registerView from 'lib/scope'

registerView('.js-home-landing_v3-_accountability', ($view, $$) => {
  const $options = $$("select option")
  const values = []
  $options.each((i, el) => {
    values.push($(el).attr("value"))
  })

  const animateCurrentTabRows = () => {
    $$("tr.visible").removeClass("visible")
    $$(".js-tab-content:not(.d-none) tbody tr").each((i, row) => {
      const $row = $(row)
      setTimeout(() => $row.addClass("visible"), i * 75)
    })
  }
  animateCurrentTabRows()

  var selectedIndex = 0;
  let interval = setInterval(() => {
    selectedIndex = selectedIndex + 1

    $$("select").val(values[selectedIndex % values.length])
    $$("select").change()
  }, 3000)

  $$("select").on("change", () => {
    animateCurrentTabRows()
  })

  $$("select").on("click", () => {
    clearInterval(interval)
  })
})

