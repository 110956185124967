import registerView from 'lib/scope'
import ButtonGroup from 'components/button_group'
import Textarea from 'components/textarea'
import Tabs from 'components/tabs'

registerView('.js-reviewers-reviews-new', ($view, $$) => {
  new ButtonGroup($$('.js-rating-button-group'))
  $$('textarea').each((i, el) => {
    new Textarea($$(el))
  })

  let $ratingInput = $$('.js-rating-button-group .js-button-group-input')
  let onRatingChange = () => {
    $$('.js-strong-no-warning').toggle($ratingInput.val() === 'strong_no')
    $$('.js-skip').toggleClass('disabled', $ratingInput.val().length > 0)
  }
  onRatingChange()
  $ratingInput.on('change', () => onRatingChange())

  new Tabs($view)

  $$('.js-tooltip').tooltip({ delay:  { 'show': 500, 'hide': 0 }, trigger: 'hover' })
})
