// Could implement something fancier where the selector doesn't need to be specified
// (e.g. using a standard file hierarchy)
let registeredViews = {}
const registerView = function(selector, onReady) {
  registeredViews[selector] = onReady
}

$(document).on('turbolinks:load', () => {
  $.each(registeredViews, (selector, onReady) => {
    $('.js-view' + selector).each((i, el) => {
      const $el = $(el)
      onReady($el, (selector) => $el.find(selector))
    })
  })
})

export default registerView
