
export default class LocalStorageForm {
  constructor($$, options = {}) {
    this.$$ = $$
    this.ttl = 1000 * 60 * 10 // 10 minutes

    this.cleanUpService()

    this.addEventListeners()
    this.populate()
  }

  //Clears expired pioneer local storage items
  cleanUpService() {
    const keys = Object.keys(localStorage)

    for (const key of keys) {
      let item = null
      try {
        item = JSON.parse(localStorage.getItem(key))
      } catch (e) {
        continue
      }

      if (item && Date.now() > item.expiry) {
        localStorage.removeItem(key)
      }
    }
  }

  populate() {
    this.$$("form").map((idx, form) => {
      const key = form.id
      const item = JSON.parse(localStorage.getItem(key))

      if (!item) return

      const formValues = item.data
      for (const key in formValues) {
        const value = formValues[key]

        const selector = this.$$("form").find(`#${key}`)
        const selectorHasValue = this.$$(selector).val()

        if (!selectorHasValue) {
          this.$$(selector).val(value)

          // Trigger are-you-sure to make the form dirty, refresh expiry on item
          this.$$(selector).trigger("change")
        }
      }
    })
  }

  updateLocalStorage() {
    this.$$("form").map((idx, form) => {
      const selectors = $(form).find(":input:not(input[type=submit]):not(input[type=button]):not(input[type=hidden])")
      const data = {}

      selectors.map((_idx, selector) => {
        const value = $(selector).val()
        if (value) {
          data[selector.id] = value
        }
      })

      const item = { expiry: Date.now() + this.ttl, data }
      localStorage.setItem(form.id, JSON.stringify(item))
    })
  }

  addEventListeners() {
    this.$$("form").map((_idx, form) => {
      const $form = this.$$(form)
      $form.on("change", () => this.updateLocalStorage())
    })

    this.$$("form").map((_idx, form) => {
      $(form).on("submit", (event) => {
        localStorage.removeItem(event.target.id)
      })
    })
  }
}
