import registerView from 'lib/scope'
import _ from "underscore"

registerView('.js-left-sidebar-container', ($view, $$) => {
  // We manually set the sidebar height so that the border takes up at least the height of the window (minus padding)
  // But, if the sidebar content goes on beyond the window, we let it grow naturally
  let setSidebarHeight = _.throttle(() => {
    let $stickySidebar = $$('.js-full-height-sidebar-container')
    let heightBasedOnWindow = window.innerHeight -
        parseFloat($stickySidebar.css('padding-bottom')) - parseFloat($stickySidebar.css('padding-top')) // -
        // $('.layouts-header').height() - $('.layouts-footer').height()
    $$('.js-sidebar-column-content').css('height', 'auto')
    let naturalHeight = $$('.js-sidebar-column-content').height()
    $$('.js-sidebar-column-content').css('height', _.max([naturalHeight, heightBasedOnWindow]))
  }, 100)

  $(window).resize(() => {
    setSidebarHeight()
  })
  $('.js-sidebar-column-content').on('sidebarUpdated', () => {
    setSidebarHeight()
  })
  setSidebarHeight()
})
