import registerView from 'lib/scope'
import Chart from "chart.js"

registerView('.js-course_progression-pmfs-level', ($view, $$) => {
  const chartConfigs = [
    {
      value: 10,
      range: [0, 15],
      color: "#DC3545",
    },
    {
      value: 10,
      range: [15, 30],
      color: "#FF791A",
    },
    {
      value: 10,
      range: [30, 40],
      color: "#156EEB",
    },
    {
      value: 10,
      range: [40, null],
      color: "#11D03D",
    },
    {
      value: 40,
      color: "#FFE44B"
    }
  ]

  const currentPMFValue = $view.data("pmf-value")

  if (currentPMFValue) {
    chartConfigs.forEach((config) => {
      if (config.range) {
        let transparentize = false
        const min = config.range[0]
        const max = config.range[1]

        if ((min && currentPMFValue < min) || (max && currentPMFValue >= max)) {
          transparentize = true
        }

        if (transparentize) {
          config.color = `${config.color}33`
        }
      }
    })
  }

  const context = $$(".js-pmf-meter")
  const chart = new Chart(context, {
    type: "pie",
    data: {
      datasets: [{
        data: chartConfigs.map((c) => c.value),
        backgroundColor: chartConfigs.map((c) => c.color),
        borderColor: "#FFE44B",
      }],
      labels: chartConfigs.map((c) => c.label)
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 80,
      rotation: Math.PI,
      animation: {
        animateRotate: true
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    }
  })

  chart.update()

  $$('.js-tooltip').tooltip({ delay:  { 'show': 0, 'hide': 0 }, trigger: 'hover' })
})
