import * as React from "react"
import Hero from "./index/Hero"
import WinnerGrid from "./index/WinnerGrid"

export interface WinnerLocation {
  x: number
  y: number
}

export interface Winner {
  id: number
  slug: string
  full_name: string
  age: number
  location_description: string
  one_liner: string
  month_of_win: string
  bio: string
  project_summary: string
  project_url: string
  twitter_url: string
  embeddable_livestream_presentation_url: string
  photo_url: string
  location: WinnerLocation
  teammates: Winner[]
}

export interface RouteTemplates {
  [index:string]: string
}

export interface IndexProps {
  winners: Winner[]
  route_templates: RouteTemplates
  winners_subtitle: string
}

export interface IndexState {
  filterWinnerGridIds: number[]
  highlightMapLocation: WinnerLocation
}

export default class Index extends React.Component<IndexProps, IndexState> {
  constructor(props: IndexProps) {
    super(props)

    this.state = {
      filterWinnerGridIds: [],
      highlightMapLocation: null
    }

    this.onFilterWinnerGridIdsChange = this.onFilterWinnerGridIdsChange.bind(this)
  }

  onFilterWinnerGridIdsChange(filterWinnerGridIds: number[]) {
    this.setState((state, props) => {
      return {
        filterWinnerGridIds: filterWinnerGridIds
      }
    })
  }

  onMouseEnterWinnerCard(winner: Winner) {
    this.setState((state, props) => {
      return {
        highlightMapLocation: winner.location
      }
    })
  }

  onMouseLeaveWinnerCard(winner: Winner) {
    this.setState((state, props) => {
      return {
        highlightMapLocation: null
      }
    })
  }

  render() {
    return (
      <div className="winner_team_members-index w-100 bg-white pb-md-4 pb-2">
        <Hero
          onFilterWinnerGridIdsChange={this.onFilterWinnerGridIdsChange}
          highlightMapLocation={this.state.highlightMapLocation}
          {...this.props}
        />
        <WinnerGrid
          onMouseEnterWinnerCard={this.onMouseEnterWinnerCard.bind(this)}
          onMouseLeaveWinnerCard={this.onMouseLeaveWinnerCard.bind(this)}
          winnerIdsToShow={this.state.filterWinnerGridIds}
          {...this.props} />
      </div>
    )
  }
}
