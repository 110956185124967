import React from "react"
import PropTypes from "prop-types"
import ChatMessage from "./ChatMessage"
import ChatMessageForm from "./ChatMessageForm"
import cable from 'lib/cable'
import _ from 'underscore'

class ChatRoom extends React.Component {
  constructor(props) {
    super(props)

    this.sortedRooms = _.sortBy(this.props.rooms, (room) => {
      if (room.slug == "vips") {
        return -1000;
      } else if (room.slug == "general") {
        return -100;
      } else {
        return parseInt(room.slug)
      }
    })

    this.state = {
      activeRoomId: this.sortedRooms[0].id,
      unreadRoomIds: new Set,
      messages: [],
      online: [],
    }

    // Only contains room IDs that we're listening to
    this.roomIdToMessages = {}

    this.receivedMessage = this.receivedMessage.bind(this)
    this.handleDeletedMessageForRoom = this.handleDeletedMessageForRoom.bind(this)
    this.onRoomLinkClick = this.onRoomLinkClick.bind(this)
    this.ref = React.createRef()

    this.loadAllMessagesForRoom(this.state.activeRoomId)
    this.refreshOnlineList()

    setInterval(this.refreshOnlineList.bind(this), 15000);
    setTimeout(this.refreshOnlineList.bind(this), 1000); // Run once to capture the current user being online
  }

  loadAllMessagesForRoom(roomId) {
    if (this.roomIdToMessages.hasOwnProperty(roomId)) {
      // Already tail-loaded these messages
      return;
    }
    this.roomIdToMessages[roomId] = []

    $.ajax({
      url: `/chat/${roomId}/messages`,
      data: {
        livestream_viewer_hex_uid: this.props.livestream_viewer_hex_uid
      }
    }).done((messages) => {
      this.handleIncomingMessagesForRoom(roomId, messages)
    })
  }

  refreshOnlineList() {
    $.ajax({
      url: this.props.refresh_online_list_url,
    }).done((data) => {
      this.setState((state, props) => {
        let onlinePreview = data.onlinePreview
        let totalOnlineCount = data.totalOnlineCount
        let vipOnlineCount = data.vipOnlineCount

        return {
          online: onlinePreview,
          totalOnlineCount: totalOnlineCount,
          vipOnlineCount: vipOnlineCount
        }
      })
    })
  }

  handleIncomingMessagesForRoom(roomId, messages) {
    this.roomIdToMessages[roomId] = this.roomIdToMessages[roomId].concat(messages)

    if (roomId == this.state.activeRoomId) {
      this.setState((state, props) => ({
        messages: this.roomIdToMessages[state.activeRoomId] || []
      }))
    }
  }

  handleDeletedMessageForRoom(deletedMessage) {
    this.roomIdToMessages[deletedMessage.roomId] = this.roomIdToMessages[deletedMessage.roomId].filter((message) => {
      return message.id != deletedMessage.messageId
    })

    if (deletedMessage.roomId == this.state.activeRoomId) {
      this.setState((state, props) => ({
        messages: this.roomIdToMessages[state.activeRoomId] || []
      }))
    }
  }

  scrollToBottom() {
    let chatMessages = this.ref.current.getElementsByClassName("js-chat-messages")[0]
    chatMessages.scrollTop = chatMessages.scrollHeight;
  }

  receivedLivestreamBroadcast(broadcast) {
    if (broadcast.message) {
      this.receivedMessage(broadcast.message)
    } else if (broadcast.deletedMessage) {
      this.handleDeletedMessageForRoom(broadcast.deletedMessage)
    }
  }

  receivedMessage(message) {
    let roomId = message.chat_room_id

    if (roomId != this.state.activeRoomId) {
      this.setState((state, props) => {
        let newUnreadRoomIds = new Set(state.unreadRoomIds)
        newUnreadRoomIds.add(roomId)

        return {
          unreadRoomIds: newUnreadRoomIds
        }
      })
    }

    if (!this.roomIdToMessages.hasOwnProperty(roomId)) {
      return;
    }

    this.handleIncomingMessagesForRoom(roomId, [message])
  }

  activeRoom() {
    return _.find(this.sortedRooms, (room) => {
      return room.id === this.state.activeRoomId
    })
  }

  componentDidMount() {
    this.livestreamChannel = cable.subscriptions.create({ channel: 'LivestreamChannel', id: 3, livestream_viewer_hex_uid: this.props.livestream_viewer_hex_uid }, {
      received: this.receivedLivestreamBroadcast.bind(this),
    })
    this.scrollToBottom()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let chatMessages = this.ref.current.getElementsByClassName("js-chat-messages")[0]

    // Scroll to bottom, unless we're staying in the same room and the user is scrolled up.
    let wasScrollingUp = false
    if (prevState.activeRoomId == this.state.activeRoomId && this.previousScrollHeight) {
      // 300px buffer. I.e. was scrolling up by more than 300px
      wasScrollingUp = (chatMessages.scrollTop + chatMessages.clientHeight) < (this.previousScrollHeight - 300)
    }

    if (!wasScrollingUp) {
      this.scrollToBottom()
    }

    this.previousScrollHeight = chatMessages.scrollHeight
  }

  componentWillUnmount() {
    this.livestreamChannel.unsubscribe()
  }

  onRoomLinkClick(e) {
    let currentTarget = e.currentTarget;
    let newActiveRoomId = parseInt(currentTarget.dataset.roomId);

    this.setState(function(state, props) {      
      let newUnreadRoomIds = new Set(state.unreadRoomIds)
      newUnreadRoomIds.delete(newActiveRoomId)

      return {
        activeRoomId: newActiveRoomId,
        messages: this.roomIdToMessages[newActiveRoomId] || [],
        unreadRoomIds: newUnreadRoomIds
      }
    })
    this.loadAllMessagesForRoom(newActiveRoomId)
  }

  render() {
    const roomList = this.sortedRooms.map((room) => {
        let classNames = "room-list-item font-weight-400 px-3 "
        if (room.id == this.state.activeRoomId) {
          classNames += " active"
        }

        if (this.state.unreadRoomIds.has(room.id)) {
          classNames += " unread"
        }

        return (
            <div key={room.id} className={classNames}>
              <span className="room-list-item-text" onClick={this.onRoomLinkClick} data-room-id={room.id}>
                #{room.slug}
              </span>
            </div>
        )
      })

    let messages = this.state.messages.map((message) => {
      return (
          <ChatMessage
              key={message.id}
              {...message}
          />
      )
    })

    if (this.state.messages.length == 0) {
      messages = (
          <div className="font-weight-400">
            No messages yet
          </div>
      )
    }

    let usersToRender;
    let nTotalUsersIncludingUnrendered = 0;
    let onlineListTitle;
    if (this.activeRoom().slug === "vips") {
      usersToRender = _.filter(this.state.online, (presence) => {
        return presence.vip;
      })
      onlineListTitle = "VIPs"
      nTotalUsersIncludingUnrendered = this.state.vipOnlineCount;
    } else {
      usersToRender = this.state.online
      onlineListTitle = "Online"
      nTotalUsersIncludingUnrendered = this.state.totalOnlineCount;
    }

    let onlineList = usersToRender.map((presence) => {
      return (
          <div key={presence.id} className="presence-list-item font-weight-400">{presence.display}</div>
      )
    })

    if (usersToRender.length < nTotalUsersIncludingUnrendered) {
      let delta = nTotalUsersIncludingUnrendered - usersToRender.length
      onlineList.push(
          <div key={`additional-${delta}`} className="presence-list-item font-italic font-weight-400">
            {`And ${delta} others`}
          </div>
      )
    }

    return (
        <div ref={this.ref}>
          <div className="chat-room bg-gray-F border border-black">
            <div className="row no-gutters">
              <div className="col-3 border-right">
                <div className="chatroom-list">
                  <div className="font-weight-bold mb-1 py-2 px-3">
                    Channels
                  </div>
                  <div className="room-list-items">
                    {roomList}
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="row">
                  <div className="col-12 col-md-8 pr-0">
                    <h5 className="px-3 pt-2 mb-0 border-bottom">
                      #{this.activeRoom().slug}
                    </h5>
                    <div className="js-chat-messages chat-messages">
                      {messages}
                    </div>
                  </div>
                  <div className="d-none d-md-block col-md-4 pl-0 border-left">
                    <div className="presence-list">
                      <div className="font-weight-bold mb-1">
                        {`${onlineListTitle} (${nTotalUsersIncludingUnrendered})`}
                      </div>
                      <div className="presence-list-items">
                        {onlineList}
                      </div>
                    </div>
                  </div>
                </div>
                <ChatMessageForm
                    chatRoomId={this.state.activeRoomId}
                    livestreamViewerHexUid={this.props.livestream_viewer_hex_uid}/>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

ChatRoom.propTypes = {
  messages: PropTypes.array,
  postMessageUrl: PropTypes.string,
  livestream_viewer_hex_uid: PropTypes.string,
  refresh_online_list_url: PropTypes.string,
};

export default ChatRoom

