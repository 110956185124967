import _ from "underscore"
import registerView from 'lib/scope'
import AudioInput from 'components/audio_input'
import CharactersRemaining from 'lib/characters_remaining'
import ClientSideValidationView from 'components/client_side_validation_view'
import ButtonGroup from 'components/button_group'
import Tabs from 'components/tabs'
import confetti from 'canvas-confetti'
import analytics from 'lib/analytics'
import { handleEnterOnInputFields } from 'lib/form_helpers'
import Textarea from 'components/textarea'
import Cookies from 'js-cookie'

const PREFERRED_FEEDBACK_TYPE_COOKIE = "preferredFeedbackType"

const PROMPTS = [
  "What is particularly exciting or memorable about their project?",
  "Should they add or change KPIs?",
  "Does their current focus make sense? Should they focus on something else?",
  "Do you have feedback on their landing page?",
  "Do you understand their one-line description?",
  "What is confusing about their project?",
  "What questions do you have for the team?",
  "How can they make their progress update clearer?",
  "Do you have any blog posts, articles, or tools that might be helpful?",
  "Imagine you’re the target user. Would you use the product? Why or why not?",
  "Put yourself in the shoes of the founder. How would you approach their challenges?",
]

const PROMPT_DELAY = 1000 * 30 // Wait 30 sec before showing feedback prompts

// Note that order very much matters for the 3 main sections because of all the funky callback behavior
// (e.g. we can't check if the form can be submitted until syncing changes have been propagated)
registerView('.js-submitted-grant_application_matchups-_show', ($view, $$) => {
  /**** 1. Set up basic form behavior ****/

  $$('.js-text-input-with-character-limit').each((i, el) => {
    new CharactersRemaining($(el), { showCharactersUsed: true })
  })

  $$('textarea').each((i, el) => {
    new Textarea($(el))
  })

  $$('.js-audio-input').each((i, el) => {
    new AudioInput($(el))
  })

  new ClientSideValidationView($view)

  new ButtonGroup($$('.js-winner-button-group'))

  handleEnterOnInputFields($view)

  // populate prompts
  const numberOfPrompts = $$(".js-prompt").length
  const randomPrompts = _.sample(PROMPTS, numberOfPrompts)

  $$(".js-prompt").each((idx, selector) => {
    $$(selector).text(randomPrompts[idx])
  })

  $$('.js-mobile:hidden,.js-desktop:hidden').remove()
  const tabs = new Tabs($view, {
    tabScrollSyncing: true,
    tabScrollTopOffset: $('.js-mobile-sticky-nav').height() + 10,
    afterSetActiveTab: (data) => {
      analytics.track('Voting tab view', { title: data.tabTitle })
    }
  })

  $$('.js-participant-linked').each((_idx, selector) => {
    const $feedbackPrompts = $$(selector).find(".js-feedback-prompts")
    const $linkedFeedbackInput = $$(selector).find(".js-feedback-input")

    const suggestedCharacterMin = $linkedFeedbackInput.data("suggested-character-min")

    const showPrompts = () => {
      const promptsHidden = $feedbackPrompts.hasClass("d-none")
      const belowCharacterMin = $linkedFeedbackInput.val().trim().length < suggestedCharacterMin

      if (belowCharacterMin && promptsHidden) {
        $feedbackPrompts.toggleClass("d-none", false)
      }
    }

    let timeoutId

    $linkedFeedbackInput.on("blur", showPrompts)
    $linkedFeedbackInput.on("focus", () => {
      const promptsHidden = $feedbackPrompts.hasClass("d-none")

      if (promptsHidden) {
        timeoutId = setTimeout(showPrompts, PROMPT_DELAY)
      }
    })

    $linkedFeedbackInput.on("change keyup", () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }

      const promptsHidden = $feedbackPrompts.hasClass("d-none")
      if (promptsHidden) {
        timeoutId = setTimeout(showPrompts, PROMPT_DELAY)
      }
    })
  })

  var feedbackRemovedByFlagging = {}

  $$(".js-tooltip").tooltip()

  // Note that it's important that these change handlers are registered _before_ the sync ones, since
  // they need to take effect before propagating to linked participants
  $$(".js-participant-linked,.js-participant-real").each((i, el) => {
    const $participant = $(el)
    $participant.find(".js-flag-checkbox").on("change", (e) => {
      const $checkbox = $(e.currentTarget)
      const checked = $checkbox.prop("checked")
      const $feedbackInput = $participant.find(".js-feedback-input")
      const $audioFeedbackRecordingButton = $participant.find(".js-audio-input-button")
      const $audioAttachedTextInput = $participant.find(".js-audio-attached-text-input")
      const participantId = $participant.data("participant-id")

      $feedbackInput.attr("disabled", checked)
      $audioFeedbackRecordingButton.attr("disabled", checked)
      $audioAttachedTextInput.attr("disabled", checked)

      if (checked && $feedbackInput.val()) {
        feedbackRemovedByFlagging[participantId] = $feedbackInput.val()
        $feedbackInput.val("")
      } else if (!checked && feedbackRemovedByFlagging[participantId]) {
        $feedbackInput.val(feedbackRemovedByFlagging[participantId])
        delete feedbackRemovedByFlagging[participantId]
      }
    })

    // Link the toggle for selecting feedback type
    $participant.find(".js-toggle-feedback-type-link").on("click", (e) => {
      e.preventDefault();
      const $feedbackInput = $participant.find(".js-feedback-type-input");
      const newlySelectedFeedbackType = $feedbackInput.val() === "text" ? "audio" : "text";

      $feedbackInput.val(newlySelectedFeedbackType).trigger("change");
      Cookies.set(PREFERRED_FEEDBACK_TYPE_COOKIE, newlySelectedFeedbackType);
    });

    $participant.find(".js-feedback-type-input").on("change", (e) => {
      const selectedFeedbackType = $(e.currentTarget).val();
      
      // show / hide audio and text inputs as required
      const showAudioFeedbackFields = selectedFeedbackType === "audio";
      $participant.find(".js-feedback-input").toggleClass("d-none", showAudioFeedbackFields);
      $participant.find(".js-characters-remaining").toggleClass("d-none", showAudioFeedbackFields);
      $participant.find(".js-audio-input").toggleClass("d-none", !showAudioFeedbackFields).toggleClass("d-flex", showAudioFeedbackFields);
      $participant.find(".js-audio-attached-text").toggleClass("d-none", !showAudioFeedbackFields);

      // update the toggle link text
      const unselectedFeedbackType = selectedFeedbackType === "text" ? "audio" : "text";
      $participant.find(".js-toggle-feedback-type-link").html(`Switch to ${unselectedFeedbackType} feedback &raquo;`);
    })
  })

  // Propagate last chosen feedback type as the default
  const preferredFeedbackType = Cookies.get(PREFERRED_FEEDBACK_TYPE_COOKIE);
  if (preferredFeedbackType) {
    $$('.js-feedback-type-input').val(preferredFeedbackType).trigger('change');
  }

  /**** 2. Set up linked participant behavior ****/

  let syncLinkedParticipants = ($sourceParticipant, $destinationParticipant) => {
    [".js-feedback-input", ".js-flag-checkbox", ".js-audio-input", ".js-feedback-type-input", ".js-audio-attached-text-input"].forEach((klass) => {
      const $sourceInput = $sourceParticipant.find(klass)
      const $destinationInput = $destinationParticipant.find(klass)

      if ($sourceInput.is(":checkbox")) {
        $destinationInput.prop("checked", $sourceInput.prop("checked"))
      }
      else if (klass === ".js-audio-input") {
        const destinationAudioPlayer = $destinationInput.find(".js-audio-player");
        const sourceAudioPlayerSrc = $sourceInput.find(".js-audio-player").prop("src");
        const sourceAudioUUID = $sourceInput.find(".js-audio-file-field").val()
          .replace(/.*[\/\\]/, "").split(".")[0].split("_")[1];

        if (sourceAudioPlayerSrc) {
          // Update the destination audio player src
          destinationAudioPlayer.prop("src", sourceAudioPlayerSrc);
          destinationAudioPlayer.attr("controls", true);
          destinationAudioPlayer.prop("preload", "auto");
          
          // Update the destination hidden file field via a custom event
          destinationAudioPlayer.trigger("playerSrcUpdated", [sourceAudioPlayerSrc, sourceAudioUUID]);
        } else {
          // This can happen if the user has a previous recording, then records a new one less than the minumum length
          // In this case, the old recording was already removed when they clicked and confirmed "Start new recording"
          destinationAudioPlayer.removeProp("src");
          destinationAudioPlayer.removeAttr("controls");
          destinationAudioPlayer.removeProp("preload");
          destinationAudioPlayer.trigger("playerSrcUpdated", [null, null]);
        }

        // Prevent audio feedback from being flagged if the user has already recorded feedback
        if (sourceAudioUUID) {
          $sourceParticipant.find(".js-flag-checkbox").prop("disabled", true);
          $destinationParticipant.find(".js-flag-checkbox").prop("disabled", true);
        }
      }
      else {
        $destinationInput.val($sourceInput.val())
      }
      $destinationInput.trigger('change', { rootTriggeringParticipantId: $sourceParticipant[0].id })
    })
  }

  $$('.js-participant-real').each((i, el) => {
    const $realParticipant = $(el)
    const participantId = $realParticipant.data("participant-id")
    const $linkedParticipant = $$(`.js-participant-linked[data-participant-id=${participantId}]`)

    syncLinkedParticipants($realParticipant, $linkedParticipant)
    $realParticipant.find("input,select,textarea").on('change keyup', (e, opts) => {
      if (opts && opts.rootTriggeringParticipantId == $realParticipant[0].id) {
        return //this means the linked element triggered a redundant change event after synchronizing
      }
      syncLinkedParticipants($realParticipant, $linkedParticipant)
    })

    $linkedParticipant.find("input,select,textarea").on('change keyup', (e, opts) => {
      if (opts && opts.rootTriggeringParticipantId == $linkedParticipant[0].id) {
        return //this means the linked element triggered a redundant change event after synchronizing
      }
      syncLinkedParticipants($linkedParticipant, $realParticipant)
    })
  })

  /**** 3. Handle submisison behavior ****/

  const $submit = $$('.js-submit-vote')
  const refreshSubmitEnabled = () => {
    let requiredFieldsComplete = true
    const winnerId = $$('.js-winner-input').val()

    if (!winnerId) {
      requiredFieldsComplete = false
    } else {
      const $winnerParticipant = $$(`.js-participant-real[data-participant-id=${winnerId}]`)
      const $requiredFeedback = $winnerParticipant.find(".js-feedback-input")
      const flagged = $winnerParticipant.find(".js-flag-checkbox").prop("checked")
      const hasAudioFeedback = $winnerParticipant.find('.js-audio-file-field').val() !== ''
      const feedbackTypeSelected = $winnerParticipant.find(".js-feedback-type-input").val()

      const canSubmitAudio = feedbackTypeSelected === "audio" && hasAudioFeedback
      const canSubmitText = feedbackTypeSelected === "text" && $requiredFeedback.val().replace(/\s/g, '').length !== 0

      if (!canSubmitAudio && !canSubmitText && !flagged) {
        requiredFieldsComplete = false
      }
    }

    requiredFieldsComplete ? $submit.removeAttr('disabled') : $submit.attr('disabled', 'disabled')
  }

  refreshSubmitEnabled()
  const selectorsToRefreshSubmitEnabled = [
    '.js-winner-input',
    '.js-participant-real .js-feedback-input',
    '.js-participant-real .js-flag-checkbox',
    '.js-participant-real .js-audio-file-field',
    '.js-participant-real .js-feedback-type-input',
  ];
  $$(selectorsToRefreshSubmitEnabled.join(',')).on('change keyup fileInputUpdated', () => refreshSubmitEnabled())

  $submit.on("click", (e) => {
    let reachedSuggestedCharacterCount = false

    $$('.js-participant-real').each((i, el) => {
      const $el = $(el)
      const $feedbackInput = $el.find('.js-feedback-input')
      const suggestedCharacterMin = $feedbackInput.data("suggested-character-min")
      const hasAudioFeedback = $el.find('.js-audio-file-field').val() !== ''

      if ($feedbackInput.val().trim().length >= suggestedCharacterMin || hasAudioFeedback) {
        reachedSuggestedCharacterCount = true
      }
    })

    if (!reachedSuggestedCharacterCount) {
      e.preventDefault()
      $$(".js-character-min-modal").modal()
      $$(".js-voting-prompts").toggleClass("d-none", false)
    }
  })

  const $form = $$(".js-voting-form")

  $$(".js-submit-anyway").on("click", (e) => {
    $form.submit()
  })

  $form.on('ajax:error', (e) => {
    throw 'Vote form submit error'
  }).on('ajax:success', (e) => {
    $submit.remove()
    $$('form input,form button').attr('disabled', 'disabled')

    $$('.js-bonus-round-tab').removeClass('d-none')
    $$('.js-bonus-round').html(e.detail[0].html)

    let $modal = $$('.js-bonus-modal')
    $modal.modal()

    $modal.on('hide.bs.modal', () => {
      tabs.setActiveTabByIndex(parseInt($$('.js-bonus-round').data('tab')))
      $$('.js-training-highlight').addClass('text-highlight')
    })
    $modal.on('hidden.bs.modal', () => {
      if (e.detail[0].correct) {
        confetti({ angle: 70, spread: 30, origin: { x: 0.5, y: 0.5 } });
        confetti({ angle: 110, spread: 30, origin: { x: 0.5, y: 0.5 } });
      }
    })
  })
})
