import registerView from 'lib/scope'
import AdvanceToNextLevelForm from 'views/course_progression/shared/advance_to_next_level_form'
import { COMPLETED_EVENT } from "views/course_progression/shared/ics_table"

registerView('.js-course_progression-pmf-_level6', ($view, $$) => {
  let enableSubmit = false

  const shouldEnableSubmit = () => {
    return enableSubmit
  }
  const advanceToNextLevelForm = new AdvanceToNextLevelForm($$('.js-level-form'), { shouldEnableSubmit: shouldEnableSubmit })


  $$(".js-ics-table").on(COMPLETED_EVENT, () => {
    enableSubmit = true
    advanceToNextLevelForm.refreshSubmitEnabled()
  })
})