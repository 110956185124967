import registerView from "lib/scope"
import LineChart from "views/shared/ticker/line_chart"

const chartOptions = {
  scales: {
    yAxes: [
      {
        ticks: { fontColor: "#FFE44B" },
        scaleLabel: { fontColor: "#FFE44B" },
        gridLines: {
          display: true,
          color: "#324555",
          tick: [1]
        }
      }
    ],
    xAxes: [
      {
        type: "time",
        ticks: { fontColor: "#FFE44B" },
        gridLines: {
          display: true,
          color: "#324555",
          tick: [1]
        },
        scaleLabel: { fontColor: "#FFE44B" },
      }
    ]
  },
  tooltips: {
    intersect: false
  },
  spanGaps: true,
  layout: {
    padding: {
      left: 0,
      right: 10,
      top: 10,
      bottom: 0
    }
  }
}

registerView(".js-project_priority_chart", ($view, $$) => {
  const context = $$(".js-line-chart")

  if (context)  {
    new LineChart($view, context, chartOptions)
  }
})
