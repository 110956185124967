import registerView from 'lib/scope'
import AreYouSure from 'lib/are_you_sure'
import Clipboard from 'lib/clipboard'

registerView('.js-course_progression-launches-_level3', ($view, $$) => {
  const $successModal = $$('.js-level3-success-modal')
  if ($successModal.length) {
    $successModal.modal()
  }

  new Clipboard('.js-copy-email-template')

  const templateInputClassSelectors = [
    ".js-project-name-input",
    ".js-upcoming-feature-input",
    ".js-project-description-input",
    ".js-reason-input",
    ".js-first-name-input",
  ]

  function updateEmail() {
    templateInputClassSelectors.forEach(function(selector) {
      const $templateInput = $$(selector)
      const $templateInputTarget = $$($templateInput.data("target"))

      $templateInputTarget.text($templateInput.val() || $templateInput.attr("value") || `[${$templateInput.data("default")}]`)
    })

    const clipboardText = $$('.js-launcher-level3-email').text().trim().replace(/[\r\n][\r\n][\r\n]+/g, "\n\n")
    $$('.js-copy-email-template').attr('data-clipboard-text', clipboardText)
    $$('.js-copy-email-template').text('Copy')

    $$('form').map((idx, form) => {
      const $form = $$(form)
      AreYouSure.reinitialize($form)
    })
  }
  updateEmail()

  templateInputClassSelectors.forEach(function(selector) {
    $$(selector).on("change keyup", updateEmail)
  })
})
