import registerView from 'lib/scope'
import analytics from 'lib/analytics'
import AutosaveForm from 'lib/autosave_form'
import _ from 'underscore'

// TODO (Rishi) fix hard-coded params here
class PresenterViewerPointsView {
  // computePointsRemaining is a function; not sure of function-naming convention
  constructor($view, computePointsRemaining, options = {}) {
    this.$view = $view
    this.computePointsRemaining = computePointsRemaining
    this.onPointsChange = options.onPointsChange

    $view.find('.js-decrement-score').on('click', (e) => {
      this.addPoints(-10)
    })

    $view.find('.js-increment-score').on('click', (e) => {
      this.addPoints(10)
    })

    this.refreshButtonStates()
  }

  addPoints(delta) {
    let input = this.$view.find(".js-points-awarded-input")
    let oldScore = parseInt(input.val())
    let newScore = oldScore + delta

    if (newScore < 0) {
      newScore = 0
    } else if (newScore > 90) {
      newScore = 90
    }

    input.val(newScore)
    input.change()

    this.refreshButtonStates()

    if (this.onPointsChange) {
      this.onPointsChange()
    }
  }

  refreshButtonStates() {
    let input = this.$view.find(".js-points-awarded-input")
    let score = parseInt(input.val())

    this.$view.find('.js-decrement-score').removeClass('disabled')
    this.$view.find('.js-increment-score').removeClass('disabled')
    this.$view.find(".js-increment-score-wrapper").tooltip('disable')

    if (score == 0) {
      this.$view.find('.js-decrement-score').addClass('disabled')
    }

    if (this.computePointsRemaining() == 0) {
      this.$view.find('.js-increment-score').addClass('disabled')
      this.$view.find(".js-increment-score-wrapper").attr("data-original-title",
          "<div class=\"sans-serif py-1\">No points left to distribute</div>")
      this.$view.find(".js-increment-score-wrapper").tooltip('enable')
    } else if (score == 90) {
      this.$view.find('.js-increment-score').addClass('disabled')
      this.$view.find(".js-increment-score-wrapper").attr("data-original-title",
          "<div class=\"sans-serif py-1\">90 points max</div>")
      this.$view.find(".js-increment-score-wrapper").tooltip('enable')
    }
  }
}

class SubscribeView {
  constructor($view) {
    this.$view = $view

    this._$subscribeCTA().on("click", () => this._toggleSubscribeField())
    this._refreshSubscribeCTA()
  }

  _toggleSubscribeField() {
    let $subscribeField = this._$subscribeField()
    $subscribeField.val(!($subscribeField.val() === "true"))
    $subscribeField.change()
    this._refreshSubscribeCTA()
  }

  _refreshSubscribeCTA() {
    if (this._$subscribeField().val() === "true") {
      this.$view.find(".js-you-are-subscribed-text").show()
      this._$subscribeCTA().text("Unfollow")
    } else {
      this.$view.find(".js-you-are-subscribed-text").hide()
      this._$subscribeCTA().text("Follow")
    }
  }

  _$subscribeField() {
    return this.$view.find('.js-subscribed-field')
  }

  _$subscribeCTA() {
    return this.$view.find('.js-subscribe-cta')
  }
}

// Controls point percentages for _all_ presenters
class PointPercentagesView {
  constructor($view, interval = 15000) {
    this.$view = $view
    this.pointPercentagesPath = $view.data("point-percents-path")

    this.setNameWidths()

    this.refreshPointPercentages()
    setInterval(this.refreshPointPercentages.bind(this), interval);
  }

  setNameWidths() {
    // Find the widest name
    let widths = this.$view.find(".js-point-percent-name").map((i, el) => {
      return $(el).width()
    })
    let maxNameWidth = Math.max(...widths)
    let widthForPaddingAndPercentageText = 50
    this.baseWidth = maxNameWidth + widthForPaddingAndPercentageText
    this.$view.find(".js-point-percent-progress-bar").css("width", `${this.baseWidth}px`)

    this.nameContainerWidth = this.$view.find(".js-point-percent-container").first().width()
  }

  refreshPointPercentages() {
    $.ajax({
      url: this.pointPercentagesPath
    }).done(((data, textStatus, jqXHR) => {
      let maxPercent = 0
      for (let viewerId in data) {
        if (data.hasOwnProperty(viewerId)) {
          let percent = data[viewerId]
          if (percent > maxPercent) {
            maxPercent = percent
          }
        }
      }

      for (let viewerId in data) {
        if (data.hasOwnProperty(viewerId)) {
          let percent = data[viewerId]
          let $pointPercentageContainer =
              this.$view.find(`.js-presenter-row[data-presenter-id="${viewerId}"] .js-point-percent-container`)

          $pointPercentageContainer.data("percent", percent)
          $pointPercentageContainer.find(".js-point-percent-value").text(`${Math.round(percent * 100)}%`)

          let normalizedPercent = percent / maxPercent
          let newWidth = this.baseWidth + (this.nameContainerWidth - this.baseWidth) * normalizedPercent - 10 // padding
          $pointPercentageContainer.find(".js-point-percent-progress-bar").width(newWidth)
        }
      }
    }).bind(this))
  }
}

class PointsRemainingView {
  constructor($view) {
    this.$view = $view
    this.$pointsRemainingView = this.$view.find(".js-points-remaining")
    this.refresh()
  }

  computePointsAwarded() {
    let pointsAwarded = this.$view.find(".js-points-awarded-input").map((i, el) => {
      return parseInt($(el).val())
    })
    return _.reduce(pointsAwarded, ((sum, p) => sum + p), 0)
  }

  computePointsRemaining() {
    return parseInt(this.$pointsRemainingView.data("max")) - this.computePointsAwarded()
  }

  refresh() {
    this.$pointsRemainingView.text(this.computePointsRemaining())
  }
}

class MailingListSubscribeView {
  constructor($view) {
    this.$view = $view

    this._$mailingListCta().on("click", this._onClickSubscribe.bind(this))
  }

  _onClickSubscribe(e) {
    e.preventDefault()

    $.post({
      url: this._$mailingListCta().data("path")
    }).done((data, textStatus, jqXHR) => {
      this._$mailingListCta().addClass("d-none")
      this.$view.find(".js-after-subscription").removeClass("d-none")
    })
  }

  _$mailingListCta() {
    return this.$view.find(".js-mailing-list-subscribe-cta")
  }
}

registerView('.js-livestreams-watch', ($view, $$) => {
  analytics.track('Viewed livestreams-watch')
  $$('.js-tooltip').tooltip({ delay:  { 'show': 500, 'hide': 0 }, trigger: 'hover' })

  let pointPercentagesView = new PointPercentagesView($view)
  // TODO (Rishi) This is still a little buggy
  let debouncedPointsRefresh = _.debounce(() => {
    pointPercentagesView.refreshPointPercentages()

    // Do an extra one in a few seconds since we've got a few sources of lag: form save throttling & server-side caching
    setTimeout(pointPercentagesView.refreshPointPercentages.bind(pointPercentagesView), 4000)
  }, 1000)

  let pointsRemainingView = new PointsRemainingView($view)

  let allPresenterViewerPointsViews =
    $$('.js-points-awarded-input-container').map((i, el) => {
      return new PresenterViewerPointsView(
          $(el),
          pointsRemainingView.computePointsRemaining.bind(pointsRemainingView),
          {
            onPointsChange: () => {
              debouncedPointsRefresh()
              pointsRemainingView.refresh()

              allPresenterViewerPointsViews.forEach((presenterViewerPointsView) => {
                presenterViewerPointsView.refreshButtonStates()
              })
            }
          }
      )
    }).toArray()

  $$('.js-subscribe-toggle-container').each((i, el) => {
    new SubscribeView($(el))
  })

  new AutosaveForm($$('form.js-autosave'), 3000)

  let refreshActivePresenter = () => {
    $.ajax({
      url: $view.data("active-presenter-path")
    }).done(((data, textStatus, jqXHR) => {
      $$(".js-presenter-row").removeClass("presenter-row--active")

      let $activePresenterRow = $$(`.js-presenter-row[data-presenter-id="${data.id}"]`)
      $activePresenterRow.addClass("presenter-row--active")

      let $upcomingPresenters = $$(".js-upcoming-presenters-table tbody")
      let $previousPresenters = $$(".js-previous-presenters-table tbody")


      let sortedRows = $$(".js-presenter-row").sort((row1, row2) => {
        return $(row1).data('order') - $(row2).data('order')
      })

      let activePresenterShowOrder = null;
      if ($activePresenterRow.length) {
        activePresenterShowOrder = $activePresenterRow.data("order")
      }

      sortedRows.each((i, row) => {
        let $row = $(row)
        if (!activePresenterShowOrder || $row.data('order') >= activePresenterShowOrder) {
          $row.detach().appendTo($upcomingPresenters)
        } else {
          $row.detach().appendTo($previousPresenters)
        }
      })

      $$(".js-previous-presenters-title").toggle(!!$previousPresenters.find("tr").length)
    }))
  }
  refreshActivePresenter()
  setInterval(refreshActivePresenter.bind(this), 15000);

  new MailingListSubscribeView($$(".js-mailing-list-subscription"))
})
