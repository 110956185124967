// Modifies default enter behavior on input & select fields such that:
// - if there is a next field to jump to, the cursor moves to it
// - if not, the form submits (default behavior)
export const handleEnterOnInputFields = function($view) {
  $view.find('input,select').on('keypress', (e) => {
    if ((e.which || e.keyCode) == 13) {
      let $inputs = $view.find('input:visible:enabled,select:visible:enabled,textarea:visible:enabled')
      let nextIndex = $inputs.index(e.currentTarget) + 1

      if (nextIndex < $inputs.length) {
        let $nextInput = $($inputs.get(nextIndex))
        $nextInput.focus()
        e.preventDefault()
      }
    }
  })
}
