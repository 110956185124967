import registerView from "lib/scope"
import AdvanceToNextLevelForm from 'views/course_progression/shared/advance_to_next_level_form'
import DatePicker from "views/course_progression/shared/date_picker"

registerView(".js-course_progression-pmf-_level7", ($view, $$) => {
  const maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() + 2)

  const datePicker = new DatePicker($view, { maxDate })

  const shouldEnableSubmit = ($form) => {
    if (!AdvanceToNextLevelForm.defaultShouldEnableSubmit($form)) {
      return false
    }
    // Ensure the date is not in the past, not in future past two months
    return datePicker.isDateInValidRange()
  }

  new AdvanceToNextLevelForm($$(".js-level-form"), { shouldEnableSubmit: shouldEnableSubmit })
})
