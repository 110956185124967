import registerView from 'lib/scope'
import Countdown from 'lib/countdown'
import analytics from "lib/analytics"

registerView('.js-forum-posts-show', ($view, $$) => {
  analytics.track("[Frontier] Viewed post", { postId: $view.data("post-hex-uid"), challenge: $view.data("challenge") })

  const hash = window.location.hash;
  if (hash.length > 0) {
    const $replyLink = $$(`${hash} .js-reply-to-comment`)
    if ($replyLink.length > 0) {
      $replyLink.click()
      $$(".js-forum-posts-_shared_reply_to_comment .js-comment-input").focus()
    }
  }

  new Countdown($$('.js-post-countdown'))
})
