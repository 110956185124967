import * as React from "react"
import { Winner, RouteTemplates } from "./Index"
import WinnerCard from "./index/WinnerCard"
import TwitterBird from "-!svg-react-loader!images/icons/twitter-bird.svg"

export interface ShowProps {
  winner: Winner
  route_templates: RouteTemplates
}

export interface ShowState {
}

export default class Show extends React.Component<ShowProps, ShowState> {
  constructor(props: ShowProps) {
    super(props)

    this.state = {
    }
  }

  render() {
    let teammates_section = null

    if (this.props.winner.teammates.length > 0) {
      const rendered_teammates = this.props.winner.teammates.map((teammate) => {
        return (
          <div
            key={teammate.id}
            className="col-xl-6 pr-xl-3 col-12 pb-3">
            <WinnerCard
              showOneLiner={false}
              routeTemplates={this.props.route_templates}
              winner={teammate} />
          </div>
        )
      })

      teammates_section = (
        <div className="teammates">
          <div className="text-transform--uppercase font-weight-bold mt-4 text-small mb-2">
            Teammates
          </div>
          <div className="row no-gutters mb-4">
            {rendered_teammates}
          </div>
        </div>
      )
    }

    const oneLiner = this.props.winner.one_liner
    let unpuncuatedOneLiner = null
    if (oneLiner.substr(oneLiner.length - 1) == ".") {
      unpuncuatedOneLiner = oneLiner.substr(0, oneLiner.length - 1)
    } else {
      unpuncuatedOneLiner = oneLiner
    }

    const projectUrl = this.props.winner.project_url
    let renderedProjectUrl = null
    if (projectUrl) {
      renderedProjectUrl =
        <a href={projectUrl} className="serif" target="_blank">
          {projectUrl}
        </a>
    }

    const livestreamPresentationUrl = this.props.winner.embeddable_livestream_presentation_url
    let renderedLivestreamPresentation = null
    if (livestreamPresentationUrl) {
      renderedLivestreamPresentation =
        <div className="mt-4">
          <div className="text-transform--uppercase font-weight-bold mt-4 text-small">
            Demo Livestream Presentation
          </div>
          <iframe
            src={livestreamPresentationUrl}
            frameBorder="0"
            allowFullScreen={true}
            width="100%"
            height="300px"
            className="pt-2"
            >

          </iframe>
        </div>
    }

    let renderedTwitterUrl = null
    let twitterUrl = this.props.winner.twitter_url
    if (twitterUrl) {
      renderedTwitterUrl = (
        <span>
          &nbsp;&middot;&nbsp;
          <a href={this.props.winner.twitter_url} target="_blank">
            <TwitterBird />
          </a>
        </span>
      )
    }

    const renderedImage = (
      <img
        className="border border-black winner-img w-100"
        src={this.props.winner.photo_url}
      />
    )

    return (
      <div className="winner_team_members-show">
        <div className="container pt-md-4 d-flex align-items-start flex-wrap px-0 px-sm-3">
          <div className="back-arrow-column d-none d-sm-block pt-3">
            <a href="/founders">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25 12.3889H3M3 13.4889L13.0435 2M3 11.2889L13.0435 22.7778" stroke="#172a3b" strokeWidth="4"/>
              </svg>
            </a>
          </div>
          <div className="image-column d-none d-sm-block pr-1">
            {renderedImage}
          </div>
          <div className="content-column pt-2 pl-sm-4 pr-sm-0 px-3 pb-5 pb-sm-5">
            <a href="/founders" className="text-gray-5 d-flex align-items-center mb-2 text-small d-sm-none">
              <span className="">
                « Meet the Pioneers
              </span>
            </a>
            <div className="content-header d-flex">
              <div className="content-col-img d-block d-sm-none">
                {renderedImage}
              </div>
              <div className="content-col-heading pl-3 pl-sm-0">
                <div className="mb-0 winner-name">
                  {this.props.winner.full_name}
                </div>
                <div className="subtitle">
                  {this.props.winner.age}
                  &nbsp;&middot;&nbsp;
                  {this.props.winner.location_description}
                  {renderedTwitterUrl}
                </div>
              </div>
            </div>
            <p
              className="serif bio"
              dangerouslySetInnerHTML={{ __html: this.props.winner.bio}}>
            </p>
            <h5 className="one-liner font-weight-bold mb-0">
              {unpuncuatedOneLiner}
            </h5>
            {renderedProjectUrl}
            <div
              className="serif project-summary mt-2"
              dangerouslySetInnerHTML={{ __html: this.props.winner.project_summary}}>
            </div>
            {renderedLivestreamPresentation}
            {teammates_section}
          </div>
        </div>
      </div>
    )
  }
}
