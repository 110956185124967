import registerView from 'lib/scope'
import Tabs from "components/tabs"

registerView(".js-example-progress-updates", ($view, $$) => {
  $$('.js-mobile:hidden,.js-desktop:hidden').remove()
  new Tabs($view, {
    tabScrollSyncing: true,
    tabScrollTopOffset: $('.js-mobile-sticky-nav').height() + 10
  })
})
