import * as React from "react"
import * as _ from "underscore"
import flatEarthImage from "images/views/winner_team_members/flat-earth.png"
import flatEarthImage2x from "images/views/winner_team_members/flat-earth@2x.png"
import { HeroMapPin } from "./HeroMapPin"
import {Winner, WinnerLocation} from "../Index"

interface HeroProps {
  winners: Winner[]
  winners_subtitle: string
  highlightMapLocation: WinnerLocation
  onFilterWinnerGridIdsChange: (filterWinnerGridIds: number[]) => void
}

interface HeroState {
  clickedPin: HeroMapPin
  filterWinnerGridIds: number[]
}

export default class Hero extends React.Component<HeroProps, HeroState> {
  constructor(props: HeroProps) {
    super(props)

    this.state = {
      clickedPin: null,
      filterWinnerGridIds: null
    }

    this.onPinClick = this.onPinClick.bind(this)
  }

  onPinClick(pin: HeroMapPin, event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    if (pin === this.state.clickedPin) {
      this.setState((state, props) => { return { clickedPin: null } })
      this.props.onFilterWinnerGridIdsChange([])
    } else {
      this.setState((state, props) => { return { clickedPin: pin } })
      this.props.onFilterWinnerGridIdsChange(pin.props.winners.map((winner) => winner.id))
    }
  }

  render() {
    const locationStringsToWinners = _.groupBy(this.props.winners, (winner) => {
      if (winner.location) {
        // Convert to a type for which JS naturally defines equality the way we want
        return `${winner.location.x}x${winner.location.y}`
      } else {
        return ""
      }
    })
    delete locationStringsToWinners[""]

    let mapPins =
      _.values(_.mapObject(locationStringsToWinners, (winnersInSameLocation, locationString) => {
        const location = winnersInSameLocation[0].location
        const winnerCardHighlighted =
          this.props.highlightMapLocation &&
          this.props.highlightMapLocation.x === location.x &&
          this.props.highlightMapLocation.y === location.y

        return (
          <HeroMapPin
            key={locationString}
            selected={this.state.clickedPin && locationString === this.state.clickedPin.props.locationString}
            winnerCardHighlighted={winnerCardHighlighted}
            onClick={this.onPinClick}
            location={location}
            locationString={locationString}
            winners={winnersInSameLocation}
          />
        )
      }))

    return (
      <div className="winner_team_members-index-_hero js-flat-earth-hero bg-black position-relative">
        <div className="flat-earth-title d-flex align-items-center justify-content-center position-absolute h-100 w-100">
          <div className="text-center heading-container text-white px-3">
            <div className="hero-heading-block mx-auto py-2 px-3">
              <h1 className="hero-h1 mb-0">
                Meet the Pioneers
              </h1>
              <p className="hero-subtitle mx-auto">
                {this.props.winners_subtitle}
              </p>
            </div>
          </div>
        </div>
        <div className="flat-earth mx-auto text-white position-relative text-center">
          <div className="d-none d-md-block">
          { mapPins }
          </div>
          <img
            srcSet={`${flatEarthImage2x} 2x`}
            src={flatEarthImage}
          />
        </div>
      </div>
    )
  }
}
