import registerView from 'lib/scope'
import Tabs from 'components/tabs'

registerView('.js-applicants-grant_applications-_global_and_regional_leaderboards', ($view, $$) => {
  new Tabs($view, { disableUrlHash: true })

  // load the badge tooltips once the data has loaded asynchronously
  $view.on('tabLoaded', (_event, tab) => {
    const $loadedTab = $view.find(`.js-tab-content[data-tab=${tab}]`);
    // we have to wait until the tab is loaded, or else there are no '.js-tooltip' badge elements to find
    $loadedTab.find('.js-tooltip').tooltip({ delay: { 'show': 100, 'hide': 0 }, trigger: 'hover' });
  })
})
