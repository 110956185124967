import registerView from 'lib/scope'

registerView('.js-home-landing_v3-_testimonials', ($view, $$) => {
  $(window).on("load", () => {
    const $quotesContainer = $$(".js-quotes-container")

    const originalHeight = $quotesContainer.height()
    const collapsedHeight = originalHeight / 2

    $quotesContainer.height(collapsedHeight)
    var isCollapsed = true

    $quotesContainer.addClass("uses-animation")

    $$(".js-toggle-quotes").on("click", (e) => {
      if (isCollapsed) {
        $quotesContainer.height(originalHeight)
      } else {
        $quotesContainer.height(collapsedHeight)
      }

      $$(".js-collapsed-gradient").toggleClass("invisible", isCollapsed)
      $$(".js-see-more").toggleClass("d-none", isCollapsed)
      $$(".js-see-less").toggleClass("d-none", !isCollapsed)

      isCollapsed = !isCollapsed
    })
  })
})
