import registerView from "lib/scope"
import AdvanceToNextLevelForm from "views/course_progression/shared/advance_to_next_level_form"

registerView(".js-course_progression-pmf-_level1", ($view, $$) => {
  const $productNameInput = $$(".js-product-name-input")

  new AdvanceToNextLevelForm($$(".js-level-form"))

  function updateProductName() {
    $$($productNameInput.data("target")).each((i, el) => {
      const $templateInputTarget = $(el)
      $templateInputTarget.text($productNameInput.val() || `${$templateInputTarget.data("default")}`)
    })
  }

  updateProductName()
  $productNameInput.on("change keyup", updateProductName)
})

