import OtherInput from 'components/other_input'

export default class Checklist {
  constructor($view, options) {
    this.$view = $view
    if (options.maxCheckboxes) {
      this.maxCheckboxes = options.maxCheckboxes
    }

    let $otherInputContainer = this.$view.find('.js-other-input-container')
    if ($otherInputContainer.length) {
      this.otherInput = new OtherInput($otherInputContainer)
    }

    this.refresh()
    this.$checkboxes().on('change', () => this.refresh())
  }

  $checkboxes(options = {}) {
    let $checkboxes = this.$view.find('input[type=checkbox]')

    if ('checked' in options) {
      return $checkboxes.filter((i, checkbox) => {
        return $(checkbox).prop('checked') === options.checked
      })
    } else {
      return $checkboxes
    }
  }

  refresh() {
    this._refreshDisabled()
    this._refreshOtherInput()
  }

  _refreshOtherInput() {
    if (!this.otherInput) {
      return
    }

    let otherCheckboxChecked = this.$view.find('.js-other-checkbox').prop('checked')
    this.otherInput.toggle(otherCheckboxChecked)
  }

  _maxCheckboxesSelected() {
    if (this.maxCheckboxes) {
      let checkedCheckboxes = this.$checkboxes({ checked: true })
      return checkedCheckboxes.length >= this.maxCheckboxes
    } else {
      return false;
    }
  }

  _refreshDisabled() {
    let disabled = this._maxCheckboxesSelected()
    return $(this.$checkboxes({ checked: false })).prop('disabled', disabled)
  }
}
