import registerView from 'lib/scope'

registerView('.js-applicants-home-up_next', ($view, $$) => {
  $$('.js-low-priority').hide()
  $$('.js-toggle-low-priority-items').on('click', () => {
    $$('.js-low-priority').slideToggle(200, () => {
      if ($$('.js-low-priority').is(':visible')) {
        $$('.js-toggle-low-priority-items').text('See fewer')
      } else {
        $$('.js-toggle-low-priority-items').text('See more')
      }
    })
  })
})
