import OtherInput from 'components/other_input'

export default class ButtonGroup {
  constructor($buttonGroup, options) {
    this.$view = $buttonGroup
    this.$view.find('.js-button-group-option').on('click', (e) => this.onClick(e))
    this.$hiddenInput = this.$view.find('.js-button-group-input')

    let defaults = {
      allowUnclickOnSelectedOption: true,
    }
    this.options = Object.assign({}, defaults, options);

    let $otherInputContainer = this.$view.find('.js-other-input-container')
    if ($otherInputContainer.length) {
      this.otherInput = new OtherInput($otherInputContainer)
    }

    this.render()
    this.$hiddenInput.on('change', () => {
      this.render()
    })
  }

  onClick(e) {
    let $target = $(e.currentTarget)
    if ($target.hasClass('disabled')) { return }

    this._updateSelection($target.val())
  }

  render() {
    let value = this.$hiddenInput.val()
    let $previousSelection = this.$view.find('.js-button-group-option.selected')
    $previousSelection.removeClass('selected')

    let $selected = this.$view.find(`.js-button-group-option[value="${value}"]`)
    $selected.addClass('selected')
    this._refreshOtherInput($selected)
  }

  _refreshOtherInput($selectedOption) {
    if (!this.otherInput) {
      return
    }

    this.otherInput.toggle($selectedOption.hasClass('js-other'))
  }

  _updateSelection(newValue) {
    let oldValue = this.$hiddenInput.val()
    if (oldValue == newValue) {
      if (this.options.allowUnclickOnSelectedOption) {
        this.$hiddenInput.val(null).change()
      }
    } else {
      this.$hiddenInput.val(newValue).change()
    }
  }
}
