import _ from 'underscore'

export default class AutosaveForm {
  constructor($form, autosaveInterval = 1000) {
    this.$form = $form
    $form.find('input,select').on('change', (e) => this.formChanged())
    $form.find('textarea').on('change keyup', (e) => this.formChanged())
    $form.on('submit', () => { this.cancelPendingSave() })

    this.throttledSave = _.throttle(() => this._save(), autosaveInterval)
  }

  formChanged() {
    if (this.activeRequest) {
      this.waitingToSave = true
    } else {
      this.throttledSave()
    }
  }

  cancelPendingSave() {
    this.waitingToSave = false
  }

  _save() {
    this.activeRequest =
        $.ajax({
          url: this.$form.prop('action'),
          method: this.$form.prop('method'),
          data: this.$form.serialize(),
        }).done((data, textStatus, jqXHR) => {
          this._requestFinished(jqXHR)
        }).fail((jqXHR, textStatus, errorThrown) => {
          this._requestFinished(jqXHR)
        })
  }

  _requestFinished() {
    this.activeRequest = null

    if (this.waitingToSave) {
      this.throttledSave()
      this.waitingToSave = false
    }
  }
}
