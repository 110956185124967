import registerView from "lib/scope"
import AreYouSure from 'lib/are_you_sure'
import Clipboard from 'lib/clipboard'

registerView(".js-course_progression-pmf-_level3", ($view, $$) => {
  const $successModal = $$(".js-level3-success-modal")
  if ($successModal.length) {
    $successModal.modal()
  }

  new Clipboard('.js-copy-email-template')

  function updateEmail() {
    const clipboardText = $$(".js-pmf-level3-email").val().trim()

    $$(".js-l3-email-body").val(clipboardText)
    $$('.js-copy-email-template').attr('data-clipboard-text', clipboardText)
    $$('.js-copy-email-template').text('Copy')
  }

  updateEmail()
  $$(".js-pmf-level3-email").on("input propertychange", updateEmail)

  // Templating email shouldn't ask user to "save progress"
  AreYouSure.disable($view)
})
