export default class OtherInput {
  constructor($view) {
    this.$view = $view
  }

  toggle(show) {
    if (!show) {
      this.$view.find('input[type="text"]').val('')
    }
    this.$view.toggleClass('d-none', !show)
  }
}
