import registerView from 'lib/scope'
import Countdown from 'lib/countdown'
import analytics from 'lib/analytics'
import Tabs from 'components/tabs'

import _ from 'underscore'
import Cookies from 'js-cookie'

const APPLICANT_HOMEPAGE_SAVED_TAB_NAME_COOKIE = 'applicantHomepageSavedTabname'

registerView('.js-applicants-home-index', ($view, $$) => {
  analytics.track('Viewed applicant homepage')

  let $countdowns = $$('.js-deadline-countdown')
  _.each($countdowns, (el) => {
    new Countdown($(el), { alwaysShowDays: false, alwaysShowHours: false })
  })

  $$('.js-stripe-modal').modal()
  $$('.js-share-progress-update-modal').modal()
  $$('.js-fast-track-review-incoming-modal').modal()

  if ($view.data("show-fast-track-modal") == "1") {
    $$('.js-fast-track-modal').modal()
  }

  let savedTabname = Cookies.get(APPLICANT_HOMEPAGE_SAVED_TAB_NAME_COOKIE)
  let tabs = new Tabs($$('.js-applicants-home-_leaderboards'), {
    initialTabname: savedTabname,
    afterSetActiveTab: (data) => {
      if (data.tabname) {
        Cookies.set(APPLICANT_HOMEPAGE_SAVED_TAB_NAME_COOKIE, data.tabname, { expires: 30 })
      }
    }
  })
})
