export default class DateSelect {
  constructor($view) {
    this.$monthSelect = $view.find('.js-month-select')
    this.$daySelect = $view.find('.js-day-select')
    this.$monthSelect.on('change', () => this._refreshDaySelect())

    this._refreshDaySelect()
  }

  _refreshDaySelect() {
     // TODO: number of days depending on month
  }
}
