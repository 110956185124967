import 'jquery.are-you-sure'

export default class AreYouSure {
  constructor($$) {
    this.$$ = $$

    this.initialize()
    this.addEventListeners()
  }

  initialize() {
    // event listeners persist through pages, sometimes
    $(document).off("page:before-change turbolinks:before-visit")

    this.$$('form').map((idx, form) => {
      const $form = $(form)
      $form.areYouSure({message: "You have unsaved changes. Leave anyway?"})
    })
  }

  static reinitialize($form) {
    $form.trigger('reinitialize.areYouSure')
  }

  static disable($scope) {
    $scope.on('change keyup propertychange input', () => {
      $scope.find('form').map((_idx, form) => {
        $scope.find(form).trigger('reinitialize.areYouSure')
      })
    })
  }

  addEventListeners() {
    // Turbolinks 4 and 5 handling of beforeunload
    $(document).on("page:before-change turbolinks:before-visit", () => {
      if (this.$$('form').hasClass('dirty')) {
        return confirm("You have unsaved changes. Leave anyway?");
      }
    });

    $(document).on("turbolinks:visit", () => {
      $(document).off("page:before-change turbolinks:before-visit")
    })

    //reinit forms that submit remotely
    this.$$('form').map((idx, form) => {
      const $form = $(form)

      $form.on("ajax:success", () => {
        AreYouSure.reinitialize($form)
      });
    })
  }
}
