export default class BooleanInput {
  constructor($container, trueValue) {
    this.$container = $container
    this.$hiddenField = $container.find(".js-hidden-field")
    this.trueValue = trueValue
    this.refresh()
  }

  value() {
    return this.$hiddenField.val();
  }

  setValue(newValue) {
    this.$hiddenField.val(newValue);
    this.refresh()
  }

  refresh() {
    this.$container.toggleClass("boolean-input--active", this.value() === this.trueValue)
  }
}
