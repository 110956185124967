import { SELECT, DESELECT, TIME_FILTER, HOVER, HOVER_EXIT } from "./events"

// Listens for events from TickerEventRouter
// Emits requests to TickerEventRouter.
// Ticker event router will handle side effects and routing requests to other TickerEventNodes //
// To add a new event, add it to event.js file, add triggerFn, add on<Event> fn
export default class TickerEventNode {
  constructor($view, config) {
    this.$view = $view

    this.initializeEventListeners()
  }

  initializeEventListeners() {
    this.$view.on(SELECT.adultEvent, (event, eventData) => {
      this.onSelect(event, eventData)
    })

    this.$view.on(DESELECT.adultEvent, (event, eventData) => {
      this.onDeselect(event, eventData)
    })

    this.$view.on(TIME_FILTER.adultEvent, (event, eventData) => {
      this.onTimeFilter(event, eventData)
    })

    this.$view.on(HOVER.adultEvent, (event, eventData) => {
      this.onMainChartHover(event, eventData)
    })

    this.$view.on(HOVER_EXIT.adultEvent, (event, eventData) => {
      this.onMainChartHoverExit(event, eventData)
    })
  }

  triggerSelect(eventData) {
    this.$view.trigger(SELECT.childEvent, [eventData])
  }

  triggerDeselect(eventData) {
    this.$view.trigger(DESELECT.childEvent, [eventData])
  }

  triggerTimeFilter(eventData) {
    this.$view.trigger(TIME_FILTER.childEvent, [eventData])
  }

  triggerMainChartHover(eventData) {
    this.$view.trigger(HOVER.childEvent, [eventData])
  }

  triggerMainChartHoverExit() {
    this.$view.trigger(HOVER_EXIT.childEvent, [])
  }

  /*
    eventData: {
      symbol: string,
      color: string,
      tableData: TableData (ticker_table.js)
   */
  onSelect(event, eventData) {
  }

  /*
    eventData: {
      symbol: string,
   */
  onDeselect(event, eventData) {
  }

  /*
    eventData: {
      lookbackDuration: number,
      start: number,
      end: number,
      displayName: string,
   */
  onTimeFilter(event, eventData) {
  }

  /*
    eventData: {
      activePoint: ChartElement
    }
   */
  onMainChartHover(event, eventData) {
  }

  /*
    eventData: {}
   */
  onMainChartHoverExit(event, eventData) {
  }
}

