import registerView from 'lib/scope'
import Clipboard from 'lib/clipboard'
import Countdown from 'lib/countdown'

registerView('.js-applicants-home-_leaderboard', ($view, $$) => {
  let rankValueWidth = $$('.js-rank-value').outerWidth()

  let $scoreBreakdown = $$('.js-score-breakdown')
  $scoreBreakdown.css('padding-left', rankValueWidth)

  new Clipboard('.js-copy-invite-link')

  let $countdown = $$('.js-tournament-start-countdown')
  new Countdown($countdown, { alwaysShowDays: false })

  $$('.js-tooltip').tooltip({ delay: { 'show': 100, 'hide': 0 }, trigger: 'hover' })
})
