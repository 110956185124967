import registerView from 'lib/scope'

registerView('.js-home-landing_v3-_feedback', ($view, $$) => {
  $$('textarea').each((i, el) => {
    new Textarea($(el))
  })

  const animateFeedbackRow = ($feedbackRow, onCompletion) => {
    const $content = $feedbackRow.find(".js-content")
    const $fakeButton = $feedbackRow.find(".js-fake-button")

    $content.text("")
    $fakeButton.html("&nbsp;")

    $feedbackRow.addClass("looks-editable")
    $feedbackRow.slideDown(75)

    const feedback = $content.data("feedback")

    const start = Date.now()
    const renderCharacters = () => {
      const elapsedTime = Date.now() - start
      const nCharactersToRender = Math.ceil(elapsedTime / 25)
      $content.text(feedback.substring(0, nCharactersToRender))

      if (nCharactersToRender < feedback.length) {
        window.requestAnimationFrame(renderCharacters)
      } else {
        $feedbackRow.removeClass("looks-editable")
        $fakeButton.text("Reply")
        setTimeout(onCompletion, 500)
      }
    }

    window.requestAnimationFrame(renderCharacters)
  }

  let simulatedConversationRows = $$(".js-simulated-conversation .js-feedback-row")
  const $firstTextareaRow = $(simulatedConversationRows.get(0))
  const $secondTextareaRow = $(simulatedConversationRows.get(1))

  // Set heights to prevent stuttering before we kick off the animations
  const feedbackExamples = $$(".js-feedback-examples")
  feedbackExamples.css("min-height", feedbackExamples.outerHeight())

  const simulatedConversationContents = $$(".js-simulated-conversation .js-content")
  simulatedConversationContents.each((i, el) => {
    const $content = $(el)
    $content.css("min-height", $content.outerHeight())
  })

  const rerunAllAnimations = () => {
    const rows = [
      $firstTextareaRow,
      $secondTextareaRow,
    ]
    rows.forEach((row) => {
      row.css("display", "none")
    })

    animateFeedbackRow($firstTextareaRow, () => {
      animateFeedbackRow($secondTextareaRow, () => {
        setTimeout(rerunAllAnimations, 3000)
      })
    })
  }

  rerunAllAnimations()
})

