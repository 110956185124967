import registerView from 'lib/scope'

// We ask users how they heard about Pioneer -- but how to aggregate and analyze these?
// We've got a bunch of free-form one-liners.
//
// Given an array of {text: "Some reason", user: "username"} objects, we want to:
//
// * Run a collection of regular expression searches on the text to identify recurring
//   themes. They read an article, they saw it on Twitter, they heard about it from a
//   friend, they listened to a podcast, and so on.
//
// * Tag these signup reasons accordingly. "Tweet from Tyler Cowen", for example, could
//   get tagged with "twitter" and "cowen".
//
// * For each tag, show the signup reasons tagged with it and the distribution of other
//   tags that overlapped with it.
//
// The way to edit the tags is here. A few lines down, there'll be a TAG DEFINITIONS
// section. Edit that, reload the page, and watch as your new tags are applied.

registerView('.js-admins-applicant_referrals-explorer', function($view, $$) {
  Array.prototype.sortBy = function(fn) {
    return this.sort(function(a, b) {
      let fna = fn(a), fnb = fn(b);
      if (fna < fnb) return -1;
      if (fna > fnb) return 1;
      return 0;
    });
  }

  // A tag matches if one or more of its regular expressions match.
  let TAGS = []
  function deftag(tagname, ...regexes) {
    let saw_regex = false;
    for (let r of regexes) {
      TAGS.push([tagname, r])
      saw_regex = true;
    }
    if (!saw_regex) {
      TAGS.push([tagname, tagname]);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  // TAG DEFINITIONS: Eyeball your data, edit these, hit reload.
  ////////////////////////////////////////////////////////////////////////////////

  // Each tag is defined by one or more strings or regular expressions. Whenever one of
  // those strings is found, or one of those regular expressions matches, the tag is
  // applied. For example, this one tags the case-insensitive strings "ai grant" and
  // "aigrant" with the tag "aigrant":

  deftag('aigrant', /ai[. ]?grant/i);

  // You can typically figure these out just by eyeballing the section of things that aren't
  // tagged and asking "what should be tagged here?" Then just hit reload.

  deftag('article', /(?:a|the) news|article/i);
  deftag('balaji', /balajis?|srinivasan/i);
  deftag('blog', /blog/i);
  deftag('collison', /collison|patrickc|stripe/i);
  deftag('cowen', /cowen|conversations with tyler|marginal ?revolution/i);
  deftag('daniel', /\bdaniel\b|dcgross|danicgross|\bgross\b/i);
  deftag('email', /e-?mail/i);
  deftag('facebook', /(?:facebook|\bfb\b)(?: group)?/i);
  deftag('friend', /friends?|word of mouth|co[- ]?worker|a(?:nother)? pioneer|colleague|mentor|brother|sister|daughter|\bson\b|family|spouse/i);
  deftag('internet', /\b(?:online|internet|google|bing|duckduckgo|social media|web ?site)\b/i);
  deftag('lambda', /lambda|tommy ?collison/i);
  deftag('linkedin', /linkedin/i);
  deftag('news', /ny ?times|new york times|\bnyt\b|washington post|wapo|wired|economist|cnn|the atlantic|news-?letter/i);
  deftag('nytimes', /ny ?times|new york times|\bnyt\b/i);
  deftag('podcast', /podcast/i);
  deftag('producthunt', /product ?hunt/i);
  deftag('rishi', /rishi|narang|rnarang/i);
  deftag('talk', /talk/i);
  deftag('telegram', /telegram/i);
  deftag('twitter', /twitter|^(?:@\w+)$|tweet/i);
  deftag('yc',
         /y[ -]?combinator|hacker ?news|startup school|\byc\b/i,
         /\bhn\b/i);
  deftag('youtube', /youtube|\byt\b/i);
  deftag('opportunitydesk', /opportunity ?desk/i);
  deftag('indiehackers', /indie ?hackers/i);
  deftag('leap', /\bleap\b/i);

  // For convenience, if you just say a tag name it'll look for the name of the tag. Like,
  // `deftag("foo")` will tag anything containing the substring "foo" with the tag "foo".

  deftag('bloomberg');
  deftag('instagram');
  deftag('medium');
  deftag('paystack');
  deftag('podcast');
  deftag('reddit');
  deftag('talk');
  deftag('wired');
  deftag('1517');
  deftag('slack');
  deftag('forbes');

  ////////////////////////////////////////////////////////////////////////////////
  // TAGGING ENGINE: How the tagger actually does its thing
  ////////////////////////////////////////////////////////////////////////////////

  // We first preprocess the reasons. This takes an array and returns a new array, but
  // modifies the elements of the array. Take heed.
  function preprocessReasons(reasons) {
    let usersSeen = new Set();
    let result = [];
    for (let reason of reasons) {
      if (reason.user) {
        if (usersSeen.has(reason.user)) continue;
        usersSeen.add(reason.user);
      }
      if (typeof(reason.text) !== "string") {
        console.log("Invalid entry:", reason);
        continue;
      }
      reason.text = reason.text.replace(/\s+/g, ' ').trim();
      reason.user = reason.user || null;
      reason.created_at *= 1000; // Convert seconds (from Ruby) to milliseconds (JS)
      let m = reason.text.match(/^Referred by (.*)$/);
      if (m) {
        reason.referrer = m[1];
      }
      result.push(reason);
    }
    return result;
  }

  // Get tags, sorted, present in text.
  function tagsInText(text) {
    text = text.toLowerCase();
    let tags = new Set();
    for (let tagdef of TAGS) {
      let name = tagdef[0], r = tagdef[1];
      if (tags.has(name)) continue;
      let matched = false;
      if (typeof(r) == 'string') {
        matched = (text.indexOf(r) != -1);
      } else {
        matched = (text.search(r) != -1);
      }
      if (matched) {
        tags.add(name);
      }
    }
    return Array.from(tags).sort();
  }

  // Add "tags" property to reason, if not present.
  function annotateReasonWithTags(reason) {
    if (!reason.hasOwnProperty('tags')) {
      reason.tags = tagsInText(reason.text);
    }
  }

  // A StringCounter counts how many times it has seen a string, and can return [item, count]
  // pairs in descending order of frequency.

  function StringCounter() {
    this.counts = {};
  }

  StringCounter.prototype.saw = function(x, weight) {
    if (!weight) weight = 1;
    if (this.counts.hasOwnProperty(x)) {
      this.counts[x] += weight;
    } else {
      this.counts[x] = weight;
    }
  };

  StringCounter.prototype.items = function() {
    // Sort by descending count, breaking ties alphabetically.
    let items = Object.entries(this.counts);
    return items.sort(function(x, y) {
      if (y[1] < x[1]) return -1;
      if (y[1] > x[1]) return 1;
      if (y[0] > x[0]) return -1;
      if (y[0] < x[0]) return 1;
      return 0;
    });
  };

  function htmlForTags(tags) {
    let tl = $('<span class="tag-list"> </span>');
    for (let tag of tags) {
      $('<span class="tag"/>').text(tag).appendTo(tl);
      tl.append(' ');
    }
    return tl;
  }

  // The original implementation here aggregated referrals (e.g. 23 x Referred by pcollison).
  // Keep them separate for consistency with the other types of channels.
  function distinctEntriesForReferrers(reasons) {
    let referrerReasons = [];

    for (let reason of reasons) {
      if (reason.referrer) {
        referrerReasons.push({
          text: 'Referred by ' + reason.referrer,
          user: null,
          weight: 1,
          tags: ['(referred)']
        });
      }
    }

    return referrerReasons;
  }

  function htmlForTagPopularity(reasons) {
    // Some of the reasons are of the form "Referred by X". Aggregate those into single entries.
    let referrerReasons = distinctEntriesForReferrers(reasons);
    let otherReasons = reasons.filter(reason => !reason.hasOwnProperty('referrer'));
    reasons = referrerReasons.concat(otherReasons);

    // Give each one a "tags" property, containing tag list.
    for (let reason of reasons) annotateReasonWithTags(reason);
    // Compute tag popularity and which reasons have which tags.
    let tagCounter = new StringCounter();
    let reasonsForTag = {};
    for (let reason of reasons) {
      for (let tag of reason.tags.length ? reason.tags : ['(untagged)']) {
        tagCounter.saw(tag);
        if (!reasonsForTag.hasOwnProperty(tag)) {
          reasonsForTag[tag] = [];
        }
        reasonsForTag[tag].push(reason);
      }
    }
    // Reasons for each tag should be sorted alphabetically, except for '(referred)'
    for (let tag in reasonsForTag) {
      if (tag === '(referred)') {
        reasonsForTag[tag].sort((a, b) => b.weight - a.weight);
      } else {
        reasonsForTag[tag].sort(function(a, b) {
          let ka = a.text.toLowerCase(), kb = b.text.toLowerCase();
          if (ka < kb) return -1;
          if (ka > kb) return 1;
          return 0;
        });
      }
    }

    // Table of contents, for tag popularity summary and easy jumping.
    let container = $('<div id="tag-popularity"/>');
    let toc = $('<div id="toc"/>');
    let tocul = $('<ul/>');
    for (let [tag, tagCount] of tagCounter.items()) {
      let li = $('<li/>').appendTo(tocul);
      li.text(tag + ' (' + tagCount + ')');
      li.click(() => location.hash = '#tag-'+tag);
    }
    toc.append(tocul);
    container.append(toc);

    // Generate sections for each tag, building the HTML.
    for (let [tag, tagCount] of tagCounter.items()) {
      // How many times did we see each other tag in this section?
      let otherTagCounts = new StringCounter();
      for (let reason of reasonsForTag[tag]) {
        for (let t of reason.tags) {
          if (t !== tag) otherTagCounts.saw(t);
        }
      }
      // Summary line for this section
      let otherTagHTML = htmlForTags(otherTagCounts.items().map(function([t, c]) {
        return c + ' ' + t;
      }));
      let summaryLine = $('<div class="tag-summary">').attr('id', 'tag-' + tag);
      $('<b class="tag">').text(tag).appendTo(summaryLine);
      $('<span class="tag-summary-counts"/>').text(
        ' (' + tagCount + ', ' + (tagCount*100.0/(reasons.length)).toFixed(1) + '%) ').appendTo(summaryLine);
      summaryLine.append(otherTagHTML);
      container.append(summaryLine);
      // Show reasons for this section
      for (let reason of reasonsForTag[tag]) {
        let line = $('<div class="tag-line"/>');
        let otherTags = reason.tags.filter(t => t !== tag);
        if (otherTags.length > 0) {
          line.append(htmlForTags(otherTags));
        }
        line.append($('<span class="reason"/>').text(reason.text));
        container.append(line);
      }
    }
    return container;
  }

  ////////////////////////////////////////////////////////////////////////////////
  // USER INTERFACE: How we hook this up with the HTML
  ////////////////////////////////////////////////////////////////////////////////

  // Preset filters are mentioned in the HTML, and the definitins are here.

  let PRESET_FILTERS = {};

  PRESET_FILTERS['noFilter'] = `return reasons
`;

  PRESET_FILTERS['pastMonth'] = `let now = (new Date()).getTime();

return reasons
.filter(r => now - r.created_at < 1*30*24*3600*1000)
`;

  PRESET_FILTERS['nonDefaultGlicko'] = `return reasons
.filter(r => r.delayed_glicko_rating !== 1000)
`;

  PRESET_FILTERS['top500Glicko'] = `return reasons
.filter(r => r.delayed_glicko_rating !== 1000)
.sortBy(r => -r.delayed_glicko_rating)
.slice(0, 500)
`;

  function setupPresetFilters() {
    for (let presetName of Object.keys(PRESET_FILTERS)) {
      let presetText = PRESET_FILTERS[presetName];
      let elt = $$('li[preset-id='+presetName+']');
      if (elt) {
        elt.click(function () {
          $$('#query-expr').val(presetText);
          $$(doFilteringAndRedisplay);
        });
      }
    }
  }

  let ALL_REASONS = [];
  let FILTERED_REASONS = [];

  function doFilteringAndRedisplay() {
    $$('#content').empty()
    $$('#error-message').empty();

    let filterExpr = $$('#query-expr').val().trim();
    if (filterExpr) {
      let fn = Function('reasons', filterExpr);
      try {
        FILTERED_REASONS = Array.from(fn(ALL_REASONS));
      } catch (error) {
        $$('#error-message').text(error);
        FILTERED_REASONS = [];
      }
    } else {
      FILTERED_REASONS = Array.from(ALL_REASONS);
    }

    htmlForTagPopularity(FILTERED_REASONS).appendTo($$('#content'));

    let filtlen = FILTERED_REASONS.length, alllen = ALL_REASONS.length;
    if (alllen) {
      $$('#filter-count').text('Showing '+filtlen+' of '+alllen+' reasons ('+(filtlen*100.0/alllen).toFixed(1)+'%)');
    } else {
      $$('#filter-count').text('Showing all of the no data currently loaded. Undefined percent.');
    }
  }

  $$(function () {
    setupPresetFilters();
    $$('#filter-button').click(doFilteringAndRedisplay);

    fetch('referrers.json', {cache: 'no-cache'}).then(function (response) {
      return response.json();
    }).then(function (referrersJson) {
      ALL_REASONS = preprocessReasons(referrersJson.rows);
      $$('#query-container').show();
      $$(doFilteringAndRedisplay);
    })
  });
});
