import registerView from 'lib/scope'
import BooleanInput from "components/boolean_input"

class ReceivedFeedbackFormView {
  constructor($view) {
    this.$view = $view
    this._refreshDisabledStates()
    this._initFeedbackRows()
  }

  _initFeedbackRows() {
    this.$view.find(".js-feedback-row").each((i, el) => {
      const $el = $(el)
      const $likedToggleContainer = $el.find(".js-liked-feedback-toggle")
      const likedBooleanInput = new BooleanInput($likedToggleContainer, "true")

      $likedToggleContainer.on("click", (e) => {
        let newValue = likedBooleanInput.value() === "true" ? null : "true"
        if (newValue == null || this._canLikeAdditionalFeedback()) {
          likedBooleanInput.setValue(newValue)
        }

        this._refreshDisabledStates()
      })

      const $downvotedToggleContainer = $el.find(".js-downvoted-feedback-toggle")
      let downvotedBooleanInput = new BooleanInput($downvotedToggleContainer, "true")

      $downvotedToggleContainer.on("click", (e) => {
        let newValue = downvotedBooleanInput.value() === "true" ? null : "true"
        downvotedBooleanInput.setValue(newValue)
      })
    })
  }

  _canLikeAdditionalFeedback() {
    return this.$view.find(".js-liked-feedback-toggle .js-hidden-field[value='true']").length < parseInt(this.$view.data("max-liked-feedback"))
  }

  _refreshDisabledStates() {
    this.$view.find(".js-liked-feedback-toggle .js-hidden-field:not([value='true'])").each((i, el) => {
      let $el = $(el)
      let $parent = $el.closest(".js-liked-feedback-toggle")
      $parent.toggleClass("liked-feedback-toggle--disabled", !this._canLikeAdditionalFeedback())
    })
  }
}

registerView('.js-applicants-received_grant_application_matchups-index', ($view, $$) => {
  $$(".js-downvote-tooltip").tooltip({ delay:  { 'show': 500, 'hide': 0 }, trigger: 'hover' })
  new ReceivedFeedbackFormView($view)
})
