const CHILD_SELECT = "child-select"
const CHILD_DESELECT = "child-deselect"
const CHILD_TIME_FILTER = "child-time-filter"
const CHILD_HOVER = "child-hover"
const CHILD_HOVER_EXIT = "child-hover-exit"

const ADULT_SELECT = "adult-select"
const ADULT_DESELECT = "adult-deselect"
const ADULT_TIME_FILTER = "adult-time-filter"
const ADULT_HOVER = "adult-hover"
const ADULT_HOVER_EXIT = "adult-hover-exit"

export const SELECT = { childEvent: CHILD_SELECT, adultEvent: ADULT_SELECT }
export const DESELECT = { childEvent: CHILD_DESELECT, adultEvent: ADULT_DESELECT }
export const TIME_FILTER = { childEvent: CHILD_TIME_FILTER, adultEvent: ADULT_TIME_FILTER }
export const HOVER = { childEvent: CHILD_HOVER, adultEvent: ADULT_HOVER }
export const HOVER_EXIT = { childEvent: CHILD_HOVER_EXIT, adultEvent: ADULT_HOVER_EXIT }

export const ALL_EVENTS = [SELECT, DESELECT, TIME_FILTER, HOVER, HOVER_EXIT]
