import registerView from 'lib/scope'
import AreYouSure from 'lib/are_you_sure'
import CharactersRemaining from 'lib/characters_remaining'
import Textarea from 'components/textarea'
import analytics from 'lib/analytics'

registerView('.js-course_progression-shared-_level_layout', ($view, $$) => {
  const $progressBar = $$(".js-progress-bar")
  const finalWidth = $progressBar.data("final-width")
  if (finalWidth) {
    $progressBar.css("width", `${finalWidth}%`)
  }

  $$('textarea').each((i, el) => {
    new Textarea($$(el))
  })

  $$('.js-text-input-with-character-limit').each((i, el) => {
    new CharactersRemaining($$(el))
  })

  // Trigger modal manually instead of using an anchor tag since the resources icon
  // is nested within another link (the sidebar menu) on mobile
  $$(".js-sidebar-level-resources-btn").on("click", (e) => {
    e.stopPropagation()
    $$(".js-level-resources-modal").modal()
  })

  $$(".js-sidebar-step-wrapper.sidebar-step--active").on("click", (e) => {
    e.preventDefault()

    $$(".js-sidebar-step-toggle-icon").toggleClass("expanded")
    $$(".js-sidebar-step-wrapper:not(.sidebar-step--active)").toggleClass("sidebar-step--mobile-preview")
  })

  new AreYouSure($$)

  const levelNumber = $view.data("levelNumber")
  const course = $view.data("course")
  analytics.track('Viewed level', { course: course, levelNumber: levelNumber })

  const $modal = $(".js-level-resources-modal")
  $modal.on('shown.bs.modal', (e) => {
    analytics.track('Viewed resources modal', { course: course, levelNumber: levelNumber })

    // Stop the resources button icon (mobile-only) from floating after it's clicked
    $modal.removeClass("floating")
  })
})
