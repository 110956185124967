import * as React from "react"
import { Winner, RouteTemplates } from "../Index"
import WinnerCard from "../index/WinnerCard"

interface WinnerGridProps {
  winners: Winner[]
  route_templates: RouteTemplates
  winnerIdsToShow: number[]
  onMouseEnterWinnerCard: (winner: Winner) => void
  onMouseLeaveWinnerCard: (winner: Winner) => void
}

export default class WinnerGrid extends React.Component<WinnerGridProps, {}> {
  render() {
    const winnerCards =
      this.props.winners
        .filter((winner) => {
          if (this.props.winnerIdsToShow.length > 0) {
            return this.props.winnerIdsToShow.includes(winner.id)
          } else {
            return true
          }
        })
        .map((winner) => {
        return (
          <div
            className="col-lg-4 col-md-6 col-12 px-2 pt-md-3 pb-md-4 pb-3"
            key={winner.id} >
            <WinnerCard
              onMouseEnter={this.props.onMouseEnterWinnerCard}
              onMouseLeave={this.props.onMouseLeaveWinnerCard}
              showOneLiner={true}
              routeTemplates={this.props.route_templates}
              winner={winner} />
          </div>
        )
      })

    return (
      <div className="container container--wide pt-md-4 pt-3">
        <div className="row">
          { winnerCards }
        </div>
      </div>
    )
  }
}
