import registerView from 'lib/scope'
import AutosaveForm from 'lib/autosave_form'
import Countdown from 'lib/countdown'

registerView('.js-assessments-show-big_five', ($view, $$) => {
  const autosaveForm = new AutosaveForm($$('form'))

  $$('.js-random-answers').on('click', () => {
    let $radioButtonGroups = {}
    for (let input of $$('input[type=radio]').toArray()) {
      let $input = $(input)
      let key = $input.attr('name')
      let group = $radioButtonGroups[key] || []
      group.push($input)
      $radioButtonGroups[key] = group
    }

    for (let key of Object.keys($radioButtonGroups)) {
      let inputs = $radioButtonGroups[key]
      let $input = inputs[Math.floor(Math.random() * inputs.length)]
      $input.prop('checked', true)
    }

    autosaveForm.formChanged()
  })

  // Remove the hidden form immediately and always display the visible one
  $$('.js-flex-layout:hidden,.js-table-layout:hidden').remove()
  $$('.js-flex-layout,.js-table-layout').removeClass('responsive')
})

registerView('.js-assessments-show-matrices', ($view, $$) => {
  new AutosaveForm($$('form'))

  $$('.js-option').on('click', (e) => {
    let $image = $(e.currentTarget)
    let option = $image.attr('value')
    $$('.js-option').removeClass('selected')
    $(e.currentTarget).addClass('selected')

    $$('.js-response-field').val(option).trigger('change');

    let $answer = $$('.js-answer-explanation')
    if ($answer.length > 0 && $answer.hasClass('d-none')) {
      $answer.removeClass('d-none').hide().slideDown(100)
    }

    $$('.js-next-page').removeClass('page-link').addClass('btn btn-primary')
  })

  let $timer = $$('.js-deadline-timer')
  let $form = $$('form')
  if ($timer.length > 0) {
    // Note: this isn't completely accurate; e.g. if this code gets run
    // 5 seconds after `data-time-remaining` is generated on the server,
    // deadline will be 5 seconds later than it should be.
    //
    // For now, we partially address this by incorporating some leeway for
    // submissions on the server.
    //
    // Might be more accurate to do an ajax request for UTC.
    new Countdown($timer, { customHandler: (secondsRemaining) => {
      if (secondsRemaining <= 0) {
        $form.find('.js-force-submission').prop('disabled', false)
        $form.submit()
      } else if (secondsRemaining <= 60) {
        secondsRemaining = Math.floor(secondsRemaining)
        $timer.text(`${secondsRemaining}s left`).addClass('text-danger')
      } else {
        let minutes_remaining = Math.floor(secondsRemaining / 60)
        $timer.text(`${minutes_remaining}m left`)
        if (minutes_remaining < 4) {
          $timer.addClass('text-danger')
        }
      }
    }})
  }
})
