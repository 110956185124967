import ClipboardJS from 'clipboard'

export default class Clipboard {
  constructor(selector, options = {}) {
    this.clipboard = new ClipboardJS(selector)
    this.clipboard.on('success', (e) => {
      let $copyLink = $(e.trigger)
      if (options.silent) {
        return
      }
      $copyLink.text('Copied')
    })
  }
}
