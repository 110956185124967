
export default class PercentChart {
  constructor($view, chart, selectors) {
    this.selectors = selectors
    this.chart = chart
    this.$view = $view

    this.selectors.forEach((selector) => {
      $view.find(selector).on("blur", () => {
        this.update()
        this.validateTotal()
      })
    })

    this.update()
  }

  update() {
    this.chart.update()
  }

  getInputSelectorData() {
    const data = this.selectors.map((selector) => {
      let ret = parseInt(this.$view.find(selector).val())
      return ret || 0
    })

    return data
  }

  totalValid() {
    const data = this.getInputSelectorData()
    const dataSum = data.reduce((acc, a) => acc + a, 0)

    const dataSumIs100 = dataSum === 100
    return dataSumIs100
  }

  validateTotal() {
    if (this.totalValid()) {
      this.$view.find(".js-total-help-block").toggleClass("d-none", true)
    } else {
      // Only show error message if every input is a number
      const showErrorMessage = this.selectors.every((selector) => {
        const inputValue = parseInt(this.$view.find(selector).val())
        return !isNaN(inputValue)
      })

      if (showErrorMessage) {
        this.$view.find(".js-total-help-block").toggleClass("d-none", false)
      }
    }
  }

  valid() {
    return this.totalValid()
  }
}
