import registerView from 'lib/scope'

const signedUpBtnText = "Signed up"
const signedUpBtnHoverText = "Cancel"

registerView('.js-community-rsvp-card', ($view, $$) => {
  $$(".js-signed-up-button").val(signedUpBtnText)

  $$(".js-tooltip").tooltip({ delay:  { 'show': 0, 'hide': 0 }, trigger: 'hover' })

  $$(".js-signed-up-button").hover(
      (event) => {
        const target = $$(event.target)
        target.val(signedUpBtnHoverText)
      },
      (event) => {
        const target = $$(event.target)
        target.val(signedUpBtnText)
      }
  )
})
