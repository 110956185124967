import analytics from "lib/analytics"
import TickerEventNode from "views/applicants/ticker/ticker_event_node"

export default class TimeFilter extends TickerEventNode {
  constructor($view) {
    super($view)
    this.$view = $view

    this.initializeEventEmitters()
  }

  initializeEventEmitters() {
    this.$view.on("change", () => {
      analytics.track("[Ticker] Used time filter")

      this.filter()
    })
  }

  // TODO maybe the view should contain data on how to round the value
  filter() {
    const lookbackDuration = this.$view.val() * 1000 // rails epoch in s, js epoch in ms
    const end = new Date()

    const oneDay = 1000 * 60 * 60 * 24

    if (lookbackDuration > oneDay) {
      end.setHours(0)
    }

    end.setMinutes(0)
    end.setSeconds(0)
    end.setMilliseconds(1)

    const endEpoch = end.getTime()
    const startEpoch = endEpoch - lookbackDuration

    const displayName = this.$view.find(":selected").attr("display_name")

    const eventData = { lookbackDuration, start: startEpoch, end: endEpoch, displayName }
    this.triggerTimeFilter(eventData)
  }
}
