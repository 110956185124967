import registerView from 'lib/scope'
import ButtonGroup from 'components/button_group'
import AdvanceToNextLevelForm from 'views/course_progression/shared/advance_to_next_level_form'

registerView('.js-course_progression-launches-_level1', ($view, $$) => {
  new ButtonGroup($$('.js-launch-timeline-button-group'))


  $$(".js-button-group-option").on("click", (e) => {
    const $button = $(e.currentTarget)

    const $launchCountdown = $('.js-launch-countdown')
    if ($button.hasClass("selected")) {
      $launchCountdown.data("time-remaining", $button.data("time"))
    } else {
      $launchCountdown.removeData("time-remaining")
    }
    $launchCountdown.tooltip('disable') // the date in the tooltip is no longer accurate once we change the timer
    $launchCountdown.trigger('deadlineChanged')
  })

  // Default behavior does not work since CSV doesn't try to validate hidden fields
  const shouldEnableAdvanceToLevel2 = ($form) => {
    return !!$form.find(".js-button-group-input").val()
  }
  new AdvanceToNextLevelForm($$('.js-level-form'), { shouldEnableSubmit: shouldEnableAdvanceToLevel2 })
})
